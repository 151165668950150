import styled from 'styled-components';

export const DateCardLayout = styled.div`
    
    margin: 0 auto;
    margin-left: 2%;
    margin-top: -20%;
    background: rgba(0, 191, 99, 0.41);
    border-radius: 6px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6.2px);
    -webkit-backdrop-filter: blur(6.2px);
    border: 0.6px solid rgba(0, 191, 99, 0.3);

    color: #000;
    z-index:1;
    top:-46px;
    font-weight: 800;
    line-height: 1.1;
    text-align: center;
    text-transform: uppercase;
    width: 5.4em;
    `;
export const MonthLayout = styled.h4`
    text-align: center;
    font-weight: 100;
    font-size: 1.22em;
    color:white;
    font-weight: 500;
    line-height: 1.1;
    border: 0 none;
    box-sizing: border-box;
    
`;
export const DayLayout = styled.h3`
    text-align: center;
    font-weight: 100;
    color:white;
    font-size: 2.375em;
    border: 0 none;
    font-weight: 800;
    line-height: 1.1;
    box-sizing: border-box;
    
`;