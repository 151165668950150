import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardSubTitle,
  MDBCardText,
  MDBCardLink,
  MDBCardImage,
} from "mdb-react-ui-kit";
import styled from "styled-components";
import * as S from "../../../AppLayout.style";
import { LinkLayout } from "../NavigationBar/Menu.style";
const StyledMDBCard = styled(MDBCard)`
  @media ${S.device.mobileM} {
    width: 100%;

    text-align: center;
    &:hover {
      cursor: pointer;
      box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
        rgba(0, 0, 0, 0.23) 0px 6px 6px;
    }
  }
  @media ${S.device.laptop} {
    max-height: 33rem;
    margin-left: 0;
    padding-bottom: 0;
    text-align: center;
    &:hover {
      cursor: pointer;
      box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
        rgba(0, 0, 0, 0.23) 0px 6px 6px;
    }
  }
`;
const StyledMDBCardText = styled(MDBCardText)`
  &:hover {
    color: #259067aa;
    text-decoration: underline;
  }
`;
const StyledMDBCardTitle =styled(MDBCardTitle)`
font-size:1.2rem;
&:hover {
    color: #1c963daa;
    text-decoration: underline;
  }
`
function MDBCardi(props) {
  return (
    <StyledMDBCard>
      <MDBCardBody
        style={{
          textAlign: "center",
          minHeight: "29rem",
          padding: "0",
          paddingBottom: "2%",
        }}
      >
        <div
          style={{
            backgroundSize: "cover",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            backgroundImage: `url(${props.imagePath})`,
            height: "30vh",
          }}
        ></div>

        <StyledMDBCardTitle
          style={{
            
            paddingTop: "4%",
            color: "green",
            textTransform: "uppercase",
          }}
        >
          <LinkLayout to="TheNews" style={{ fontSize: "inherit", fontWeight:"inherit" }}>
            {props.cardTitle}
          </LinkLayout>
        </StyledMDBCardTitle>
        <MDBCardSubTitle style={{ paddingRight: "4%", paddingLeft: "4%" }}>
          {props.cardSubtitle}
        </MDBCardSubTitle>
        <StyledMDBCardText style={{ paddingRight: "4%", paddingLeft: "4%" }}>
          <LinkLayout to="TheNews" style={{ textTransform: "capitalize" }}>
            {props.cardText}
          </LinkLayout>
        </StyledMDBCardText>
        {/* <MDBCardLink href="#">{props.cardLink}</MDBCardLink> */}
        {/* <MDBCardLink href="#">{props.anotherLink}</MDBCardLink> */}
      </MDBCardBody>
    </StyledMDBCard>
  );
}

export default MDBCardi;
