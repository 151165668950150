import * as E from "./Elements.styles";
import {Link} from "react-router-dom"
export function Elements(props) {
  return (
    <E.Header paddingTop={props.paddingTop} >
      <E.GotAQuestion fS1={props.fS1}>Got a Question</E.GotAQuestion>
      <E.ContactRhibms fS2={props.fS2}>contact rhibms</E.ContactRhibms>
      <E.ContactP width={props.widthOfContactP}>
        We’re here to help and answer any question you might have. We look
        forward to hearing from you 🙂
      </E.ContactP>
    </E.Header>
  );
}

export function EnterMessage(props) {
  return (
    <E.EnterMessage margin={props.margin} width={props.width}>
      <E.Pair>
        <E.PairDiv1>
          <E.PairDivLabel>first name</E.PairDivLabel>

          <E.PairDivInput type="text" />
        </E.PairDiv1>
        <E.PairDiv2>
          <E.PairDivLabel>last name</E.PairDivLabel>

          <E.PairDivInput type="text" />
        </E.PairDiv2>
      </E.Pair>
      <E.Email>
        <E.EmailDivLabel>email</E.EmailDivLabel>
        <E.EmailDivInput  placeholder="Enter your email" type="email" />
      </E.Email>
      <E.Message>
        <E.MessageDivLabel>message</E.MessageDivLabel>

        <E.MessageDivInput
          id="message"
          name="message"
          placeholder="Write your message"
        />
      </E.Message>
      <E.MessageBtn>Send Message</E.MessageBtn>
    </E.EnterMessage>
  );
}

export function HowCanWeHelp(props) {
  return (
    <>
      <E.HowCanWeHelp display={props.display}>
        <E.ContactInfo width={props.ContactInfoWidth}>
          <E.ContactInfoHeader>
            <E.ContactInfoHead>Contact Information</E.ContactInfoHead>
          </E.ContactInfoHeader>
          <E.ContactInfoDetails>
            <E.Location>
              <E.LocationIcon size={23} /> RHIBMS University, Molyko, Buea
            </E.Location>
            <E.Phone>
              {" "}
              <E.PhoneIcon size={23} />
              681 019 578, 233 324 850, 677 172 022{" "}
            </E.Phone>
            <E.Mail>
              <E.MailIcon size={20} /> info@rhibms.com
            </E.Mail>
          </E.ContactInfoDetails>
        </E.ContactInfo>
        <E.HowCanWeHelpText>
          <E.HowCanWeHelpTextHeader>
            <E.HowCanWeHelpTextHead>How Can We Help?</E.HowCanWeHelpTextHead>
            <E.HowCanWeHelpTextHeadDetails width={props.HelpTextHeadDetailsWidth} >
              Please select a topic below related to your inquiry. If you don’t
              find what you need, fill out our contact form.
            </E.HowCanWeHelpTextHeadDetails>
            <E.WhereIsIt>Where is the School?</E.WhereIsIt>
            <E.HowCanWeHelpTextHeadDetails width={props.HelpTextHeadDetailsWidth} >
              The School is in Cameroon, the South West Region, in Buea, Molyko
            </E.HowCanWeHelpTextHeadDetails>
            <E.HoriLine/>
            <E.WhereIsIt>How do I Apply?</E.WhereIsIt>
            <E.HowCanWeHelpTextHeadDetails width={props.HelpTextHeadDetailsWidth} >
              It has become easy to apply, taking only 5 minutes. <Link to="/">Apply Now</Link> 
            </E.HowCanWeHelpTextHeadDetails>
            <E.HoriLine/>
            <E.WhereIsIt>What are the Fees?</E.WhereIsIt>
            <E.HowCanWeHelpTextHeadDetails width={props.HelpTextHeadDetailsWidth} >
              Fees range from 310,000 FCFA to 850,000 FCFA
            </E.HowCanWeHelpTextHeadDetails>
            <E.HoriLine/>
            You Should check the Frequently Asked Questions(<Link to="/FAQ">FAQ</Link> ) for more ...
            <E.AcreditedBy>
            Acredited by UBa, <br/>
            Auth No: 12/0648/MINESUP/SG/VDDES for creation and 13/0585/MINESUP/of 2nd Dec.  2013 for opening
BMP 20, BUEA
          </E.AcreditedBy>
          </E.HowCanWeHelpTextHeader>

          
        </E.HowCanWeHelpText>
      </E.HowCanWeHelp>
    </>
  );
}
