import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn,
} from "mdb-react-ui-kit";
import "animate.css";

import styled from "styled-components";

import { FaSchool } from "react-icons/fa";
import * as S from "../../../AppLayout.style";
import { LinkLayout } from "../NavigationBar/Menu.style";

const MiniMDBCard = styled(MDBCard)`
  object-fit: cover;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 1, 0, 0.7);
  height: 80vh;

`;
const MiniMDBCardMobile = styled(MDBCard)`
  object-fit: cover;
  width: 40%;
  height: 20vh;
 
`;
const MiniatureAboutImageCardMobileHead1 = styled.h2`
  color: #265854;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;
  font-size: 0.8125rem;
  font-weight: 700;
  vertical-align: baseline;
`;
const MiniatureAboutImageCardMobileHead2 = styled.h3`
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-transform: capitalize;
  text-align: center;
  letter-spacing: 2px;
  font-size: 1.8125rem;
  font-weight: 800;
  vertical-align: baseline;
`;

function MiniatureAboutImageCardWrapper({Image}) {


  return <MiniatureAboutImageCard Image={Image} />


}
export default MiniatureAboutImageCardWrapper;

function MiniatureAboutImageCard({ Image }) {
  return (
    <div style={{ display: "flex" }}>
      <MiniMDBCard

        className="mb-1"
        style={{
          width: "100%",
          background: `url(${Image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />

      <S.OtherSideAbout height="80vh" padding="8.75%">
        <h2
          style={{
            fontWeight: 600,
            textTransform: "uppercase",
            color: "#265854",
          }}
        >
          About RHIBMS
        </h2>
        <p
          style={{
            fontSize: 1.7 + "em",
            fontWeight: 800,
            lineHeight: 1.1,
          }}
          className="bodyTitle"
        >
          Undertake excellent research and teaching
        </p>

        <p>
          The Redemption Higher Institute of Biomedical and Management Science
          (RHIMBS) established in 2010 as a training center for Nursing
          Assistants under the ministry of Public Health. Its mission was to
          train nurses in the professional field. Barely a year down the line,
          had its role in the sector became very salient to be unnoticed.
          Eventually responding to this, the ministry of Public Health
          authorized the institution to run programs for the registry nursing
          Diploma ...
        </p>
        <MDBBtn
          style={{
            marginTop: "2%",
            width: "9rem",
            fontSize: 0.7 + "rem",
            fontWeight: 300,
            lineHeight: 1.1,
            padding: "15px",
            backgroundColor: "transparent",
            border: "1px solid #265854",
            color: "#265854",
          }}
        >
          <LinkLayout pR="0"  to="/MnV">
          See More
          </LinkLayout>
        </MDBBtn>
      </S.OtherSideAbout>
    </div>
  );
}


