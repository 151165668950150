import DateCard from "../DateCard/DateCard";

import * as S from "./Events.style";
import { LinkLayout } from "../NavigationBar/Menu.style";

const EventsList = [
  {
    title: "Internship Placement",
    venue: "Amphitheater II",
    bgImage: "assets/img/AnBook.jpg",
    shortText:
      "At this time, everyone will be sent to hospitals for internship",
    Month: "Apr",
    Date: 17,
  },
  {
    title: "Graduation Ceremony",
    venue: "Hall III",
    bgImage: "assets/img/FacTut.jpg",
    shortText:
      "Exquisite revolutionary medicine school, removing it's 18th batch!!",
    Month: "July",
    Date: 14,
  },
  {
    title: "Faculty Tutorials",
    venue: "Amphitheater II",
    bgImage: "assets/img/AnBook.jpg",
    shortText: "Exquisite revolutionary medicine school, more stuff here!!",
    Month: "Mar",
    Date: 28,
  },
  {
    title: "Library Books Showcase",
    venue: "Amphitheater II",
    bgImage: "assets/img/FacTut.jpg",
    shortText: "Exquisite revolutionary medicine school, more stuff here!!",
    Month: "Feb",
    Date: 14,
  },
];
function EventCardsWrapper({ isMobile }) {
  return <div>{isMobile ? <EventCardsMobile /> : <EventCards />}</div>;
}
export default EventCardsWrapper;
function EventCards() {
  return (
    <S.OjageRow
      style={{ marginBottom: "2%" }}
      className="row-cols-1 row-cols-md-4 g-4"
    >
      {EventsList.map((deEvent) => {
        return (
          <S.OjageCol>
            <S.OjageCard height="67.6vh" className="h-200">
              <div
                style={{
                  borderRadius: "6px 6px 0 0",
                  height: "70%",
                  backgroundImage: `url(${deEvent.bgImage})`,
                  backgroundSize: "cover",
                }}
              >
                {/* <S.OjageCardImage
                  src="assets/img/ManUniversityImg.jpg"
                  alt="..."
                  position="top"
                /> */}
              </div>

              <DateCard month={deEvent.Month} date={deEvent.Date} />
              <S.OjageCardBody color="black">
                <S.OjageCardVenue top="-68px" right="-30.8%">
                  {deEvent.venue}
                </S.OjageCardVenue>
                <S.OjageCardTitle>
                  <LinkLayout style={{font:"inherit",textTransform:"inherit",color:"inherit"}} to="MainEvents">
                  {deEvent.title}
                  </LinkLayout>
                </S.OjageCardTitle>
                <S.OjageCardText>
                  <LinkLayout style={{font:"inherit",textTransform:"inherit",color:"inherit"}} to="MainEvents">
                    {deEvent.shortText}
                  </LinkLayout>
                </S.OjageCardText>
              </S.OjageCardBody>
            </S.OjageCard>
          </S.OjageCol>
        );
      })}
    </S.OjageRow>
  );
}

function EventCardsMobile() {
  return (
    <div style={{ marginBottom: "-2%" }}>
      {EventsList.slice(0, 2).map((deEvent, index) => {
        return (
          <S.OjageRow>
            <S.OjageCol mobileMT="2%">
              <S.OjageCard height="67.6vh" className="h-200">
                <div
                  style={{
                    borderRadius: "6px 6px 0 0",
                    height: "70%",
                    backgroundImage: `url(${deEvent.bgImage})`,
                    backgroundSize: "cover",
                  }}
                ></div>
                <DateCard month={deEvent.Month} date={deEvent.Date} />
                <S.OjageCardBody color="black">
                  <S.OjageCardVenue>{deEvent.venue}</S.OjageCardVenue>
                  <S.OjageCardTitle>
                  <LinkLayout style={{font:"inherit",textTransform:"inherit",color:"inherit"}} to="MainEvents">
                  {deEvent.title}
                  </LinkLayout>
                </S.OjageCardTitle>
                <S.OjageCardText>
                  <LinkLayout style={{font:"inherit",textTransform:"inherit",color:"inherit"}} to="MainEvents">
                    {deEvent.shortText}
                  </LinkLayout>
                </S.OjageCardText>
                </S.OjageCardBody>
              </S.OjageCard>
            </S.OjageCol>
          </S.OjageRow>
        );
      })}
    </div>
  );
}
