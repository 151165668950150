import { Fade } from "react-slideshow-image";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import RHIBMSSCHOOLS_DATA from "./RHIBMSSCHOOLS_DATA.json";
import * as E from "./Elements/Elements.style";
import HeadTextElement from "./Elements/HeadTextElement";
import {
  MDBRow,
  MDBCol,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";

import Footer from "../../LandingPageComponents/Footer/Footer";
import { Gradient } from "../../../AppLayout.style";
import * as S from "./Schools.style";
import { BackCardRe } from "../../AdmissionPageComponents/MainAdmission.styles";
const RhibmsSchool_DATA = [
  "School of Public Health",
  "School of Biomedical Sciences",
  "School of Hospitality Management and Textile",
  "School of Management Sciences",
  "School of Arts and Education",
];
const aboutedu = "https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTl8fGVkdWNhdGlvbiUyMGJsYWNrfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=400&q=60";

function SchoolsWrapper(props) {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const isTablet = useMediaQuery({
    query: "(min-width: 787px)",
    query: "(max-width: 1224px)",
  });

  const isMobile = useMediaQuery({
    query: "(max-width: 786px)",
  });

  return (
    <div>
      {isMobile ? (
        <SchoolsMobile isTablet={isTablet} />
      ) : (
        <Schools isTablet={isTablet} />
      )}
    </div>
  );
}

function SchoolsMobile() {
  const imagePath = "https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTl8fGVkdWNhdGlvbiUyMGJsYWNrfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=400&q=60";

  const imagePaths = [
    "assets/img/requirementsPics/backgroundImage.JPG",
    "assets/img/requirementsPics/IMG_9225.JPG",
    "assets/img/requirementsPics/_DSC0017.JPG",
    "assets/img/requirementsPics/_DSC0007.JPG",
    "assets/img/requirementsPics/_DSC0012.JPG",
    "assets/img/requirementsPics/_DSC0029.JPG",
    "assets/img/requirementsPics/DSC_0369.JPG",
    "assets/img/requirementsPics/_DSC0094.JPG",
    "assets/img/requirementsPics/_DSC0081.JPG",
    "assets/img/requirementsPics/_DSC0080.JPG",
    "assets/img/requirementsPics/_DSC0069.JPG",
    "assets/img/requirementsPics/_DSC0064.JPG",
  ];

  const [basicActive, setBasicActive] = useState("tab1");

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  return (
    <S.SchoolsLayout bg={imagePath}>
      <S.SchoolsHeaderLayout height="25vh" bg={aboutedu}>
        <Gradient>
          <E.HeadTextElement paddingTop="9%">
            <HeadTextElement spanMargin="0 auto" title="Schools" />
          </E.HeadTextElement>
        </Gradient>
      </S.SchoolsHeaderLayout>
      <E.ContentActual>
        <S.RhibmsSchoolsInfo>RHIBMS Schools</S.RhibmsSchoolsInfo>
        <S.RSIParagraph>
          RHIBMS is known for its <b>05</b> separate schools and other Schools with
          accreditation from two different ministries (Public Health and Higher
          Education) and mentored by the University of Bamenda to offer
          Bachelors and Masters Schools. Take some time to explore our schools
          and colleges and find the program that’s right for you.
        </S.RSIParagraph>

        {RHIBMSSCHOOLS_DATA.map((val) => {
          return (
            <S.CardForSchool width="98%" bgColor={val.bgColor} key={val.id}>
              <S.RhibmsSchoolsInner width="100%">
                <S.SchoolName fS="2.2em">{val.nameOfSchool}</S.SchoolName>
                <S.ShortDesc fS="1em">{val.shortDescription}</S.ShortDesc>
              </S.RhibmsSchoolsInner>
              {/* <S.CardForSchoolImg height="80%" mL="0" bg={val.image}></S.CardForSchoolImg> */}
            </S.CardForSchool>
          );
        })}
      </E.ContentActual>

      <Footer />
    </S.SchoolsLayout>
  );
}
function Schools() {
  const imagePath = "assets/img/aboutedu.svg";

  const imagePaths = [
    "assets/img/requirementsPics/backgroundImage.JPG",
    "assets/img/requirementsPics/IMG_9225.JPG",
    "assets/img/requirementsPics/_DSC0017.JPG",
    "assets/img/requirementsPics/_DSC0007.JPG",
    "assets/img/requirementsPics/_DSC0012.JPG",
    "assets/img/requirementsPics/_DSC0029.JPG",
    "assets/img/requirementsPics/DSC_0369.JPG",
    "assets/img/requirementsPics/_DSC0094.JPG",
    "assets/img/requirementsPics/_DSC0081.JPG",
    "assets/img/requirementsPics/_DSC0080.JPG",
    "assets/img/requirementsPics/_DSC0069.JPG",
    "assets/img/requirementsPics/_DSC0064.JPG",
  ];

  const [basicActive, setBasicActive] = useState("tab1");

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  return (
    <S.SchoolsLayout bg={imagePath}>
      <S.SchoolsHeaderLayout bg={aboutedu}>
        <Gradient>
          <E.HeadTextElement>
            <HeadTextElement spanMargin="0 auto" title="Schools" />
          </E.HeadTextElement>
        </Gradient>
      </S.SchoolsHeaderLayout>
      <E.ContentActual>
        <S.RhibmsSchoolsInfo>RHIBMS Schools</S.RhibmsSchoolsInfo>
        <p style={{fontSize:"1em", width:"90%", margin:"0 auto"  }}>
          RHIBMS is known for its <b>05</b> separate schools and other Schools with
          accreditation from two different ministries (Public Health and Higher
          Education) and mentored by the University of Bamenda to offer
          Bachelors and Masters Schools. Take some time to explore our schools
          and colleges and find the program that’s right for you.
        </p>

        {RHIBMSSCHOOLS_DATA.map((val) => {
          return (
            <S.CardForSchool bgColor={val.bgColor} key={val.id}>
              <S.RhibmsSchoolsInner>
                <S.SchoolName>{val.nameOfSchool}</S.SchoolName>
                <S.ShortDesc>{val.shortDescription}</S.ShortDesc>
              </S.RhibmsSchoolsInner>
              <S.CardForSchoolImg bg={val.image}></S.CardForSchoolImg>
            </S.CardForSchool>
          );
        })}
      </E.ContentActual>
    
  
      

      <Footer />
    </S.SchoolsLayout>
  );
}

export default SchoolsWrapper;
