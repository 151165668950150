export const TestimonialsRes = [
    {
        name: "Obi ALbright",
        post: "Student",
        text: "RHIBMS has a strong system as stipulated by the Ministry of Higher Education that has given me much knowledge.",
        img: "assets/img/requirementsPics/_DSC0069.JPG",
    },
    {
        name: "Ojong Balboa",
        post: "Student",
        text: "RHIBMS has a strong system as stipulated by the Ministry of Higher Education that has given me much knowledge.",
        img: "assets/img/requirementsPics/_DSC0069.JPG",
    },
    {
        name: "Ayuk Nelson",
        post: "Course Delegate",
        text: "RHIBMS has a strong system as stipulated by the Ministry of Higher Education that has given me much knowledge.",
        img: "assets/img/requirementsPics/_DSC0069.JPG",
    },
    {
        name: "Ebountat Ryan",
        post: "Lecturer",
        text: "This is one of the best schools I have taught in, I mean it has got everything",
        img: "assets/img/requirementsPics/_DSC0069.JPG",
    },
]