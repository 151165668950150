import styled from "styled-components";
import { BiMenu } from "react-icons/bi";
import { MdClose } from "react-icons/md";

import { device } from "../../../AppLayout.style";
export const ToggleDiv = styled.div`
  position: fixed;
  z-index: 9;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgb(0, 8, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ToggleLayout = styled.div`
  position: absolute;
  right: 6%;

  color: white;
  &:hover {
    cursor: pointer;
  }
 
`;
export const MenuDashes = styled(BiMenu)`
  position: relative;
  right: 5px;
  top: 9px;
  z-index: 10;

  color: ${(props) => (props.scrolled ? "black" : "white")};
  display: ${(props) => props.display};
  &:hover {
    border-radius: 16px;
    background: rgb(23, 95, 12, 0.1);
  }
`;
export const MenuClose = styled(MdClose)`
  position: relative;
  right: 5px;
  top: 9px;
  color: white;
  color: ${(props) => props.menuCloseColor};
  display: ${(props) => props.display};
  z-index: 10;
`;

export const StyledUl = styled.ul`
  /* overflow-y: scroll; */
  list-style: none;
  position: fixed;
  top: 0;
  background: #065935;
  /* background: #2B231F; */
  background:${(props) => props.background};
  right: 0;
  bottom: 0;
  height: 100%;
  text-align: center;
  padding-top: 80px;
  width: ${(props) => props.width};
  display: ${(props) => props.display};
  text-transform: uppercase;
  /* max-width: 290px; */
  z-index: ${(props) => props.zIndex};
  color: #000;
  overflow: scroll;
`;

export const XtraLayer = styled.div`
  /* overflow-y: scroll; */
  z-index:1;
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  left: 0px;
  background-color: transparent;
  height:100vh;
  width:100vw;
`;
export const StyledLi = styled.li`
  /* font-weight: 500; */
  color: white;
  text-align: right;
  padding-right: 2%;
  &:hover {
    /* font-weight: 600; */
    padding-right: 2.5%;
  }
`;
export const Button = styled.button`
  font-weight: 500;
  color: white;
  background-color: transparent;
  text-align: right;
  text-transform: uppercase;
  &:hover {
  }
`;
