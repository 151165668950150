// Import all the required modules
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import "./Gallery.css"
import { useMediaQuery } from "react-responsive";
import * as E from "./Elements/Elements.style";
import HeadTextElement from "./Elements/HeadTextElement";
import {
  GalleryHeaderLayout,
  GalleryLayout,
  DeGallery,
  DeGalleryPics,
  DePic,
  ZoomIcon,
  NothingFoundCard,
  ImgDivForNoData,
  StyledTabs,
} from "./MainAbout.styles";
import { BackCardGuIn } from "../AdmissionPageComponents/MainAdmission.styles";
import "animate.css";
import { Gradient } from "../../AppLayout.style";
import Footer from "../LandingPageComponents/Footer/Footer";
import { useState } from "react";

const imagePath = "assets/img/approach-rhibms.PNG";
const aboutedu = "assets/img/aboutedu.svg";
// const [zoom, setZoom] = useState("none");
function GalleryWrapper(props) {
  const isMobile = useMediaQuery({
    query: "(max-width: 786px)",
  });
  /**
   * @dataImg
   * 
   * An array of all the gallery images
   * It is put here within the GalleryWrapper so that it can
   */
  // 
  const dataImg = [
    
    
    {
      imgSrc: "assets/img/requirementsPics/tinified/_DSC0007.JPG",

      id: 1,
      title: "image",
    },
    {
      imgSrc:"assets/img/requirementsPics/tinified/_DSC0012.JPG",
      id: 2,
      title: "image",
    },
    {
      imgSrc: "assets/img/requirementsPics/tinified/_DSC0029.JPG",
      id: 3,
      title: "image",
    },
    {
      imgSrc:"assets/img/requirementsPics/tinified/_DSC0012.JPG",
      id: 4,
      title: "image",
    },
    {
      imgSrc:"assets/img/requirementsPics/tinified/_DSC0012.JPG",
      id: 5,
      title: "image",
    },

    {
      imgSrc: "assets/img/requirementsPics/tinified/_DSC0029.JPG",
      id: 6,
      title: "image",
    },
    {
      imgSrc:"assets/img/requirementsPics/tinified/_DSC0007.JPG",
      id: 7,
      title: "image",
    },
    {
      imgSrc:"assets/img/requirementsPics/tinified/_DSC0012.JPG",
      id: 8,
      title: "Image Of RHIBMS Activities",
    },
  ];
  return (
    <div>
      {/* Tenary to Check and display */}
      {isMobile ? (
        <GalleryMobile dataImg={dataImg} />
      ) : (
        <Gallery dataImg={dataImg} />
      )}
    </div>
  );
}
export default GalleryWrapper;
// Tbe Default Display
function Gallery(props) {
  const [hovered, setHovered] = useState(false);
  function ZoomIconToggle() {
    setHovered(true);
  }
  const [basicActive, setBasicActive] = useState("tab1");

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };
  return (
    <GalleryLayout>
      <GalleryHeaderLayout height="30vh">
        <E.NicePic>
          <E.HeadTextElement>
            <E.GalleryHead>RHIBMS GALLERY</E.GalleryHead>
          </E.HeadTextElement>
        </E.NicePic>
      </GalleryHeaderLayout>

      <>
        <StyledTabs
         className="mb-3"
          style={{
            height: "10%",
            padding: "0",
            width: "fit-content",
            
            fontSize: "40rem",
            margin: "0 auto",
            marginTop: "10%",
           
          }}
        >
          <MDBTabsItem className="animate__animated animate__backInUp">
            <MDBTabsLink
            
              onClick={() => handleBasicClick("tab1")}
              active={basicActive === "tab1"}
            >
              All
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink
              onClick={() => handleBasicClick("tab2")}
              active={basicActive === "tab2"}
            >
              Study
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink
              onClick={() => handleBasicClick("tab3")}
              active={basicActive === "tab3"}
            >
              Campus
            </MDBTabsLink>
          </MDBTabsItem>

          <MDBTabsItem>
            <MDBTabsLink
              onClick={() => handleBasicClick("tab4")}
              active={basicActive === "tab4"}
            >
              Others
            </MDBTabsLink>
          </MDBTabsItem>
        </StyledTabs>

        <MDBTabsContent style={{ paddingBottom: "20px" }}>
          <MDBTabsPane
            
            show={basicActive === "tab1"}
          >
            <DeGallery className="animate__animated animate__bounce">
              {props.dataImg.map(({ id, imgSrc, title }, i) => {
                return (
                  <DeGalleryPics key={i} id={id || 1}>
                    <DePic
                    className="animate__animated animate__fadeIn"
                      // onMouseEnter={() => setHovered(true)}
                      // onMouseLeave={() => setHovered(false)}
                      src={imgSrc}
                      alt={title}
                    />
                    <ZoomIcon
                      style={{
                        display: `${hovered ? "inline-block" : "none"}`,
                      }}
                    />
                  </DeGalleryPics>
                );
              })}
            </DeGallery>
          </MDBTabsPane>
          <MDBTabsPane
            className="animate__animated animate__backInUp"
            show={basicActive === "tab2"}
          >
            <NothingFoundCard>
              <ImgDivForNoData bg="assets/img/noData.svg" />
              content coming Soon
            </NothingFoundCard>
          </MDBTabsPane>
          <MDBTabsPane
            className="animate__animated animate__backInUp"
            show={basicActive === "tab3"}
          >
            <NothingFoundCard>
              <ImgDivForNoData bg="assets/img/noData.svg" />
              content coming Soon
            </NothingFoundCard>
          </MDBTabsPane>
          <MDBTabsPane
            className="animate__animated animate__backInUp"
            show={basicActive === "tab4"}
          >
            <NothingFoundCard>
              <ImgDivForNoData bg="assets/img/noData.svg" />
              content coming Soon
            </NothingFoundCard>
          </MDBTabsPane>
        </MDBTabsContent>
      </>

      <Footer />
    </GalleryLayout>
  );
}
// THe Display for Mobile
function GalleryMobile(props) {
  const [basicActive, setBasicActive] = useState("tab1");

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };
  return (
    <GalleryLayout>
      <GalleryHeaderLayout height="30vh">
        <E.NicePic>
          <E.HeadTextElement>
            <E.GalleryHead MobileFS="2em">RHIBMS GALLERY</E.GalleryHead>
          </E.HeadTextElement>
        </E.NicePic>
      </GalleryHeaderLayout>

      <>
      <StyledTabs
         className="mb-3"
          style={{
            height: "5%",
            padding: "0",
            width: "80%",
            fontSize: "20rem",
            margin: "0 auto",
            marginTop: "10%",
           
          }}
        >
          <MDBTabsItem 
          >
            <MDBTabsLink
            // style={{
            // paddingRight: "0",
            // width: "fit-content",
            // fontSize: "1rem",}}
              onClick={() => handleBasicClick("tab1")}
              active={basicActive === "tab1"}
            >
              All
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink
              onClick={() => handleBasicClick("tab2")}
              active={basicActive === "tab2"}
            >
              Study
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink
              onClick={() => handleBasicClick("tab3")}
              active={basicActive === "tab3"}
            >
              Campus
            </MDBTabsLink>
          </MDBTabsItem>

        </StyledTabs>

        <MDBTabsContent style={{ paddingBottom: "20px" }}>
          <MDBTabsPane
            className="animate__animated animate__backInUp"
            show={basicActive === "tab1"}
          >
            <DeGallery className="animate__animated animate__bounce">
              {props.dataImg.map(({ id, imgSrc, title }, i) => {
                return (
                  <DeGalleryPics key={i} id={id || 1}>
                    <DePic src={imgSrc} alt={title} />
                  </DeGalleryPics>
                );
              })}
            </DeGallery>
          </MDBTabsPane>
          <MDBTabsPane
            className="animate__animated animate__backInUp"
            show={basicActive === "tab2"}
          >
            <NothingFoundCard>
              <ImgDivForNoData bg="assets/img/noData.svg" />
              content coming Soon
            </NothingFoundCard>
          </MDBTabsPane>
          <MDBTabsPane
            className="animate__animated animate__backInUp"
            show={basicActive === "tab3"}
          >
            <NothingFoundCard>
              <ImgDivForNoData bg="assets/img/noData.svg" />
              content coming Soon
            </NothingFoundCard>
          </MDBTabsPane>
          <MDBTabsPane
            className="animate__animated animate__backInUp"
            show={basicActive === "tab4"}
          >
            <NothingFoundCard>
              <ImgDivForNoData bg="assets/img/noData.svg" />
              content coming Soon
            </NothingFoundCard>
          </MDBTabsPane>
        </MDBTabsContent>
      </>

      <Footer />
    </GalleryLayout>
  );
}
