import styled from "styled-components";
import { MDBBtn } from "mdb-react-ui-kit";
import { MdLocationPin, MdOutlineCall, MdMail } from "react-icons/md";
import { Link } from "react-router-dom";
export const CardOneLayout = styled.div`
  /* background: #b5d4d9; */
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 6px;
  margin-top: -1%;
  margin-left: 0;
`;
export const OtherLayout = styled.div`
  /* background: #b5d4d9; */
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 6px;
  margin: 4%;
  margin-top: -1%;
`;

export const ImageForCardLayout = styled.div`
  background: red;
  border-radius: 6px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
  height: 35%;
  background: url(${(props) => props.bg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 7%;
`;

export const OtherLayoutPic = styled(ImageForCardLayout)`
  height: 45%;
`;
export const Category = styled.div`
  text-align: center;
  /* text-align: left; */
  /* padding: 0; */
  padding-top:4%;
  text-transform: uppercase;
  color: #13e7a2;
  font-size: 0.7125em;
  /* line-height: 1.53846154em; */
  font-weight: 600;
`;

export const Headline = styled.h2`
  font-size: 1.025em;
  line-height: 1.14285714em;
  font-size: ${(props) => props.fS2};
  text-transform: capitalize;
  text-align: center;
  font-weight: 600;
  padding-left: 0;
  &:hover {
    color: darkgreen;
  }
`;
export const ContactP = styled.p`
  margin: 0 auto;
  width: 30%;
  width: ${(props) => props.width};
`;

export const FeaturedNewsLayout = styled.div`
  background: whitesmoke;
  background: ${(props) => props.bgColor};
  margin-top: -20%;
  margin-top: ${(props) => props.marginTop};
  padding: 10%;
  padding-top: 20%;
  padding: ${(props) => props.padding};
  padding-top: ${(props) => props.paddingTop};
  height: 45%;
  height: ${(props) => props.height};
  width: 100%;
`;
export const FeaturedNewsPic = styled.div`
  background: url(${(props) => props.bg});
  /* border: 1px solid white; */
  height: 60vh;  
  border-radius:6px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  width: 80%;
  width: ${(props) => props.width};
  margin: 0 auto;
  margin-top: -5.5%;
  background-size: cover;
  background-repeat: none;
`;
export const FeaturedNewsCategory = styled.p`
  padding-left: 2%;
  padding-top: 40%;
  /* width: 80%; */
  text-align: center;
  color: white;
  padding-top: ${(props) => props.CategoryPaddingTop};
  font-size: ${(props) => props.fS1};

  width: ${(props) => props.width};
`;
export const FeaturedNewsHeadline = styled.h3`
  /* margin: 0 auto; */
  color: white;
  text-align: center;

  /* width: ${(props) => props.width}; */
  font-size: ${(props) => props.fS2};

  /* font-size:1.7em; */
  &:hover {
    color: #25dea5;

    text-underline-offset: 10%;
  }
`;

export const AllNewsLayout = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexD};
  height: max-content;
  margin: 0 auto;
  width: 100%;
  padding-left: 0;
  padding: ${(props) => props.padding};

  background: rgb(125, 145, 130, 0.2);
`;
export const EachNews = styled.div`
  background: gainsboro;
  background: url(${(props) => props.bg});
  background-size: cover;
  background-position: center;
  height: 28vh;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%;
  border-radius:6px 6px 0 0;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  &:hover {
    outline: 0.5px solid white;
    -moz-outline-radius:6px;
    outline-offset: -12px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    /* border: 1px solid white; */
    background-size: 100%;
  }
`;
export const EachNewsContainer = styled.div`
  height: 41vh;
  width: 20%;
  /* padding-left:2%; */
  border-radius:6px;
  background: whitesmoke;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  width: ${(props) => props.width};
  margin: 2%;
  margin: ${(props) => props.margin};
  /* padding: 2%; */
`;
export const RecentHighlightsLayout = styled(AllNewsLayout)`
  background-color: transparent;
`;
export const RecentHighlightsContainer = styled(EachNewsContainer)`
  margin: ${(props) => props.mobileML};
`;
export const EachHighlight = styled(EachNews)``;
export const AllHighlights = styled.div`
  margin-top: 4%;
`;

export const RecentHighlightsText = styled.h2`
  text-align: center;
  font-weight: 700;
`;
