import styled from 'styled-components';

export const MainAcademicsLayout = styled.div`
    font-family: Montserrat;
    color: 'black';
    height: 280vh;
    background-image: url(${props => props.bg});
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-color: #F5F5F5;

    `
export const MainOnAcademicsHeaderLayout = styled.div`
    
    height: 45vh;
    margin-left: 0;
    background-image: url(${props => props.bg});
    `;