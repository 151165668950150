import { MDBBtn, MDBRow, MDBCol } from "mdb-react-ui-kit";
import NewsCard from "../News/NewsCard";
import * as S from "./LandingMnV.style";

import { useMediaQuery } from "react-responsive";
import { LinkLayout } from "../NavigationBar/Menu.style";

function LandingMnVWrapper() {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const isTablet = useMediaQuery({
    query: "(max-width: 1224px)",
  });

  const isMobile = useMediaQuery({
    query: "(max-width: 786px)",
  });

  const isPortrait = useMediaQuery({
    query: "(orientation: portrait)",
  });

  const isRetina = useMediaQuery({
    query: "(max-resolution: 300dpi)",
  });
  return <div>{isMobile ? <LandingMnVMobile /> : <LandingMnV />}</div>;
}

export default LandingMnVWrapper;
function LandingMnV() {
  return (
    <S.LandingMnVLayout>
      <S.AboutHead>What is RHibms?</S.AboutHead>
      <S.RhibmsOneStatement>
        It trains students in Health & Management
      </S.RhibmsOneStatement>
      <S.RhibmsExplain>
        Redemption Higher Institute of Biomedical and Management Sciences
        (RHIBMS) with slogan{" "}
        <span style={{ color: "green" }}>
          {" "}
          "Passion for excellent Training"
        </span>{" "}
        is one of the few training schools used by the Ministries of Higher
        Education, Public Health and Professional training for the training of
        skilled personnel in various disciplines more particularly Nurses and
        Laboratory Studies.
      </S.RhibmsExplain>
      <hr style={{ color: "gainsboro" }} />
      <S.RhibmsExplain>
        RHIBMS offers programs for{" "}
        <span style={{ color: "green" }}>
          {" "}
          <b>
            HND in Midwifery, BSc in Nursing, Masters in Business
            Administration(MBA)
          </b>
        </span>{" "}
        and many more programs in between.
      </S.RhibmsExplain>
      <div
        style={{
          textAlign: "center",
          width: "11rem",
          margin: "0 auto",
          fontSize: 1 + "rem",
          fontWeight: 500,
          lineHeight: 1.1,
          marginTop: "1%",
          padding: "10px",
          borderRadius:"6px",
          backgroundColor: "#098639",
          // border: "1px solid #188c5a",
          color: "#fafafa",
        }}
      >
        <LinkLayout  to="/">Apply Now</LinkLayout>
      </div>
    </S.LandingMnVLayout>
  );
}

function LandingMnVMobile() {
  return (
    <>
      <S.LandingMnVMobileLayout
        mobilePL="1%"
        mobilePR="1%"
        bg="assets/img/teach.png"
      >
        <S.AboutHead mobileFS="1em">What is Rhibms?</S.AboutHead>
        <S.RhibmsOneStatement mobileFS="1.5em" mobileWidth="80%">
          It trains students in Health & Management
        </S.RhibmsOneStatement>
        <S.RhibmsExplain mobileWidth="95%">
          Redemption Higher Institute of Biomedical and Management Sciences
          (RHIBMS) with slogan{" "}
          <span style={{ color: "green" }}>
            {" "}
            "Passion for excellent Training"
          </span>{" "}
          is one of the few training schools used by the Ministries of Higher
          Education, Public Health and Professional training for the training of
          skilled personnel in various disciplines more particularly Nurses and
          Laboratory Studies.
        </S.RhibmsExplain>
        <hr style={{ color: "gainsboro" }} />
        <S.RhibmsExplain mobileWidth="95%">
          RHIBMS offers programs for{" "}
          <span style={{ color: "green" }}>
            {" "}
            <b>
              HND in Midwifery, BSc in Nursing, Masters in Business
              Administration(MBA)
            </b>
          </span>{" "}
          and many more programs in between.
        </S.RhibmsExplain>
        <MDBBtn
          style={{
            margin: "10%",
            width: "80%",
            fontSize: 1 + "rem",
            fontWeight: 500,
            lineHeight: 1.1,
            marginTop: "1%",
            padding: "10px",
            backgroundColor: "#098639",
            // border: "1px solid #188c5a",
            color: "#fafafa",
          }}
        >
          Apply Now
        </MDBBtn>
      </S.LandingMnVMobileLayout>
    </>
  );
}
