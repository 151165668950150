import "animate.css";
import { Gradient } from "../../../AppLayout.style";
import { useState } from "react";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { useMediaQuery } from "react-responsive";
import Modal from "./Elements/Modal";
import { StaffDuties_DATA } from "./StaffDuties_DATA";
import * as S from "./OfficeOfStudentAffairs.style";
import {
  BackCardGuIn,
  BackCardRe,
} from "../../AdmissionPageComponents/MainAdmission.styles";
import Footer from "../../LandingPageComponents/Footer/Footer";
import {LinkLayout} from "../../LandingPageComponents/NavigationBar/Menu.style"

function OfficeOfStudentAffairsWrapper(props) {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const isTablet = useMediaQuery({
    query: "(min-width: 787px)",
    query: "(max-width: 1224px)",
  });

  const isMobile = useMediaQuery({
    query: "(max-width: 786px)",
  });

  return (
    <div>
      {isMobile ? (
        <OfficeOfStudentAffairsMobile isTablet={isTablet} />
      ) : (
        <OfficeOfStudentAffairs isTablet={isTablet} />
      )}
    </div>
  );
}

function OfficeOfStudentAffairs() {
  const imagePath = "assets/img/approach-rhibms.PNG";
  const teacher = "assets/img/teacher.svg";
  const AcademicsAffairs = "https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTl8fGVkdWNhdGlvbiUyMGJsYWNrfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=400&q=60";
  const [basicActive, setBasicActive] = useState("tab1");

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  const RenderModal = (val) => {
    const [show, setShow] = useState(false);
    return (
      <>
        <S.ActualRole
          className="animate__animated animate__bounceInLeft"
          onClick={() => {
            setShow(true);
          }}
          key={val.id}
        >
          <S.ActualRoleImage bg={val.icon} />
          <S.RoleText>{val.role}</S.RoleText>
        </S.ActualRole>
        <Modal
          onClose={() => setShow(false)}
          show={show}
          role={val.role}
          name={val.name}
          image={val.image}
        />
      </>
    );
  };
  return (
    <S.OAALayout bg={imagePath}>
      <S.OAAHeaderLayout bP="top" bg={AcademicsAffairs}></S.OAAHeaderLayout>
      <S.OAAPop>
        <S.OAAPopLead1>Providing Educational Support</S.OAAPopLead1>
        <S.OAAPopLead2>Encourage Involvement</S.OAAPopLead2>
        <S.OAAPopPara>
          The Office of Student Affairs manages the student body and strives to
          maintain general Student Health and Wellness , promote and manage
          Diversity, grow Alumni Relations, give Guidance for Students on
          Admissions and develop Campus Life and Activities.
          <br />
          The Vice President of the Student Office, <b>Lum Sandrine (SRN III)</b> works closely with
          the President, Secretary and other offices for the smooth functioning and implementation of the
          institutions policies.
        </S.OAAPopPara>
        <S.OAAPopBtn>
        <LinkLayout  to="/MnV">Read Our Vision</LinkLayout>{" "}
          
        </S.OAAPopBtn>
      </S.OAAPop>
      <S.StaffDuties>
        <S.StaffDutiesLeadText>
          Responsibilities of <br />
          <S.Bolded>staff</S.Bolded>
        </S.StaffDutiesLeadText>
        <S.ListOfRoles>{StaffDuties_DATA.map(RenderModal)}</S.ListOfRoles>
      </S.StaffDuties>
      {/* <MDBRow>
        <MDBCol md="8" className="col-example">
          <BackCardRe blurOnHover="3px" marginTop="7px" width="64vw" height="145vh">
            <BackCardGuIn
              marginLeft="170px"
              marginTop="7px"
              height="40vh"
              width="30vw"
              bg={imageRegistrar}
            />

            <div>
              <h2
                style={{
                  textAlign: "center",
                  fontWeight: 500,
                  letterSpacing: 3 + "px",
                }}
              >
                Registrar
              </h2>
              <p style={{ textAlign: "center" }}>
                TAMBEAGBOR HENRIETTA ép AYUK <br /> BSc In Curriculum Studies
                and Teaching M.Ed in Educational administration Ph.D researcher
                in Educational Administration
              </p>
              <span>Her responsibility includes:</span>
              <br />
              <LiLayout>
                {" "}
                The registrar works together with the Director for the smooth
                functioning and implementation of the institutions policies
              </LiLayout>
              <LiLayout>
                {" "}
                she supervises the work of the other departments for effective
                implementation
              </LiLayout>
              <LiLayout>
                {" "}
                Proposes the calendar of activities for the Academic year
              </LiLayout>
              <LiLayout> Signs students results and makes them public</LiLayout>
              <LiLayout>
                {" "}
                Signs all Attestations for new and old students
              </LiLayout>
              <LiLayout>
                {" "}
                Signs transcripts before students can collect
              </LiLayout>
              <LiLayout>
                {" "}
                Works with the Dean of studies in assigning courses to
                individual lecturers according to their area of competence
              </LiLayout>
              <LiLayout>
                {" "}
                Plans for recruitment of new staff when there is need and
                presents to the Director for approval
              </LiLayout>
              <LiLayout>
                {" "}
                Organizes interview sessions for new staff at the end of the
                year in preparation for the coming year
              </LiLayout>
              <LiLayout>
                {" "}
                She is in charge of staff induction with the assistance of the
                teaching and Learning at the beginning of the academic year
              </LiLayout>
              <LiLayout>
                {" "}
                Works together with the teaching/ learning in following up
                students with HND registration.
              </LiLayout>
              <LiLayout>
                {" "}
                Also works in collaboration with the teaching/learning when
                organizing assessments and end of semester examinations.
              </LiLayout>
              <LiLayout>
                {" "}
                Is in charge of monthly management meetings to evaluate
                activities of the month
              </LiLayout>
            </div>
          </BackCardRe>
        </MDBCol>
        <MDBCol md="4" className="col-example">
          <BackCardGuIn  marginTop="7px" height="145vh" width="30vw">
            <h2
              style={{
                marginLeft: 46.8,
                marginTop: -66.8,
                textAlign: "center",
              }}
            >
              Recent News
            </h2>
            <p style={{ marginLeft: 46.8, textAlign: "center" }}>
              RHIBMS, established in 2010 as a training center for Nursing
              Assistants under the ministry of Public Health.
            </p>
            <hr
              style={{
                textAlign: "center",
                marginLeft: -4.8,
                width: 340.8 + "px",
                color: "grey",
                fontWeight: 600,
              }}
            />
            <div
              style={{
                display: "block",
                textAlign: "center",
                marginLeft: 38.8,
              }}
              className="AllNewsHere"
            >
              <EachNewsForDisPage /> <br />
              <EachNewsForDisPage /> <br />
              <EachNewsForDisPage /> <br />
              <EachNewsForDisPage /> <br />
              <EachNewsForDisPage /> <br />
              <EachNewsForDisPage /> <br />
              <EachNewsForDisPage /> <br />
              <EachNewsForDisPage /> <br />
            </div>
          </BackCardGuIn>
        </MDBCol>
      </MDBRow> */}
      <Footer />
    </S.OAALayout>
  );
}

function OfficeOfStudentAffairsMobile() {
  const imagePath = "assets/img/approach-rhibms.PNG";
  const AcademicsAffairs = "https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTl8fGVkdWNhdGlvbiUyMGJsYWNrfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=400&q=60";

  const RenderModal = (val) => {
    const [show, setShow] = useState(false);
    return (
      <>
        <S.ActualRole
          width="98%"
          mB="3%"
          pT="3%"
          className="animate__animated animate__bounceInLeft"
          onClick={() => {
            setShow(true);
          }}
          key={val.id}
        >
          <S.ActualRoleImage bg={val.icon} />
          <S.RoleText>{val.role}</S.RoleText>
        </S.ActualRole>
        <Modal
          mobileCommonDetailsWidth="fit-content"
          mobileCommonDetailsML="1%"
          pL="0"
          display="block"
          mL="1px"
          mR="1%"
          mobileWidth="23rem"
          mobileHeight="23rem"
          onClose={() => setShow(false)}
          show={show}
          role={val.role}
          name={val.name}
          image={val.image}
        />
      </>
    );
  };
  return (
    <S.OAALayout bg={imagePath}>
      <S.OAAHeaderLayout
        bP="right"
        height="55vh"
        bg={AcademicsAffairs}
      ></S.OAAHeaderLayout>
      <S.OAAPop width="98%" mT="-30%">
        <S.OAAPopLead1 fS="1em" pT="2%">
          Advancing Academics
        </S.OAAPopLead1>
        <S.OAAPopLead2 fS="1.6em">Enriching Education</S.OAAPopLead2>
        <S.OAAPopPara padding="1%">
          The Office of Academic Affairs enables the success of students and
          faculty in teaching and learning, research, and creative expression.
          <br />
          The Registrar, <b>TAMBEAGBOR HENRIETTA ép AYUK</b> works together with
          the Director for the smooth functioning and implementation of the
          institutions policies.
        </S.OAAPopPara>
        <S.OAAPopBtn mL="20%">Read Our Vision</S.OAAPopBtn>
      </S.OAAPop>
      <S.StaffDuties height="fit-content">
        <S.StaffDutiesLeadText fS="1.5em  ">
          Responsibilities of <br />
          <S.Bolded>staff</S.Bolded>
        </S.StaffDutiesLeadText>
        <S.ListOfRoles display="block">
          {StaffDuties_DATA.map(RenderModal)}
        </S.ListOfRoles>
      </S.StaffDuties>

      <Footer />
    </S.OAALayout>
  );
}
export default OfficeOfStudentAffairsWrapper;
