// import { BsPerson, BsTelephoneFill, BsPersonFill } from "react-icons/bs";
// import { MdEmail, MdOutlineMessage } from "react-icons/md";

import * as Sub from "./Elements/Elements";
import {
  MDBRow,
  MDBCol,
  MDBInputGroup,
  MDBInputGroupText,
  MDBInputGroupElement,
  MDBBtn,
} from "mdb-react-ui-kit";
import { FaSearchLocation } from "react-icons/fa";
import { Gradient } from "../../AppLayout.style";
import { BackCardGuIn } from "./MainContact.styles";
import Footer from "../LandingPageComponents/Footer/Footer";

import {
  MainContactLayout,
  MainContactHeaderLayout,
  EnterMessageAndMapLayout,
} from "./MainContact.styles";
import MapContainer from "./MapContainer";
function MainContactWrapper() {
  const imagePath = "assets/img/approach-rhibms.PNG";
  const aboutedu = "assets/img/aboutedu.svg";

  return <div><MainContact /></div>;
}
export default MainContactWrapper;

function MainContact() {
  const imagePath = "assets/img/approach-rhibms.PNG";
  const aboutedu = "assets/img/aboutedu.svg";

  return (
    <MainContactLayout>
      <MainContactHeaderLayout>
        <Gradient height="45vh">
          <Sub.Elements />
        </Gradient>
      </MainContactHeaderLayout>
      <EnterMessageAndMapLayout>
      <Sub.EnterMessage margin="2%" width="30%"/>
      <MapContainer />
      </EnterMessageAndMapLayout>
      
      <Sub.HowCanWeHelp />

      <Footer />
    </MainContactLayout>
  );
}

function MainContactMobile() {
  const imagePath = "assets/img/approach-rhibms.PNG";
  const aboutedu = "assets/img/aboutedu.svg";

  return (
    <MainContactLayout>
      <MainContactHeaderLayout>
        <Gradient height="20vh">
          <Sub.Elements
            fS1="0.8em"
            fS2="1.4em"
            paddingTop="25%"
            widthOfContactP="90%"
          />
        </Gradient>
      </MainContactHeaderLayout>
      <Sub.EnterMessage width="80%" />
      <Sub.HowCanWeHelp display="block" ContactInfoWidth="100%" HelpTextHeadDetailsWidth="100%"/>
      {/* <MapContainer />lorem */}

      <Footer />
    </MainContactLayout>
  );
}
