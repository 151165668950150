
import { useContext, useState } from "react";
import "animate.css";
import { MdArrowDropDown } from "react-icons/md";

import * as S from "./Menu.style";
import * as T from "./Toggle.style";

import { MenuItem } from "./MenuItem";
function Toggle(props) {
  const [navbarOpen, setNavbarOpen] = useState(false);



  const handleToggle = () => {
    setNavbarOpen(!navbarOpen);
  };
  const hide = () => setNavbarOpen(false);
  const show = () => setNavbarOpen(true);
  const [isAdmissionClicked, setisAdmissionClicked] = useState(false);
  const [isOnCampusClicked, setisOnCampusClicked] = useState(false);
  const [isAcademicsClicked, setisAcademicsClicked] = useState(false);
  const [isAboutRhibmsClicked, setisAboutRhibmsClicked] = useState(false);
  return (
    <div>
      
        <T.ToggleLayout>
          <T.MenuDashes
            scrolled={props.scrolled}
            size={40}
            onClick={handleToggle}
            display={navbarOpen ? "none" : "block"}
            
          />
          <T.MenuClose
            className="animate__animated animate__fadeInRight"
            size={40}
            onClick={handleToggle}
            display={navbarOpen ? "block" : "none"}
          />

          <T.XtraLayer
            // onClick={() => setNavbarOpen(false)}
          >
            <T.StyledUl
              className="animate__animated animate__fadeInRight"
              display={navbarOpen ? "block" : "none"}
              zIndex="9"
              width={navbarOpen ? "80%" : "0"}
              onBlur={hide} onFocus={show}
            >
              <T.StyledLi>
                <S.LinkLayout  onClick={handleToggle} fW="500" to="/">
                  Home
                </S.LinkLayout>
              </T.StyledLi>
              <T.StyledLi>
                <hr />
                <T.Button
                  onClick={() =>
                    isAdmissionClicked
                      ? setisAdmissionClicked(false)
                      : setisAdmissionClicked(true)
                  }
                  className="menu-Button"
                >
                  Admissions <MdArrowDropDown size={30} />
                </T.Button>
                <hr />
                <T.StyledLi>
                  <div
                    style={{
                      display: `${isAdmissionClicked ? "block" : "none"}`,
                    }}
                  >
                    <T.StyledLi>
                      <S.LinkLayout onClick={handleToggle} fW="500" to="Admissions">
                        Admission requirements
                      </S.LinkLayout>
                    </T.StyledLi>
                    <hr />
                    <T.StyledLi>
                      <S.LinkLayout onClick={handleToggle} fW="500" to="/Admissions">
                        Admission Guidelines
                      </S.LinkLayout>
                    </T.StyledLi>
                    <hr />
                  </div>
                </T.StyledLi>
              </T.StyledLi>

              <T.StyledLi>
                <T.Button
                  onClick={() =>
                    isOnCampusClicked
                      ? setisOnCampusClicked(false)
                      : setisOnCampusClicked(true)
                  }
                  className="menu-T.Button"
                >
                  Oncampus <MdArrowDropDown size={30} />
                </T.Button>
                <hr />
                <div
                  style={{ display: `${isOnCampusClicked ? "block" : "none"}` }}
                >
                  <S.LinkLayout onClick={handleToggle} fW="500" to="OnCampus">
                    Clubs & Associations
                  </S.LinkLayout>
                  <hr />
                </div>
              </T.StyledLi>
              <T.StyledLi>
                <div>
                  <T.Button
                    onClick={() =>
                      isAcademicsClicked
                        ? setisAcademicsClicked(false)
                        : setisAcademicsClicked(true)
                    }
                    className="menu-T.Button"
                  >
                    academics <MdArrowDropDown size={30} />
                  </T.Button>
                  <hr />
                  <div
                    style={{
                      display: `${isAcademicsClicked ? "block" : "none"}`,
                    }}
                  >
                    <S.LinkLayout onClick={handleToggle} fW="500" to="Programs">
                      schools
                    </S.LinkLayout>
                    <hr />
                    <S.LinkLayout onClick={handleToggle} fW="500" to="Office-of-Academic-Affairs">
                      office of academic affairs
                    </S.LinkLayout>
                    <hr />
                    <S.LinkLayout onClick={handleToggle} fW="500" to="Office-of-Student-Affairs">
                      office of student affairs
                    </S.LinkLayout>
                    <hr />
                  </div>
                </div>
              </T.StyledLi>
              <T.StyledLi>
                <div>
                  <T.Button
                    onClick={() =>
                      isAboutRhibmsClicked
                        ? setisAboutRhibmsClicked(false)
                        : setisAboutRhibmsClicked(true)
                    }
                    className="menu-T.Button"
                  >
                    about rhibms <MdArrowDropDown size={30} />
                  </T.Button>
                  <hr />
                  <div
                    style={{
                      display: `${isAboutRhibmsClicked ? "block" : "none"}`,
                    }}
                  >
                    <T.StyledLi>
                      <S.LinkLayout onClick={handleToggle} fW="500" to="/FAQ">
                        FAQ
                      </S.LinkLayout>
                    </T.StyledLi>
                    <hr />
                    <T.StyledLi>
                      <S.LinkLayout onClick={handleToggle} fW="500" to="/Gallery">
                        Gallery
                      </S.LinkLayout>
                    </T.StyledLi>
                    <hr />
                    <T.StyledLi>
                      <S.LinkLayout onClick={handleToggle} fW="500" to="/MnV">
                        Mission & Vision
                      </S.LinkLayout>
                    </T.StyledLi>
                  </div>
                </div>
              </T.StyledLi>
              <T.StyledLi>
                <S.LinkLayout onClick={handleToggle} fW="500" to="/MainNews">
                  NEWS
                </S.LinkLayout>
              </T.StyledLi>
              <hr style={{ color: "white" }} />
              <T.StyledLi>
                <S.LinkLayout onClick={handleToggle} fW="500" to="/Contact">
                  CONTACT
                </S.LinkLayout>
              </T.StyledLi>
            </T.StyledUl>
          </T.XtraLayer>
        </T.ToggleLayout>
     
    </div>
  );
}

export default Toggle;
