import styled from 'styled-components';
// Get a button group from MDB
import { MDBBtnGroup, MDBBtn } from 'mdb-react-ui-kit';

export const DesignCard = styled.div`
    margin: 0 auto;
    margin-top: 12%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    height: 70vh;
    width: 40vw;
    border-radius: 10px;
    /* Centralise the contents of the div */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* Set background image for this div */
    background-image: url("../../../../assets/img/logo_2.png");
    background-size: 85% 85%;
    /* background-color: gainsboro; */
    background-position: center;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    text-align: center;

    

`;
export const JoinClassroom = styled.div`
    /* width: auto; */
    font-family: montserrat;

`;
// Get a button group from MDB
export const BtnGroup = styled(MDBBtnGroup)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5%;
    box-shadow: none;

    &:hover{
        box-shadow: none;
    }
`;

// Style a button from MDB
export const Btn = styled(MDBBtn)`
    padding:4%;
    background-color: #00bf63;
    &:hover{
        background-color: #265854;
    }
    & > a {
        color: black;
        text-decoration: none;
    }
`;