import { FaArrowRight } from "react-icons/fa";

import { MDBCard, MDBCardImage } from "mdb-react-ui-kit";

import "animate.css";
import styled from "styled-components";
import { Link } from "react-router-dom";

const WorkFlowHead = styled.h2`
  color: #265854;
  text-transform: uppercase;
  text-align: center;
  padding-top: 7%;
  padding-bottom: 1%;
  letter-spacing: 2px;
  font-size: 1.8125rem;
  font-weight: 700;
`;
const StyledWorkFlow = styled.div`
  display: flex;
  justify-content: space-between;
`;
const NumberOne = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2%;
  width: 30%;
`;
const Caption = styled.h4`
  padding-top: 4%;
  color: #314954;
  text-transform: capitalize;
  text-align: left;
`;
const NumberOneP = styled.p`
  
  font-size: 0.9rem;
`;
const NumberBox = styled.div`
  color: white;
  padding: 10%;
  font-size: 2rem;
  font-weight: 700;
  border-radius: 10px;
  text-align: center;
  height: 100px;
  width: 100px;
  background: #265854;
`;

function WorkFlowWrapper(props) {
  const Images = [
    "https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTl8fGVkdWNhdGlvbiUyMGJsYWNrfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
    "https://images.unsplash.com/photo-1514416432279-50fac261c7dd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8ZWR1Y2F0aW9uJTIwYmxhY2slMjBkb2N0b3J8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
    "https://media.istockphoto.com/photos/african-scientist-medical-worker-or-tech-in-modern-laboratory-picture-id635767756?b=1&k=20&m=635767756&s=170667a&w=0&h=TMzDoirjhgXD6FHW9sDPdniFL0_DE8gIiFPhXPuunVk=",
    "https://images.unsplash.com/photo-1639741660848-a07ebe5e2ce0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8Z3JhZHVhdGV8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
  ];
  return <WorkFlow Images={Images} />;
}

export default WorkFlowWrapper;
function WorkFlow(props) {
  return (
    <>
      <WorkFlowHead>Our WorkFlow </WorkFlowHead>
      <StyledWorkFlow>
        <NumberOne>
          <NumberBox>1</NumberBox>
          <Caption>Visit Us </Caption>
          <NumberOneP>
            Visiting our campus is the best way to experience RHIBMS. Schedule
            an individual tour or join an event, or visit the site online as you
            are doing now.
          </NumberOneP>
        </NumberOne>
        <NumberOne>
          <NumberBox>2</NumberBox>
          <Caption>Apply Now </Caption>
          <NumberOneP>
            We know you’re busy so we’ve made our application process clear and
            easy to understand.Apply in just 5 mins.
          </NumberOneP>
        </NumberOne>
        <NumberOne>
          <NumberBox>3</NumberBox>
          <Caption>Get Admitted </Caption>
          <NumberOneP>
            Congratulations on being accepted to RHIBMS Buea campus! There are
            couple of steps to take to secure your spot.
          </NumberOneP>
        </NumberOne>
        <NumberOne>
          <NumberBox>4</NumberBox>
          <Caption>Graduate </Caption>
          <NumberOneP>
            You've done it, you graduated and for that congratulations; Go for
            your dreams.
          </NumberOneP>
        </NumberOne>
      </StyledWorkFlow>
    </>
  );
}
