import * as E from "./Elements.style";
import { useState, useEffect, useContext } from "react";

function ProgramTable(props) {
  return (
    <E.ProgramTable>
      <E.TableHeading>
        {props.title}
        <E.Small>RHIBMS Buea</E.Small>
      </E.TableHeading>
      <E.ResponsiveTable>
        <E.TableHeader>
          <E.Col1>Program</E.Col1>
          <E.TuitionMerge>
            <E.Col1>Tuition</E.Col1>
            <E.TuitionMergeExpand>
              <E.Col1>Local|</E.Col1>
              <E.Col2>International</E.Col2>
            </E.TuitionMergeExpand>
          </E.TuitionMerge>
          <E.Col3>Duration</E.Col3>
        </E.TableHeader>
        {
          props.subPrograms.map((subProgram) =>(
            <E.TableRow>
            <E.Col4>{subProgram.title}</E.Col4>
            <E.Col4>{subProgram.tuition.local} | {subProgram.tuition.notLocal}</E.Col4>
            <E.Col4>{subProgram.duration} year(s)</E.Col4>
          </E.TableRow>
          ))
        }
            
          
      
        
      </E.ResponsiveTable>
    </E.ProgramTable>
  );
}

export default ProgramTable;
