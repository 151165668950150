import styled from "styled-components";
import { MDBBtn } from "mdb-react-ui-kit";
import { MdLocationPin, MdOutlineCall, MdMail } from "react-icons/md";
export const Header = styled.div`
  text-align: center;
  padding-top: 11%;
  padding-top: ${(props) => props.paddingTop};
`;
export const GotAQuestion = styled.span`
  font-size: 0.9em;
  color: #f2f3f3;
  font-size: ${(props) => props.fS1};
  text-transform: uppercase;
`;
export const ContactRhibms = styled.h1`
  font-size: 1.9em;
  font-size: ${(props) => props.fS2};
  text-transform: capitalize;
  font-weight: 700;
  padding: 1%;
`;
export const ContactP = styled.p`
  margin: 0 auto;
  width: 30%;
  width: ${(props) => props.width};
`;

// EnterMessage Component styles
export const EnterMessage = styled.p`
  margin: 0 auto;
  margin: ${(props) => props.margin};
  height: 28%;
  width: 25%;
  width: ${(props) => props.width};
  padding: 3%;
  padding-top: 2%;
  background: white;
  border: 0.8 solid;
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;
export const Pair = styled.p`
  display: flex;
`;
export const PairDiv = styled.p`
  margin: 1%;
  margin-top: 0;
`;
export const PairDiv1 = styled(PairDiv)``;
export const PairDiv2 = styled(PairDiv)``;
export const PairDivInput = styled.input`
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
`;
export const PairDivLabel = styled.label`
  text-transform: uppercase;
  font-weight: 700;
  &:after {
    content: " *";
    color: red;
  }
`;
export const Email = styled.div``;
export const EmailDivLabel = styled(PairDivLabel)``;
export const EmailDivInput = styled(PairDivInput)``;
export const Message = styled.div``;
export const MessageDivLabel = styled(PairDivLabel)``;
export const MessageDivInput = styled.textarea`
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  height: 65%;
`;
export const MessageBtn = styled(MDBBtn)`
  margin-top: 1%;
  background: darkgreen;
  width: 100%;
  height: 15%;
  &:hover {
    background: #068d14;
  }
`;

export const HowCanWeHelp = styled.div`
  display: flex;
  display: ${(props) => props.display};
  padding: 5%;
  margin-top: 5%;
  width: 100%;
  height: 40%;
  background-color: #46f3a8;
`;

export const ContactInfo = styled(PairDiv)`
  margin-top: 2%;
  height: 56%;
  width: 42%;
  width: ${(props) => props.width};
  padding: 3%;
  padding-top: 2%;
  background: white;
  border: 0.8 solid;
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;
export const AcreditedBy = styled.div``;
export const ContactInfoHeader = styled.div`
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  padding: 5%;
  width: 100%;
`;
export const ContactInfoHead = styled(ContactRhibms)`
  font-size: 1.4em;
  font-weight: 600;
`;
export const ContactInfoDetails = styled.div`
  padding: 4%;
`;
export const Location = styled(Pair)``;

export const Phone = styled(Pair)``;
export const Mail = styled(Pair)`
  color: blue;
  &:hover {
    cursor: pointer;
  }
`;

export const LocationIcon = styled(MdLocationPin)`
  color: green;
  margin-right: 7%;
`;
export const MailIcon = styled(MdMail)`
  color: green;
  margin-right: 7%;
`;
export const PhoneIcon = styled(MdOutlineCall)`
  color: green;
  margin-right: 7%;
`;

export const HowCanWeHelpText = styled(PairDiv)``;

export const HowCanWeHelpTextHeader = styled.div`
  padding: 4%;
`;
export const HowCanWeHelpTextHead = styled(ContactInfoHead)`
  padding: 0;
`;
export const HowCanWeHelpTextHeadDetails = styled.p`
  width: 60%;
  width: ${(props) => props.width};
  font-weight: 500;
`;
export const WhereIsIt = styled.h6`
  font-weight: 600;
  color: green;
`;

export const HoriLine = styled.hr`
  width: 60%;
  border: 1px solid black;
`;
