import {
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardLink,
  MDBListGroup,
  MDBListGroupItem,
  MDBBtn,
} from "mdb-react-ui-kit";
import { useState } from "react";
// import {Button} from "@ojage/devcombo-ui"
import "animate.css";
import styled from "styled-components";
import { OAAPopBtn } from "../../AcademicsPageComponents/OfficeOfAcademicAffairs/OfficeOfAcademicAffairs.style";
const WhyUsImage = "assets/img/whyUs.png";

const WhyUsMobileHead1 = styled.h2`
  color: #265854;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;
  font-size: 0.8125rem;
  font-weight: 700;
  vertical-align: baseline;
`;
const WhyUsMobileHead2 = styled.h3`
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-transform: capitalize;
  text-align: center;
  letter-spacing: 2px;
  font-size: 1.8125rem;
  font-weight: 800;
  vertical-align: baseline;
`;

function WhyUsWrapper(props) {
  
  const [viewed1, setViewed1] = useState(false);
  const [viewed2, setViewed2] = useState(false);
  function handleViewClick1() {
    if (viewed1) {
      setViewed1(false);
    } else {
      setViewed1(true);
    }
  }
  function handleViewClick2() {
    if (viewed2) {
      setViewed2(false);
    } else {
      setViewed2(true);
    }
  }
  return (
   
        <WhyUs
          handleViewClick2={handleViewClick2}
          handleViewClick1={handleViewClick1}
          viewed1={viewed1}
          viewed2={viewed2}
          Image2={props.Image2}
          Image1={props.Image1}
        />
     
  );
}

export default WhyUsWrapper;

function WhyUs({
  handleViewClick1,
  handleViewClick2,
  Image1,
  Image2,
  viewed1,
  viewed2,
}) {
  return (
    <div style={{ textAlign: "center", marginTop: "5.5%" }}>
      <h2
        style={{
          fontWeight: 600,
          textTransform: "uppercase",
          // color: "#265854",
        }}
      >
        Why Study with Us
      </h2>
      <p
        style={{
          margin: "auto",
          width: "60%",
        }}
      >
        College is an experience. It’s more than just taking classes, passing
        exams and going on to graduate. It’s about the friends you make, the
        experiences you have, the adventures you go on, and ultimately the place
        that becomes your home. It’s for all these reasons and more that our
        students love RHIBMS.
      </p>
      <div style={{ display: "flex", marginTop: "2.5%" }}>
        <MDBCard
          style={{
            borderRadius: "16px",
            margin: "auto",
            marginRight: "1%",
            height: "28rem",
            width: "28rem",
          }}
        >
          <MDBCardImage
            style={{
              borderRadius: "16px",
              height: "28rem",
              width: "28rem",
              objectFit: "cover",
            }}
            position="top"
            alt="School Staff & Students Growing Together"
            src={Image1}
          />
        </MDBCard>
        <div
          style={{
            margin: "auto",
            paddingTop: "2%",
            padding:"4%",
            height: "fit-content",
            width: "40.51rem",
            textAlign: "left",
          }}
        >
          <h3
            style={{
              color: "#265854",
              fontSize: 1 + "em",
              fontWeight: 700,
              lineHeight: "2.1rem",
            }}
          >
            {" "}
            01
          </h3>
          <p
            style={{
              fontSize: 1.7 + "em",
              fontWeight: 800,
              lineHeight: 1.1,
            }}
            className="bodyAbout"
          >
            70% of our graduates Become Job Creators
          </p>
          College is an experience. It’s more than just taking classes, passing
          exams and going on to graduate. It’s about the friends you make, the
          experiences you have, the adventures you go on, and ultimately the
          place that becomes your home. It’s for all these reasons and more that
          our students love RHIBMS.
          <br />
          <span
            className={`${
              viewed1
                ? "animate__animated animate__backInUp"
                : "animate__animated animate__backOutDown"
            }`}
            style={{ display: `${viewed1 ? "block" : "none"}` }}
          >
            More of this More oof this here!! College is an experience. It’s
            more than just taking classes, passing exams and going on to
            graduate. It’s about the friends you make, the experiences you have,
            the adventures you go on, and ultimately the place that becomes your
            home. It’s for all these reasons and more that our students love
            RHIBMS. More of this More oof this here!! More of this More oof this
            here!!
          </span>
          <br />
          <OAAPopBtn
            mL="0"
            mB="null"
            mW="9rem"
            mTTrans="uppercase"
            mH="fit-content"
            mFW="200"
            id="all-content-btn"
            onClick={handleViewClick1}
          >
            {viewed1 ? "See Less" : "See More"}
          </OAAPopBtn>
        </div>
      </div>

      <div style={{ display: "flex", marginLeft: "2%", marginTop: "0" }}>
        <div
          style={{
            margin: "auto",
            paddingTop: "1%",
            padding:"4%",
            height: "fit-content",
            width: "40.51rem",
            textAlign: "left",
          }}
        >
          <h3
            style={{
              color: "#265854",
              fontSize: 1 + "em",
              fontWeight: 700,
              lineHeight: "2.1rem",
            }}
          >
            {" "}
            02
          </h3>
          <p
            style={{
              fontSize: 1.7 + "em",
              fontWeight: 800,
              lineHeight: 1.1,
            }}
            className="bodyAbout"
          >
            Excellent Academic Record
          </p>
          College is an experience. It’s more than just taking classes, passing
          exams and going on to graduate. It’s about the friends you make, the
          experiences you have, the adventures you go on, and ultimately the
          place that becomes your home. It’s for all these reasons and more that
          our students love RHIBMS.
          <br />
          <span
            className={`${
              viewed2
                ? "animate__animated animate__backInUp"
                : "animate__animated animate__backOutDown"
            }`}
            style={{ display: `${viewed2 ? "block" : "none"}` }}
          >
            More of this More oof this here!! College is an experience. It’s
            more than just taking classes, passing exams and going on to
            graduate. It’s about the friends you make, the experiences you have,
            the adventures you go on, and ultimately the place that becomes your
            home. It’s for all these reasons and more that our students love
            RHIBMS. More of this More oof this here!! More of this More oof this
            here!!
          </span>
          <br />
          <OAAPopBtn
            mL="0"
            mW="9rem"
            mTTrans="uppercase"
            mH="fit-content"
            mFW="200"
            id="all-content-btn"
            onClick={handleViewClick2}
          >
            {viewed2 ? "See Less" : "See More"}
          </OAAPopBtn>
        </div>

        <MDBCard
          style={{
            borderRadius: "16px",
            margin: "auto",
            marginTop: "0",
            marginRight: "7%",
            height: "28rem",
            width: "28rem",
          }}
        >
          <MDBCardImage
            style={{
              borderRadius: "16px",
              height: "28rem",
              width: "28rem",
              objectFit: "cover",
            }}
            position="top"
            alt="School Staff & Students Growing Together"
            src={Image2}
          />
        </MDBCard>
      </div>
    </div>
  );
}

function WhyUsMobile({
  handleViewClick1,
  handleViewClick2,
  Image1,
  Image2,
  viewed1,
  viewed2,
}) {
  return (
    <div style={{ textAlign: "center", marginTop: "4%", padding: "4%" }}>
      <WhyUsMobileHead1>Why Study with Us</WhyUsMobileHead1>
      <WhyUsMobileHead2>We Fully Engage Students</WhyUsMobileHead2>

      <p
        style={{
          margin: "auto",
        }}
      >
        College is an experience. It’s more than just taking classes, passing
        exams and going on to graduate. It’s about the friends you make, the
        experiences you have and more
      </p>
      <div style={{}}>
        <MDBCard
          style={{
            borderRadius: "16px",
            margin: "0 auto",
            marginTop: "2%",
            height: "18rem",
            width: "18rem",
          }}
        >
          <MDBCardImage
            style={{
              borderRadius: "16px",
              height: "28rem",
              width: "28rem",
              objectFit: "cover",
            }}
            position="top"
            alt="School Staff & Students Growing Together"
            src={Image1}
          />
        </MDBCard>
        <div
          style={{
            margin: "auto",
            paddingTop: "12%",
            height: "fit-content",
            padding: "4%",
            textAlign: "left",
          }}
        >
          <h3
            style={{
              color: "#265854",
              fontSize: 1 + "em",
              fontWeight: 700,
              lineHeight: "2.1rem",
            }}
          >
            {" "}
            01
          </h3>
          <p
            style={{
              fontSize: 1.7 + "em",
              fontWeight: 800,
              lineHeight: 1.1,
            }}
            className="bodyAbout"
          >
            70% of our graduates Become Job Creators
          </p>
          College is an experience. It’s more than just taking classes, passing
          exams and going on to graduate. It’s about the friends you make, the
          experiences you have, the adventures you go on, and ultimately the
          place that becomes your home. It’s for all these reasons and more that
          our students love RHIBMS.
          <br />
          <MDBBtn
            style={{
              marginTop: "2%",
              width: "9rem",
              fontSize: 0.7 + "rem",
              fontWeight: 300,
              lineHeight: 1.1,
              padding: "15px",
              backgroundColor: "transparent",
              border: "1px solid #265854",
              color: "#265854",
            }}
          >
            See More
          </MDBBtn>
        </div>
      </div>

      <div style={{}}>
        <MDBCard
          style={{
            borderRadius: "16px",
            margin: "0 auto",
            marginTop: "2%",
            height: "18rem",
            width: "18rem",
          }}
        >
          <MDBCardImage
            style={{
              borderRadius: "16px",
              height: "28rem",
              width: "28rem",
              objectFit: "cover",
            }}
            position="top"
            alt="School Staff & Students Growing Together"
            src={Image2}
          />
        </MDBCard>
        <div
          style={{
            margin: "auto",
            paddingTop: "12%",
            height: "fit-content",
            padding: "4%",
            textAlign: "left",
          }}
        >
          <h3
            style={{
              color: "#265854",
              fontSize: 1 + "em",
              fontWeight: 700,
              lineHeight: "2.1rem",
            }}
          >
            {" "}
            02
          </h3>
          <p
            style={{
              fontSize: 1.7 + "em",
              fontWeight: 800,
              lineHeight: 1.1,
            }}
            className="bodyAbout"
          >
            Excellent Academic Record
          </p>
          College is an experience. It’s more than just taking classes, passing
          exams and going on to graduate. It’s about the friends you make, the
          experiences you have, the adventures you go on, and ultimately the
          place that becomes your home. It’s for all these reasons and more that
          our students love RHIBMS.
          <br />
          <MDBBtn
            style={{
              marginTop: "2%",
              width: "9rem",
              fontSize: 0.7 + "rem",
              fontWeight: 300,
              lineHeight: 1.1,
              padding: "15px",
              backgroundColor: "transparent",
              border: "1px solid #265854",
              color: "#265854",
            }}
          >
            See More
          </MDBBtn>
          <MDBCardBody
            className="animate__animated animate__hinge animate__delay-10s"
            style={{
              borderStyle: "dashed",
              borderWidth: 1 + "px",
              fontWeight: 500,
              background: "whitesmoke",
            }}
          ></MDBCardBody>
        </div>
      </div>
    </div>
  );
}
