import { MDBRow, MDBCol, MDBContainer } from "mdb-react-ui-kit";
import { useMediaQuery } from "react-responsive";
import AllNews_DATA from "./Elements/AllNews_DATA.json";

import "bootstrap";
// import "./News.css";
// import "./Base.css";
// import "./Scaffolding.less";
import Footer from "../LandingPageComponents/Footer/Footer";

import { MainNewsLayout, MainNewsHeaderLayout } from "./MainNews.style";
import * as Sub from "./Elements/Elements";
import { Gradient } from "../../AppLayout.style";
import { Link } from "react-router-dom";
function MainNewsWrapper() {
  const FeaturedNews = AllNews_DATA.slice(0, 1);

  const CardOneImg =
    "https://images.unsplash.com/photo-1601979112151-26f9fa4520b5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8Y2FtZXJvb258ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60";
  const aboutedu = "assets/img/aboutedu.svg";
  const isMobile = useMediaQuery({
    query: "(max-width: 786px)",
  });
  return (
    <div>
      {isMobile ? (
        <MainNewsMobile FeaturedNews={FeaturedNews} />
      ) : (
        <MainNews FeaturedNews={FeaturedNews} />
      )}
    </div>
  );
}
export default MainNewsWrapper;

function MainNews(props) {
  const imagePath = "assets/img/approach-rhibms.PNG";
  const aboutedu = "assets/img/aboutedu.svg";
  const logo = "assets/img/logo.png";

  return (
    <MainNewsLayout>
      <MainNewsHeaderLayout height="10%" bg={aboutedu}>
        <Gradient
          bg="180deg,
          #020502 0%,
          #020502 25%,
    #020502 50%,
    #020502 75%,
    #020502 100%"
          height="100%"
        >
          {/* <Sub.Elements /> */}
        </Gradient>
      </MainNewsHeaderLayout>

      <Sub.FeaturedNews
        fS2="1.4em"
        fS1="1.7em"
        FeaturedNews={props.FeaturedNews}
      />
      <Sub.AllNews />
      <Sub.RecentHighlights />
      <Footer />
    </MainNewsLayout>
  );
}

function MainNewsMobile(props) {
  const imagePath = "assets/img/approach-rhibms.PNG";
  const aboutedu = "assets/img/aboutedu.svg";
  const logo = "assets/img/logo.png";

  return (
    <MainNewsLayout>
      <MainNewsHeaderLayout height="10%" bg={aboutedu}>
        <Gradient
          bg="180deg,
          #020502 0%,
          #020502 25%,
    #020502 50%,
    #020502 75%,
    #020502 100%"
          height="100%"
        >
          {/* <Sub.Elements /> */}
        </Gradient>
      </MainNewsHeaderLayout>

      <Sub.FeaturedNews
        padding="0"
        paddingTop="0"
        marginTop="0"
        width="100%"
        height="30%"
        bgColor="transparent"
        FeaturedNews={props.FeaturedNews}
        // For Category
        CategoryPaddingTop="63%"
        fS2="1.4em"
        fS1="1.7em"
      />
      <Sub.AllNews
        AllNewsLayoutPadding="2%"
        NewsContainerMargin="0"
        NewsContainerWidth="100%"
        flexD="column"
      />
      <Sub.RecentHighlights
      mobileRecentHighlightsContainerML="0"
        RecentHighlightsContainerPadding="2"
        RecentHighlightsContainerMargin="0"
        RecentHighlightsContainerWidth="100%"
        flexD="column"
      />
      <Footer />
    </MainNewsLayout>
  );
}
