export const StaffDuties_DATA = [
  {
    id: "11",
    image: "https://cdn5.vectorstock.com/i/thumb-large/87/24/man-profile-vector-31988724.jpg",
    role: "President",
    name: "Ngotto Williams",
    icon: "assets/img/quiz.svg",
  },
  {
    id: "9858",
    image: "https://cdn5.vectorstock.com/i/thumb-large/67/89/woman-portrait-generic-female-avatar-gender-vector-40196789.jpg",
    role: "Vice President",
    name: "Lum Sandrine",
    icon: "assets/img/quiz.svg",
  },
  {
    id: "8826",
    image: "https://cdn5.vectorstock.com/i/thumb-large/87/24/man-profile-vector-31988724.jpg",
    role: "Secretary",
    name: "Betrand Ojong",
    icon: "assets/img/quiz.svg",
  },
  {
    id: "40",
    image: "https://cdn5.vectorstock.com/i/thumb-large/67/89/woman-portrait-generic-female-avatar-gender-vector-40196789.jpg",
    role: "Financial Secretary",
    name: "Njuno Saraphine",
    icon: "assets/img/quiz.svg",
  },
  {
    id: "166",
    image: "https://cdn5.vectorstock.com/i/thumb-large/67/89/woman-portrait-generic-female-avatar-gender-vector-40196789.jpg",
    role: "Sanitation",
    name: "Ticha Beryl Amundam",
    icon: "assets/img/quiz.svg",
  },
];
