import { Fade } from "react-slideshow-image";
import Hero from "../Hero/Hero";
import { SlideContainer, EachImg } from "../../../AppLayout.style";
import { BannerLayout, SharpOverlay } from "./Banner.style";
import { useMediaQuery } from "react-responsive";

function Banner(props) {
  const isMobile = useMediaQuery({
    query: "(max-width: 786px)",
  });
  return (
    <BannerLayout >
       {isMobile ? (
        <SharpOverlay MobileTop="0" MobileLeft="0" MobileWidth="100%" MobileHeight="100vh" />
      ) : (
        <SharpOverlay />
      )}
      
      <Hero />
      {/* <Gradient style={{
      textAlign:"center",
    }} > */}
        <SlideContainer style={{
      textAlign:"left",
    }}>
          <Fade
            autoplay
            duration={5000}
            transitionDuration={500}
            arrows={false}
            pauseOnHover={false}
            canSwipe={false}
            Easing={"ease"}
          >
            {props.imagePaths.map((image, index) => (
              <EachImg src={image} alt={`RHIBMS BEST SCHOOL ${index}`} />
            ))}
          </Fade>
        </SlideContainer>
        {/* <AnimatedScrollCue size={45}/> */}
     
      {/* </Gradient> */}
      
    </BannerLayout>
  );
}

export default Banner;
