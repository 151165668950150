import styled from 'styled-components';

export const MainNewsLayout = styled.div`
    font-family: Montserrat;
  color: "black";
  height: 200vh;
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-color: white;
    `;

export const MainNewsHeaderLayout = styled.div`
  height: ${(props) => props.height};
  margin-left: 0;
  background-image: url(${(props) => props.bg});
`;