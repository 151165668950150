import "./styles.css";

import React, { useEffect, useRef, useState } from "react";
import { MenuItem } from "./MenuItem";
import * as S from "./Menu.style";
import Toggle from "./Toggle";
function Menu(props) {
  let color = "white";
  let Bcolor = "transparent";
  if (props.scrolled) {
    color = "black";
    // Bcolor = "white";
  }
  const [active, setActive] = useState(false);
  const handleClick = () => {
    if (active) {
      setActive(false);
      // alert(active)
    } else {
      setActive(true);
      // alert(active)
    }
  };
  useEffect(() => {
    handleClick();
  }, []);

  let [isOverButton, setIsOverButton] = useState(false);
  let [isTouchInput, setIsTouchInput] = useState();
  let [hasClicked, setHasClicked] = useState();
  let button = useRef(null);

  useEffect(() => {
    setIsTouchInput(false);
    setHasClicked(false);
  }, [hasClicked]);

  function HandleMouseEnter(e) {
    setIsOverButton(true);
  }
  function CrossClass() {
    return `dropdown-content ${isOverButton ? "onIt" : ""} `;
  }
  return (
    <>
      <S.MenuForOther>
        <Toggle scrolled={props.scrolled} />
      </S.MenuForOther>
    </>
  );
}

export default Menu;
