import { useState, useEffect, useContext } from "react";
import "animate.css";
import * as E from "./Elements.style";
import * as S from "../../../AdmissionPageComponents/MainAdmission.styles";

function Modal(props) {
  //   useEffect(() => {
  //     window.addEventListener("keydown", closeOnEscapeKeyDown);
  //     return function cleanup() {
  //       window.removeEventListener("keydown", closeOnEscapeKeyDown);
  //     };
  //   }, []);
  // const [programType, setProgramType] = useState("something");
  if (!props.show) {
    return null;
  }
  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose();
    }
  };

  return (
    <E.Modal
      className="animate__animated animate__fadeInBottomLeft"
      // onLoad={() => setProgramType(`${props.title}`)}
      onClick={() => {
        // alert(admission.programType)
        props.onClose();
      }}
    >
      <E.ModalContent onClick={(e) => e.stopPropagation()}>
        <E.ModalHeader></E.ModalHeader>
        <hr />
        <E.ModalBody pL={props.pL} display={props.display}>
          {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
          <E.Image
            mL={props.mL}
            mR={props.mR}
            mobileWidth={props.mobileWidth}
            mobileHeight={props.mobileHeight}
            border="none"
            bR="6px"
            bBL="0"
            bBR="0"
            bPos="center"
            bg={props.image}
            bSize="cover"
            position="sticky"
            top="24%"
            width="22rem"
            height="22rem"
          />
          <E.CommonDetails
          
            mobileCommonDetailsML={props.mobileCommonDetailsML}
            mobileCommonDetailsWidth={props.mobileCommonDetailsWidth}
            width="39rem"
            height="22rem"
          >
            <E.NameAndLocale>
              <E.Name>{props.name}</E.Name>
              <div style={{ marginTop: "4.5%" }}>
                <E.LocationPin />
                Cameroon
              </div>
            </E.NameAndLocale>
            <E.Role>{props.role}</E.Role>
            <E.Qualifications>
              <E.QualificationsLead>Qualifications</E.QualificationsLead>
              <E.QualificationsUl>
                <E.QualificationsLi>
                  BSc In Curriculum Studies and Teaching
                </E.QualificationsLi>
                <E.QualificationsLi>
                  M.Ed in Educational administration
                </E.QualificationsLi>
                <E.QualificationsLi>
                  Ph.D researcher in Educational Administration
                </E.QualificationsLi>
              </E.QualificationsUl>
            </E.Qualifications>
          </E.CommonDetails>
          {/* </div> */}
        </E.ModalBody>
        <E.ModalFooter>
          <E.ModalBtn onClick={props.onClose}>Close!</E.ModalBtn>
        </E.ModalFooter>
      </E.ModalContent>
    </E.Modal>
  );
}

export default Modal;
