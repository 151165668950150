import { HashRouter as Router, Routes, Route } from "react-router-dom";
import MainAcademics from "./Components/AcademicsPageComponents/MainAcademics.jsx";
import MainAdmissionWrapper from "./Components/AdmissionPageComponents/MainAdmission.jsx";
import MainLandingPage from "./Components/LandingPageComponents/MainLandingPage.jsx";
import MainOnCampus from "./Components/OnCampusPageComponents/MainOnCampus.jsx";
import MainAbout from "./Components/AboutPageComponents/MainAbout.jsx";
import MainContact from "./Components/ContactPageComponents/MainContact.jsx";
import ErrorBoundary from "./ErrorBoundary.jsx";
import NavigationBar from "./Components/LandingPageComponents/NavigationBar/NavigationBar";
import SchoolWrapper from "./Components/AcademicsPageComponents/SchoolsPage/Schools";
import OfficeOfAcademicAffairsWrapper from "./Components/AcademicsPageComponents/OfficeOfAcademicAffairs/OfficeOfAcademicAffairs.jsx";
import FAQWrapper from "./Components/AboutPageComponents/FAQ.jsx";
import GalleryWrapper from "./Components/AboutPageComponents/Gallery.jsx";
import MnV from "./Components/AboutPageComponents/MnV.jsx";
import OfficeOfStudentAffairsWrapper from "./Components/AcademicsPageComponents/OfficeOfStudentAffairs/OfficeOfStudentAffairs.jsx";
import MainEvents from "./Components/EventsPageComponents/MainEvents";
import MainNews from "./Components/NewsPageComponents/MainNews";
import TheNewsWrapper from "./Components/NewsPageComponents/TheNews";
import JoinClassroom from "./Components/JoinClassroom/JoinClassroom";

import ScrollButton from "./Components/Shared/ScrollButton.jsx";
import ScrollToTop from "./Components/Shared/ScrollToTop.jsx";

function AppLaptopS() {
  return (
    // <ErrorBoundary>
    <>
      <Router>
        <NavigationBar />
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<MainLandingPage />} />
          <Route path="/Admissions" element={<MainAdmissionWrapper />} />
          <Route path="/OnCampus" element={<MainOnCampus />} />
          <Route path="/Programs" element={<SchoolWrapper />} />
          <Route
            path="/office-of-academic-affairs"
            element={<OfficeOfAcademicAffairsWrapper />}
          />
          <Route
            path="/office-of-student-affairs"
            element={<OfficeOfStudentAffairsWrapper />}
          />

          <Route path="/TheNews" element={<TheNewsWrapper />} />
          <Route path="/MnV" element={<MnV />} />
          <Route path="/Gallery" element={<GalleryWrapper />} />
          <Route path="/FAQ" element={<FAQWrapper />} />
          <Route path="/MainNews" element={<MainNews />} />
          <Route path="/MainEvents" element={<MainEvents />} />
          <Route path="/Contact" element={<MainContact />} />
          <Route path="/join-classroom" element = {<JoinClassroom />} />
          <Route
            path="*"
            element={
              <h1>
                404 Page <b>not</b> found
              </h1>
            }
          />
        </Routes>
        <ScrollButton />
      </Router>
    </>
    // </ErrorBoundary>
  );
}

export default AppLaptopS;
