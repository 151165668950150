import "animate.css";
import { LinkLayout } from "../NavigationBar/Menu.style";
import {
  FeaturedCardInHero,
  FeaturedCardBodyInHero,
  FeaturedCardTitleInHero,
  FeaturedCardTextInHero,
  FeaturedCardHeaderInHero,
  FeaturedCardBtnInHero,
  FeaturedCardFooterInHero,
} from "./Hero.style";

function MDBCardInHero(props) {
  return (

    <FeaturedCardInHero
      margin={props.margin + "%"}
      display={props.display}
      height={props.height}
      maxWidth={props.maxWidth}
      className="animate__animated animate__rubberBand"
      alignment="center"
      style={{}}
    >
      <FeaturedCardHeaderInHero>{props.CardHead}</FeaturedCardHeaderInHero>
      <FeaturedCardBodyInHero>
        <FeaturedCardTitleInHero>{props.Heading}</FeaturedCardTitleInHero>
        <FeaturedCardTextInHero>{props.TextBody}</FeaturedCardTextInHero>
        <FeaturedCardBtnInHero>
          <LinkLayout pR="0" to="/Admissions">
            <b>{props.ButtonText}</b>
          </LinkLayout>
        </FeaturedCardBtnInHero>
      </FeaturedCardBodyInHero>

    </FeaturedCardInHero>
  
  );
}

export default MDBCardInHero;
