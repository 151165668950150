export const StaffDuties_DATA = [
  {
    id: "11",
    image: "https://cdn5.vectorstock.com/i/thumb-large/87/24/man-profile-vector-31988724.jpg",
    role: "Registrar",
    name: "TAMBEAGBOR HENRIETTA ép AYUK",
    icon: "assets/img/quiz.svg",
  },
  {
    id: "9858",
    image: "https://cdn5.vectorstock.com/i/thumb-large/67/89/woman-portrait-generic-female-avatar-gender-vector-40196789.jpg",
    role: "Dean of Studies",
    name: "Peter Ojong Ayuk",
    icon: "assets/img/quiz.svg",
  },
  {
    id: "8826",
    image: "https://cdn5.vectorstock.com/i/thumb-large/87/24/man-profile-vector-31988724.jpg",
    role: "H O D(Nursing)",
    name: "Wepnyu Gladys",
    icon: "assets/img/quiz.svg",
  },
  {
    id: "40",
    image: "https://cdn5.vectorstock.com/i/thumb-large/67/89/woman-portrait-generic-female-avatar-gender-vector-40196789.jpg",
    role: "Vice Chancellor",
    name: "Ayuk Oroko Gina",
    icon: "assets/img/quiz.svg",
  },
  {
    id: "166",
    image: "https://cdn5.vectorstock.com/i/thumb-large/67/89/woman-portrait-generic-female-avatar-gender-vector-40196789.jpg",
    role: "Lecturer",
    name: "Eghombi Leonard E",
    icon: "assets/img/quiz.svg",
  },
];
