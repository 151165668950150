import { useState, useEffect, useContext } from "react";
import "animate.css";
import * as E from "./Elements.style";
import * as S from "../MainAdmission.styles";
import ProgramTable from "./ProgramTable";

function Modal(props) {
  //   useEffect(() => {
  //     window.addEventListener("keydown", closeOnEscapeKeyDown);
  //     return function cleanup() {
  //       window.removeEventListener("keydown", closeOnEscapeKeyDown);
  //     };
  //   }, []);
  // const [programType, setProgramType] = useState("something");
  if (!props.show) {
    return null;
  }
  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose();
    }
  };

  return (
    <E.Modal
      // onLoad={() => setProgramType(`${props.title}`)}
      onClick={() => {
        // alert(admission.programType)
        props.onClose();
      }}
    >
      <E.ModalContent
        className="animate__animated animate__bounceInUp"
        onClick={(e) => e.stopPropagation()}
      >
        <E.ModalHeader>
          <E.ModalTitle></E.ModalTitle>
        </E.ModalHeader>
        <E.ModalBody>
          <S.BackCardGuInCir
            border="none"
            // bR="20px"
            // bBL="0"
            // bBR="0"
            bPos="center"
            bg={props.img}
            bSize="cover"
            position="sticky"
            top="24%"
            width="22rem"
            height="22rem"
          />
          <ProgramTable
            tuition={props.tuition}
            title={props.title}
            subPrograms={props.subPrograms}
          />
        </E.ModalBody>
        <E.ModalFooter>
          <E.ModalBtn onClick={props.onClose}>Close!</E.ModalBtn>
        </E.ModalFooter>
      </E.ModalContent>
    </E.Modal>
  );
}

export default Modal;
