import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { useMediaQuery } from "react-responsive";
import * as E from "./Elements/Elements.style";
import HeadTextElement from "./Elements/HeadTextElement";
import { LinkLayout } from "../LandingPageComponents/NavigationBar/Menu.style";
import * as S from "./MainOnCampus.styles";
import { Gradient } from "../../AppLayout.style";
import Footer from "../LandingPageComponents/Footer/Footer";
import {
  BackCardGuIn,
  BackCardRe,
} from "../AdmissionPageComponents/MainAdmission.styles";
function MainOnCampusWrapper(props) {
  const isMobile = useMediaQuery({
    query: "(max-width: 786px)",
  });

  return <div>{isMobile ? <MainOnCampusMobile /> : <MainOnCampus />}</div>;
}
export default MainOnCampusWrapper;

const EachNewsForDisPage = () => {
  return (
    <div
      style={{
        margin: "0 auto",
        width: 340.8 + "px",
        height: "fit-content",
        backgroundColor: "whitesmoke",
        fontWeight: 500,
        padding: "4%",
        borderRadius: "2%",
      }}
      className="EachNews"
    >
      <LinkLayout to="/">
        Core aspects of climate models are sound — the proof’s in the plankton
      </LinkLayout>
      <p
        style={{
          fontSize: "9pt",
          fontWeight: 300,
        }}
      >
        March 7, 2022
      </p>
    </div>
  );
};
function MainOnCampus() {
  const HeaderLayoutImg = "https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTl8fGVkdWNhdGlvbiUyMGJsYWNrfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=400&q=60";

  const imagePath = "assets/img/requirementsPics/_DSC0050.jpg";
  const OnCampus = "assets/img/OnCampus.png";
  return (
    <S.MainOnCampusLayout bg={OnCampus}>
      <S.MainOnCampusHeaderLayout bRepeat="no-repeat" bg={HeaderLayoutImg}>
        <Gradient>
          <E.HeadTextElement>
            <HeadTextElement
              spanMargin="0 auto"
              title="Clubs and Associations"
            />
          </E.HeadTextElement>
        </Gradient>
      </S.MainOnCampusHeaderLayout>

      <BackCardRe marginTop="-10%" height="auto" marginBottom="4%">
        <MDBRow>
          <MDBCol
            md="8"
            className="col-example"
            style={{ textAlign: "center" }}
          >
            <h2
              style={{
                textAlign: "center",
                fontWeight: 500,
                letterSpacing: 3 + "px",
              }}
            >
              Get out there and get involved!!
            </h2>
            <p>
              To become a world-leading training and research university whose
              products, research findings and public engagements shall
              significantly improve the quality of life in Cameroon and
              Sub-Saharan Africa, friendship, personal growth, career
              development and university inclusiveness is needed.
            </p>
            <img
              style={{ borderRadius: 15 + "px" }}
              src={imagePath}
              alt="Image from"
            />
            <h5
              style={{
                marginTop: "2%",
                fontWeight: 600,
                textTransform: "uppercase",
                color: "#265854",
              }}
            >
              Why Should Students Get Involved?
            </h5>

            <p>
              <li>It's a chance to build your skills</li> It's an opportunity to
              demonstrate your time management It helps you become more
              collaborative It can lead to great friendships and new networking
              opportunities It can build your self-confidence It can increase
              your marketability It can provide you with a well-rounded college
              experience.
            </p>
          </MDBCol>
          <MDBCol md="4" className="col-example">
            <BackCardGuIn padding="1%" height="fit-content" width="100%">
              <h2
                style={{
                  textAlign: "center",
                }}
              >
                Recent News
              </h2>
              <p style={{textAlign: "center" }}>
                RHIBMS, established in 2010 as a training center for Nursing
                Assistants under the ministry of Public Health.
              </p>
              <hr
                style={{
                  textAlign: "center",
                  marginLeft: -4.8,
                  color: "grey",
                  fontWeight: 600,
                }}
              />
              <div
                style={{
                  display: "block",
                }}
                className="AllNewsHere"
              >
                <EachNewsForDisPage /> <br />
                <EachNewsForDisPage /> <br />
                <EachNewsForDisPage /> <br />
              </div>
            </BackCardGuIn>
          </MDBCol>
        </MDBRow>
      </BackCardRe>
      <Footer />
    </S.MainOnCampusLayout>
  );
}

function MainOnCampusMobile() {
  const HeaderLayoutImg = "assets/img/approach-rhibms.PNG";

  const imagePath = "assets/img/requirementsPics/_DSC0050.jpg";
  const OnCampus = "assets/img/OnCampus.png";
  return (
    <S.MainOnCampusLayout bg={OnCampus}>
      <S.MainOnCampusHeaderLayout height="15%" bRepeat="no-repeat" bg={HeaderLayoutImg}>
        <Gradient>
          <E.HeadTextElement>
            <HeadTextElement
            fS="17px"
              paddingTop="25%"
              spanMargin="0 auto"
              title="Clubs and Associations"
            />
          </E.HeadTextElement>
        </Gradient>
      </S.MainOnCampusHeaderLayout>
      <BackCardRe width="auto" margin="1%" padding="1%" height="auto" marginBottom="4%">
        <div>
          <div
            md="8"
            className="col-example"
            style={{ textAlign: "center" }}
          >
            <h2
              style={{
                textAlign: "center",
                fontWeight: 500,
                letterSpacing: 3 + "px",
              }}
            >
              Get out there and get involved!!
            </h2>
            <p>
              To become a world-leading training and research university whose
              products, research findings and public engagements shall
              significantly improve the quality of life in Cameroon and
              Sub-Saharan Africa, friendship, personal growth, career
              development and university inclusiveness is needed.
            </p>
            <img
              style={{ borderRadius: 15 + "px" }}
              src={imagePath}
              alt="Image from"
            />
            <h5
              style={{
                marginTop: "2%",
                fontWeight: 600,
                textTransform: "uppercase",
                color: "#265854",
              }}
            >
              Why Should Students Get Involved?
            </h5>

            <p>
              <li>It's a chance to build your skills</li> It's an opportunity to
              demonstrate your time management It helps you become more
              collaborative It can lead to great friendships and new networking
              opportunities It can build your self-confidence It can increase
              your marketability It can provide you with a well-rounded college
              experience.
            </p>
          </div>
          <div md="4" className="col-example">
            <BackCardGuIn padding="1%" height="fit-content" width="auto">
              <h2
                style={{
                  
                  textAlign: "center",
                }}
              >
                Recent News
              </h2>
              <p style={{ textAlign: "center" }}>
                RHIBMS, established in 2010 as a training center for Nursing
                Assistants under the ministry of Public Health.
              </p>
              <hr
                style={{
                  textAlign: "center",
                  color: "grey",
                  fontWeight: 600,
                }}
              />
              <div
                style={{
                  display: "block",
                }}
                className="AllNewsHere"
              >
                <EachNewsForDisPage /> <br />
                <EachNewsForDisPage /> <br />
                <EachNewsForDisPage /> <br />
              </div>
            </BackCardGuIn>
          </div>
        </div>
      </BackCardRe>
      <Footer />
    </S.MainOnCampusLayout>
  );
}
