import styled from "styled-components";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardHeader,
  MDBCardFooter,
  MDBBtn,
} from "mdb-react-ui-kit";

import { device } from "../../../AppLayout.style";

export const HeroLayout = styled.div`
  content: "";
  position: absolute;
  top: 0px;
  height: 100vh;
  width: 100%;
  color: white;
  z-index: 1;
`;

export const BigTextLayout = styled.div`
  position: relative;
  margin: 0 auto;
  font-size: 2rem;
  vertical-align: midddle;
  text-align: center;
  text-transform: capitalize;

  padding-top: 21%;
 

  line-height: 50px;
  color: white;
  width: 80%;
  font-weight: 600;

`;
export const MajorText = styled.h1`
  
  color: white;
  font-size: 4.2em;
  line-height: 93%;
  font-weight: 800;
  text-transform: capitalize;
`;
export const MinorText = styled.h3`
  font-size: 1.2em;
  color: white;

`;
export const BtnGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: auto;
`;

export const FeaturedCardInHero = styled(MDBCard)`
  @media ${device.mobileS} {
  }
  display: ${(props) => props.display};
  backdrop-filter: blur(1px) saturate(180%);
  -webkit-backdrop-filter: blur(1px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
  max-width: ${(props) => props.maxWidth};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};

`;

export const FeaturedCardBodyInHero = styled(MDBCardBody)``;
export const FeaturedCardTitleInHero = styled(MDBCardTitle)`
  font-size: 15px;
`;
export const FeaturedCardTextInHero = styled(MDBCardText)`
  font-size: 14px;
`;
export const FeaturedCardHeaderInHero = styled(MDBCardHeader)``;
export const FeaturedCardFooterInHero = styled(MDBCardFooter)`
  color: white;
`;
export const FeaturedCardBtnInHero = styled(MDBBtn)`
  background-color: #00bf63;
  &:hover {
    background-color: #265854;
  }
`;
export const FeaturedCardInHeroContainer = styled.div`
  width: ${(props) => props.width};
  width: ${(props) => props.height};
  position: ${(props) => props.position};
  top: ${(props) => props.top};
  left: ${(props) => props.left};

`;
