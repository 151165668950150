import styled, { keyframes } from "styled-components";

export const MainEventsLayout = styled.div`
  font-family: Montserrat;
  color: "black";
  height: fit-content;
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-color: white;
`;
export const GradientAnime = keyframes`
0% {background-position: 0% 50%;}
50% {background-position: 100% 50%; }
100% {
		background-position: 0% 50%;
	}
`;
export const MainEventsHeaderLayout = styled.div`
  height: fit-content;
  position: sticky;
  top: -4.5%;
  z-index: 1;
  margin-left: 0;
  margin-bottom: 4%;
  padding-bottom: 4vh;
  background: linear-gradient(-45deg, #49BC88, #61887B,#254734, #265854,#265054);
  background-size: 400% 400%;
  animation: ${GradientAnime} 15s ease infinite;
`;
