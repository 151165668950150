import * as E from "./Elements.styles";
import { Link } from "react-router-dom";
import AllNews_DATA from "./AllNews_DATA.json";
import { Gradient } from "../../../AppLayout.style";

export function CardOne(props) {
  return (
    <E.CardOneLayout height={props.height} width={props.width}>
      <E.ImageForCardLayout bg={props.imgInside} />
      <E.Category>University Affairs</E.Category>
      <E.Headline fS2={props.fS}>
        Doing HIV Screening in Buea, and sensitizing the public
      </E.Headline>
    </E.CardOneLayout>
  );
}

export function OtherCard(props) {
  return (
    <E.OtherLayout height={props.height} width={props.width}>
      <E.OtherLayoutPic bg={props.imgInside} />
      <E.Category>University Affairs</E.Category>
      <E.Headline fS2={props.fS}>
        Doing HIV Screening in Buea, and sensitizing the public
      </E.Headline>
    </E.OtherLayout>
  );
}

export function ShortNewsCard(props) {
  return (
    <article className="card large featured" id="top-story-3">
      <div className="card-photo ">
        <Link
          to="https://news.stanford.edu/2022/04/06/veronica-roberts-direct-cantor-arts-center-stanford/"
          tabindex="-1"
          aria-hidden="true"
          role="presentation"
          data-ga-action="position-3"
        >
          <img
            src="https://news.stanford.edu/wp-content/uploads/2022/04/1500x1000_Cantor-announcement-22.04.04-VR-by-Manny-Alcala-345x230.jpg"
            className="hidden-xs wp-post-image"
            alt=""
            srcset="https://news.stanford.edu/wp-content/uploads/2022/04/1500x1000_Cantor-announcement-22.04.04-VR-by-Manny-Alcala-345x230.jpg 345w, https://news.stanford.edu/wp-content/uploads/2022/04/1500x1000_Cantor-announcement-22.04.04-VR-by-Manny-Alcala-555x370.jpg 555w, https://news.stanford.edu/wp-content/uploads/2022/04/1500x1000_Cantor-announcement-22.04.04-VR-by-Manny-Alcala-795x530.jpg 795w, https://news.stanford.edu/wp-content/uploads/2022/04/1500x1000_Cantor-announcement-22.04.04-VR-by-Manny-Alcala-960x640.jpg 960w, https://news.stanford.edu/wp-content/uploads/2022/04/1500x1000_Cantor-announcement-22.04.04-VR-by-Manny-Alcala-705x470.jpg 705w, https://news.stanford.edu/wp-content/uploads/2022/04/1500x1000_Cantor-announcement-22.04.04-VR-by-Manny-Alcala-375x250.jpg 375w, https://news.stanford.edu/wp-content/uploads/2022/04/1500x1000_Cantor-announcement-22.04.04-VR-by-Manny-Alcala.jpg 1500w, https://news.stanford.edu/wp-content/uploads/2022/04/1500x1000_Cantor-announcement-22.04.04-VR-by-Manny-Alcala-345x230@2x.jpg 690w, https://news.stanford.edu/wp-content/uploads/2022/04/1500x1000_Cantor-announcement-22.04.04-VR-by-Manny-Alcala-555x370@2x.jpg 1110w, https://news.stanford.edu/wp-content/uploads/2022/04/1500x1000_Cantor-announcement-22.04.04-VR-by-Manny-Alcala-705x470@2x.jpg 1410w, https://news.stanford.edu/wp-content/uploads/2022/04/1500x1000_Cantor-announcement-22.04.04-VR-by-Manny-Alcala-375x250@2x.jpg 750w"
            sizes="(max-width: 345px) 100vw, 345px"
          />
        </Link>
      </div>{" "}
      <div
        className="card-content"
        style={{ padding: "4%", marginTop: "8%", height: "20.344%" }}
      >
        <div className="meta">
          <Link
            style={{
              fontSize: "0.825em",
              color: "green",
              padding: "4%",
              textTransform: "uppercase",
            }}
            to="https://news.stanford.edu/section/arts-creativity/"
          >
            Arts &amp; Creativity
          </Link>
        </div>
        <h3>
          <Link
            style={{ fontSize: "1.1875em;" }}
            to="https://news.stanford.edu/2022/04/06/veronica-roberts-direct-cantor-arts-center-stanford/"
            data-ga-action="position-3"
          >
            Veronica Roberts to direct the Cantor Arts Center at Stanford
          </Link>
        </h3>
        <p className="teaser">
          The curator of modern and contemporary art at the Blanton Museum of
          Art at The University of Texas at Austin will bring her vision for the
          Cantor to campus on July 5.
        </p>
      </div>{" "}
    </article>
  );
}
export function FeaturedNews(props) {
  const { FeaturedNews } = props;

  return (
    <E.FeaturedNewsLayout
      padding={props.padding}
      height={props.height}
      width={props.width}
      paddingTop={props.paddingTop}
      marginTop={props.marginTop}
      bgColor={props.bgColor}
    >
      <E.FeaturedNewsPic width={props.width} bg={FeaturedNews[0].image}>
        <Gradient
        bR="0 0 6px 6px"
        bg="180deg,
          #e8efe819 0%,
          #c9d2c91a 25%,
    #63736319 50%,
    #0c110c1d 75%,
    #071607d1 100%"
          height="100%"
        >
          <E.FeaturedNewsCategory CategoryPaddingTop={props.CategoryPaddingTop}>
            {FeaturedNews[0].category}
          </E.FeaturedNewsCategory>
          <E.FeaturedNewsHeadline to="/TheNews" fS1={props.fS1} fS2={props.fS2}>
          <Link 
            style={{
              fontSize: "inherit",
              color: "inherit",
              textDecoration:"inherit",
            }}
            to ="/TheNews">
            <u style={{textAlign:"center"}}>
              {FeaturedNews[0].headline}
            </u>
            </Link>
          </E.FeaturedNewsHeadline>
        </Gradient>
      </E.FeaturedNewsPic>
    </E.FeaturedNewsLayout>
  );
}

export function AllNews(props) {
  return (
    <E.AllNewsLayout padding={props.AllNewsLayoutPadding} flexD={props.flexD}>
      {AllNews_DATA.slice(0, 4).map((val, key) => (
        <E.EachNewsContainer
          width={props.NewsContainerWidth}
          margin={props.NewsContainerMargin}
        >
          <E.EachNews bg={val.image} />
          <E.Category>{val.category}</E.Category>
          <Link to="/">
            <E.Headline fS2={props.fS}>{val.headline}</E.Headline>
          </Link>
        </E.EachNewsContainer>
      ))}
    </E.AllNewsLayout>
  );
}

export function RecentHighlights(props) {
  return (
    <E.AllHighlights padding={props.RecentHighlightsContainerPadding}>
      <E.RecentHighlightsText>Recent Highlights</E.RecentHighlightsText>
      <E.RecentHighlightsLayout  flexD={props.flexD}>
        {AllNews_DATA.slice(5, 9).map((val, key) => (
          <E.RecentHighlightsContainer
          mobileML={props.mobileRecentHighlightsContainerML}
            key={key.id}
            width={props.RecentHighlightsContainerWidth}
            margin={props.RecentHighlightsContaineMargin}
          >
            <E.EachHighlight bg={val.image} />
            <E.Category>{val.category}</E.Category>
            <Link to="/TheNews">
              <E.Headline fS2={props.fS}>{val.headline}</E.Headline>
            </Link>
          </E.RecentHighlightsContainer>
        ))}
      </E.RecentHighlightsLayout>
    </E.AllHighlights>
  );
}
