import "animate.css";
import styled from "styled-components";
import { MDBContainer } from "mdb-react-ui-kit";
import * as S from "../../AppLayout.style";
import WhyUsWrapper from "./WhyUs/WhyUs";
import WorkFlow from "./SchoolWorkFlow/WorkFlow";
import Banner from "./Banner/Banner";

import MiniatureAboutImageCardWrapper from "./MiniatureAbout/MiniatureAboutImageCard";
import Footer from "./Footer/Footer";
import EventsWrapper from "./SchoolEvents/SchoolEvents";
import TestimonialsWrapper from "./Testimonials/Testimonials";
import NewsWrapper from "./News/News";
import LandingMnVWrapper from "./LandingMnV/LandMnV";
import FeaturedWrapper from "./Featured/Featured";

const StyledMDBContainer = styled(MDBContainer)``;
function MainLandingPage() {
  const imagePath = "assets/img/requirementsPics/_DSC0012.JPG";
  const image1Path = "assets/img/requirementsPics/_DSC0012.JPG";
  const image2Path = "assets/img/requirementsPics/tinified/_DSC0007.JPG";
  const imagePaths = [
    "assets/img/requirementsPics/tinified/_DSC0007.JPG",
    "assets/img/requirementsPics/tinified/_DSC0012.JPG",
    "assets/img/requirementsPics/tinified/_DSC0029.JPG",
  ];
  return (
    <S.AppLayout>
      <Banner imagePaths={imagePaths} />
      {/* What is RHIBMS*/}
      <LandingMnVWrapper />
      {/* Admissions */}
      <FeaturedWrapper />
      
      {/* Why Study With Us!??? */}
      {/* The Testimonial comes hence */}
      <TestimonialsWrapper />
      <S.WhyUsLayout>
        <WhyUsWrapper Image2={image2Path} Image1={image1Path} />
      </S.WhyUsLayout>
      {/* The Workflow of Study */}

      <S.WorkFlowLayout>
        <WorkFlow />
      </S.WorkFlowLayout>
      {/* Miniature About */}
      <S.MiniatureAbout>
        <MiniatureAboutImageCardWrapper Image={imagePath} />
      </S.MiniatureAbout>

      <NewsWrapper />
      {/* Rhibms_Events */}
      <EventsWrapper />

      <Footer />
    </S.AppLayout>
  );
}

export default MainLandingPage;
