import { HelpfulLinksRightLayout } from "./FooterArea.styled";

function HelpfulLinksRight() {
  return (
    <HelpfulLinksRightLayout>
     <br/>
     <br/>
      <nav class="nav justify-content-left">
        <a class="nav-link active" href="#">
          <p style={{ color: "white" }}> Careers </p>
        </a>
        <a class="nav-link" href="#">
          <p style={{ color: "white" }}> Rules of Conduct </p>
        </a>
        <a class="nav-link" href="#">
          <p style={{ color: "white" }}> Campus Tours </p>
        </a>
      </nav>

     

      <nav class="nav justify-content-left">
        <a class="nav-link active" href="#">
          <p style={{ color: "white" }}> Visit RHIBMS </p>
        </a>
        <a class="nav-link" href="#">
          {/* <p style={{ color: "white" }}> Partner Universities </p> */}
        </a>
      </nav>
    </HelpfulLinksRightLayout>
  );
}

export default HelpfulLinksRight;
