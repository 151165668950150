import * as E from "./Elements.styles";
import { useState, useRef } from "react";

const SearchForm = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [barOpened, setBarOpened] = useState(false);
  const formRef = useRef();
  const inputFocus = useRef();

  const onFormSubmit = (e) => {
    // When form submited, clear input, close the searchbar and do something with input
    props.ParentCallBack(searchTerm);
    e.preventDefault();
    // setSearchTerm("");
    setBarOpened(false);
    // After form submit, do what you want with the input value
    console.log(`Form was submited with input: ${searchTerm}`);
  };
  return (
    <E.Form
      barOpened={barOpened}
      onClick={() => {
        // When form clicked, set state of baropened to true and focus the input
        setBarOpened(true);
        inputFocus.current.focus();
      }}
      // on focus open search bar
      onFocus={() => {
        setBarOpened(true);
        inputFocus.current.focus();
      }}
      // on blur close search bar
      onBlur={() => {
        setBarOpened(false);
      }}
      // On submit, call the onFormSubmit function
      onSubmit={onFormSubmit}
      ref={formRef}
    >
      <E.Button type="submit" barOpened={barOpened}>
        <E.StyledFaSearch />
      </E.Button>
      <E.Input
        onChange={(event) => setSearchTerm(event.target.value)}
        ref={inputFocus}
        value={searchTerm}
        barOpened={barOpened}
        placeholder="Search for an Event"
      />
    </E.Form>
  );
};

export default SearchForm;
