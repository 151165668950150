import styled from "styled-components";
import { device } from "../../../AppLayout.style";

export const LogoLayout = styled.div`
  @media ${device.mobileS} {
    position: ${(props) => (props.isMobile ? "sticky" : "")};
    
    width: 50px;
    height: 50px;
    margin-left: 12px;
    padding-top: 4%;
    &:hover {
      cursor: pointer;
    }
  }
  @media ${device.laptop} {
    display: inline-block;
    vertical-align: middle;
    width: 60px;
    height: 60px;
    margin-left: 52px;
    padding-top: 14px;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const RHIBMS = styled.h2`
  @media ${device.mobileS} {
    color: ${(props) => (props.scrolled ? "black" : "white")};
    font-weight: bolder;
    position: absolute;
    left: 70px;
    top: 10%;
    text-align: center;
    text-transform: uppercase;
  }

  @media ${device.laptop} {
    color: ${(props) => (props.scrolled ? "black" : "white")};
    font-weight: bolder;
    position: absolute;
    left: 120px;
    top: 13px;
    text-align: center;
    text-transform: uppercase;
  }
`;

export const University = styled.div`
  @media ${device.mobileS} {
    position: absolute;
    font-size: 1rem;
    left: 2px;
    top: 24px;
    font-weight: 300px;
  }
  @media ${device.laptop} {
    position: absolute;
    font-size: 1rem;
    left: 0px;
    top: 34px;
    letter-spacing: 0.2ch;
    font-weight: 300px;
  }
`;
