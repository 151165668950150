import "animate.css";
import { LinkLayout } from "../NavigationBar/Menu.style";
import {
  FeaturedCardInHero,
  FeaturedCardBodyInHero,
  FeaturedCardTitleInHero,
  FeaturedCardTextInHero,
  FeaturedCardHeaderInHero,
  FeaturedCardBtnInHero,
  FeaturedCardFooterInHero,
} from "./Hero.style";

function MDBCardInHero(props) {
  return (
    // <FeaturedCardInHeroContainer
    //   position="absolute"
    //   left="90px"
    //   width="20px"
    //   top="60%"
    //   height="20vh"
    // >
    <FeaturedCardInHero
      margin={props.margin + "%"}
      display={props.display}
      height={props.height}
      maxWidth={props.maxWidth}
      className="animate__animated animate__rubberBand"
      alignment="center"
      style={{}}
    >
      <FeaturedCardHeaderInHero>{props.CardHead}</FeaturedCardHeaderInHero>
      <FeaturedCardBodyInHero>
        <FeaturedCardTitleInHero>{props.Heading}</FeaturedCardTitleInHero>
        <FeaturedCardTextInHero>{props.TextBody}</FeaturedCardTextInHero>
        <FeaturedCardBtnInHero>
          <LinkLayout  to="/Admissions">
            <b>{props.ButtonText}</b>
          </LinkLayout>
        </FeaturedCardBtnInHero>
      </FeaturedCardBodyInHero>
      {/* <FeaturedCardFooterInHero className="text-muted">
          {props.DaysAgo} days ago
        </FeaturedCardFooterInHero> */}
    </FeaturedCardInHero>
    //  {/* </FeaturedCardInHeroContainer> */}
  );
}

export default MDBCardInHero;
