import { Link } from "react-router-dom";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";

import styled from "styled-components";

import MDBCardi from "./NewsCard";
import { LinkLayout, NavLinkLayout } from "../NavigationBar/Menu.style";

const NewsMobileHead1 = styled.h2`
  color: #265854;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;
  font-size: 0.8125rem;
  font-weight: 700;
  vertical-align: baseline;
`;
const NewsMobileHead2 = styled.h3`
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-transform: capitalize;
  text-align: center;
  letter-spacing: 2px;
  font-size: 1.8125rem;
  font-weight: 800;
  vertical-align: baseline;
`;

function NewsWrapper() {
 

  return <News />
}
export default NewsWrapper;

function News() {
  return (
  <LinkLayout to="/TheNews" pR="0">
    <div
      id="Rhibms_News"
      style={{
        textAlign: "center",
        // background: `url('assets/img/news.png')`,
        backgroundPosition: "cover",
        marginTop: "7%",
      }}
    >
      <h2
        id="Rhibms_News_Heading"
        style={{ textTransform: "uppercase", fontWeight: 800 }}
      >
        RHIBMS News
      </h2>
      <p style={{ letterSpacing: 13 + "px", color: "#265854" }}>
        The latest stories from RHIBMS
      </p>
      <>
        <MDBRow className="mb-3" style={{ padding: "3%", paddingBottom: "0" }}>
          <MDBCol size="6" lg="4" className="col-example">
            <MDBCardi
              cardTitle="Social Sciences"
              cardSubtitle="The real benefits of paid family leave"
              cardText="RHIBMS comes with new techniques and has made our vision great"
              cardLink="View More"
              anotherLink="Check it Out"
              imagePath="https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTl8fGVkdWNhdGlvbiUyMGJsYWNrfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=400&q=60"
            />
          </MDBCol>{" "}
          <MDBCol size="6" lg="4" className="col-example">
            <MDBCardi
              cardTitle="Approach"
              cardSubtitle="THe vision of RHIBMS is one of good fortune"
              cardText="RHIBMS comes with new techniques and has made our vision great"
              cardLink="View More"
              anotherLink="Check it Out"
              imagePath="https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTl8fGVkdWNhdGlvbiUyMGJsYWNrfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=400&q=60"
            />
          </MDBCol>
          <MDBCol
            
            size="6"
            lg="4"
            className="col-example"
          >
            <MDBCardi
              cardTitle="Valiant Lecture Stream"
              cardSubtitle="Lecturers and students work"
              cardText="RHIBMS comes with new techniques and has made our vision great"
              cardLink="View More"
              anotherLink="Check it Out"
              imagePath="https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTl8fGVkdWNhdGlvbiUyMGJsYWNrfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=400&q=60"
            />
          </MDBCol>
        </MDBRow>

        <MDBRow style={{ paddingLeft: "3%", paddingRight: "3%" }}>
          <MDBCol size="6" sm="4" className="col-example">
            <MDBCardi
              cardTitle="Approach"
              cardSubtitle="THe vision of RHIBMS is one of good fortune"
              cardText="RHIBMS comes with new techniques and has made our vision great"
              cardLink="View More"
              anotherLink="Check it Out"
              imagePath="https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTl8fGVkdWNhdGlvbiUyMGJsYWNrfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=400&q=60"
            />
          </MDBCol>
          <MDBCol size="6" sm="4" className="col-example">
            <MDBCardi
              cardTitle="Approach"
              cardSubtitle="THe vision of RHIBMS is one of good fortune"
              cardText="RHIBMS comes with new techniques and has made our vision great"
              cardLink="View More"
              anotherLink="Check it Out"
              imagePath="https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTl8fGVkdWNhdGlvbiUyMGJsYWNrfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=400&q=60"
            />
          </MDBCol>
          <MDBCol size="6" sm="4" className="col-example">
            <MDBCardi
              cardTitle="Approach"
              cardSubtitle="THe vision of RHIBMS is one of good fortune"
              cardText="RHIBMS comes with new techniques and has made our vision great"
              cardLink="View More"
              anotherLink="Check it Out"
              imagePath="https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTl8fGVkdWNhdGlvbiUyMGJsYWNrfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=400&q=60"
            />
          </MDBCol>
        </MDBRow>
        <div style={{ paddingTop: 20 + "px" }}>
          <MDBBtn
            style={{
              fontWeight: 600,
              backgroundColor: "#00bf63",
              height: 10 + "%",
              width: 30 + "%",
            }}
          >
            <Link
              style={{
                color: "#fafafa",
              }}
              to="/MainNews"
            >
              More RHIBMS News
            </Link>
          </MDBBtn>
        </div>
      </>
    </div>
    </LinkLayout>
  );
}

