import styled from "styled-components";
import {
  FaMailchimp,
  FaFacebook,
  FaTwitter,
  FaWhatsapp,
  FaLinkedin,
} from "react-icons/fa";
export const MiscNewsLayout = styled.div`
  &:hover {
    cursor: pointer;
  }
`;
export const MiscNewsPic = styled.div`
  height: 20vh;
  border-radius: 6px;
  width: 100%;
  background-color: gainsboro;
  background-size: cover;
  object-fit:contain;
  background: url(${props => props.bg});
  &:hover {
    border: 1px solid gainsboro;
  }
`;
export const MiscNewsText = styled.p`
  width: 80%;
  &:hover {
    border-bottom: 1px solid #265854;
    color: #265854;
  }
`;
export const IconBox = styled.div`
  padding: 5%;
  padding-top: 0;
  &:hover {
    cursor: pointer;
  }
`;
function MiscNews(props) {

  const {RelatedNews} = props
  console.log(RelatedNews)
  return (
    <MiscNewsLayout>
      <MiscNewsPic bg={RelatedNews[0].image}/>
      <MiscNewsText>
        {RelatedNews[0].headline}
      </MiscNewsText>
    </MiscNewsLayout>
  );
}

export default MiscNews;
