import Footer from "../LandingPageComponents/Footer/Footer";

import { Gradient } from "../../AppLayout.style";
import {
  MainAcademicsLayout,
  MainOnAcademicsHeaderLayout,
} from "./MainAcademics.styles";
function MainAcademics() {
  const imagePath = "assets/img/approach-rhibms.PNG";
  const aboutedu = "assets/img/aboutedu.svg";
  return (
    <MainAcademicsLayout bg={imagePath}>
      <MainOnAcademicsHeaderLayout bg={aboutedu}>
        <Gradient>
          <h1
            style={{
              textAlign: "center",
              position: "relative",
              top: 120 + "px",
              color: "whitesmoke",
            }}
          >
            Academics
          </h1>
          <svg
            style={{ position: "absolute", top: 60 + "px" }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
          >
            <path
              fill="#F5F5F5"
              fill-opacity="1"
              d="M0,192L48,202.7C96,213,192,235,288,218.7C384,203,480,149,576,160C672,171,768,245,864,272C960,299,1056,277,1152,224C1248,171,1344,85,1392,42.7L1440,0L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
            ></path>
          </svg>
        </Gradient>
      </MainOnAcademicsHeaderLayout>
      <Footer top="1000px"/>
    </MainAcademicsLayout>
  );
}

export default MainAcademics;
