import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";
import { useState } from "react";
import { FaCheckSquare } from "react-icons/fa";
import { Link } from "react-router-dom";
import * as S from "./MainAbout.styles"
import FAQDATA from "./FAQ_DATA.json";
function FAQAccordion(props) {
  const filteredStr = FAQDATA.filter((val) =>
    val.Question.toLowerCase().includes(props.searchTerm.toLowerCase())
  );
  let filteredStrLength = filteredStr.length;
  return (
    <>
      <MDBAccordion>
        {filteredStrLength > "" ? (
          filteredStr.map((val, key) => (
            <MDBAccordionItem
              key={val.id}
              collapseId={val.CollapseId}
              headerTitle={val.Question}
            >
              <strong>{val.Answer}</strong>
              <p>
                {val.MoreInfo}{" "}
                <Link to={val.OptionalLinkPath}>{val.OptionalLink}</Link>{" "}
                {val.OptionalPEnd}
              </p>
            </MDBAccordionItem>
          ))
        ) : (
          <S.NoMatch/>
        )}
      </MDBAccordion>
    </>
  );
}

export default FAQAccordion;
