import Logo from "../Logo/Logo";
import Menu from "./Menu";
import {
  NavigationBarLayout,
  // BottomBarLayout,
  Form,
  Button,
  Input,
} from "./NavigationBar.style";
import { useState, useRef, createContext } from "react";
import { FaSearch } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";

function NavigationBar() {
  const [input, setInput] = useState("");
  const [barOpened, setBarOpened] = useState(false);
  const formRef = useRef();
  const inputFocus = useRef();
  const onFormSubmit = (e) => {
    // When form submited, clear input, close the searchbar and do something with input
    e.preventDefault();
    setInput("");
    setBarOpened(false);
    // After form submit, do what you want with the input value
    console.log(`Form was submited with input: ${input}`);
  };
  const [navBar, setNavBar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 85) {
      setNavBar(true);
    } else {
      setNavBar(false);
    }
  };
  window.addEventListener("scroll", changeBackground);
  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isEkScreen = useMediaQuery({
    query: "(min-width:908px)",
    query: "(max-width:1430px)",
  });
  const isTablet = useMediaQuery({
    query: "(max-width: 1224px)",
  });

  const isMobile = useMediaQuery({
    query: "(max-width: 786px)",
  });

  const isPortrait = useMediaQuery({
    query: "(orientation: portrait)",
  });

  const isRetina = useMediaQuery({
    query: "(max-resolution: 300dpi)",
  });
  return (
    <NavigationBarLayout isMobile={isMobile} scrolled={navBar}>
      <Logo isEkScreen={isEkScreen} isMobile={isMobile} scrolled={navBar} />
        <Menu isEkScreen={isEkScreen} scrolled={navBar} />
      {/* <Toggle /> */}
      {/* <BottomBarLayout /> */}
      {/* Start Search Button */}
      {/* <Form
        barOpened={barOpened}
        scrolled={navBar}
        onClick={() => {
          // When form clicked, set state of baropened to true and focus the input
          setBarOpened(true);
          inputFocus.current.focus();
        }}
        // on focus open search bar
        onFocus={() => {
          setBarOpened(true);
          inputFocus.current.focus();
        }}
        // on blur close search bar
        onBlur={() => {
          setBarOpened(false);
        }}
        // On submit, call the onFormSubmit function
        onSubmit={onFormSubmit}
        ref={formRef}
      > */}
        {/* <Button type="submit" barOpened={barOpened}>
          <FaSearch />
        </Button>
        <Input
          onChange={(e) => setInput(e.target.value)}
          ref={inputFocus}
          value={input}
          barOpened={barOpened}
          placeholder="Search..."
        />
      </Form> */}
      {/* End Search Button */}
    </NavigationBarLayout>
  );
}

export default NavigationBar;
