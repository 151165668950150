import {
  FAQLayout,
  FAQHeaderLayout,
  StyledFaSearch,
  Form,
  Button,
  Input,
  HowCan,
  BrowseHelp,
  Styledh2,
  QuestionSVG,
} from "./MainAbout.styles";
import { useMediaQuery } from "react-responsive";

import { useState, useRef } from "react";
import { FaSearch } from "react-icons/fa";
// import MDBArcodion from "./MDBArcodion";
import { Gradient } from "../../AppLayout.style";
import Footer from "../LandingPageComponents/Footer/Footer";
import { BackCardRe } from "../AdmissionPageComponents/MainAdmission.styles";
import FAQAccordion from "./FAQAccordion";

function FAQWrapper() {

  return <div> <FAQ /></div>;
}
export default FAQWrapper;

function FAQ() {
  const imagePath = "assets/img/approach-rhibms.PNG";
  const aboutedu = "assets/img/aboutedu.svg";
  const logo = "assets/img/logo.png";

  const [searchTerm, setSearchTerm] = useState("");
  const [barOpened, setBarOpened] = useState(false);
  const formRef = useRef();
  const inputFocus = useRef();

  const onFormSubmit = (e) => {
    // When form submited, clear input, close the searchbar and do something with input
    e.preventDefault();
    setSearchTerm("");
    setBarOpened(false);
    // After form submit, do what you want with the input value
    console.log(`Form was submited with input: ${searchTerm}`);
  };
  return (
    <FAQLayout>
      <QuestionSVG />
      {/* Start Search Button */}
      <HowCan>How Can We Help You?</HowCan>
      <Form
        barOpened={barOpened}
        onClick={() => {
          // When form clicked, set state of baropened to true and focus the input
          setBarOpened(true);
          inputFocus.current.focus();
        }}
        // on focus open search bar
        onFocus={() => {
          setBarOpened(true);
          inputFocus.current.focus();
        }}
        // on blur close search bar
        onBlur={() => {
          setBarOpened(false);
        }}
        // On submit, call the onFormSubmit function
        onSubmit={onFormSubmit}
        ref={formRef}
      >
        <Button type="submit" barOpened={barOpened}>
          <StyledFaSearch />
        </Button>
        <Input
          onChange={(event) => setSearchTerm(event.target.value)}
          ref={inputFocus}
          value={searchTerm}
          barOpened={barOpened}
          placeholder="What do you Want to Know"
        />
      </Form>
      {/* End Search Button */}
      <Styledh2>Browse Help Topics</Styledh2>
      <BrowseHelp
        padding="2%"
        marginTop="20%"
        marginBottom="2%"
        height="fit-content"
      >
        <FAQAccordion searchTerm={searchTerm} />
      </BrowseHelp>
      <Footer />
    </FAQLayout>
  );
}

function FAQMobile() {
  const imagePath = "assets/img/approach-rhibms.PNG";
  const aboutedu = "assets/img/aboutedu.svg";
  const logo = "assets/img/logo.png";

  const [searchTerm, setSearchTerm] = useState("");
  const [barOpened, setBarOpened] = useState(false);
  const formRef = useRef();
  const inputFocus = useRef();

  const onFormSubmit = (e) => {
    // When form submited, clear input, close the searchbar and do something with input
    e.preventDefault();
    setSearchTerm("");
    setBarOpened(false);
    // After form submit, do what you want with the input value
    console.log(`Form was submited with input: ${searchTerm}`);
  };
  return (
    <FAQLayout bg={aboutedu}>
      {/* <FAQHeaderLayout height="1%">

      </FAQHeaderLayout> */}
      {/* <FAQBarWithSearch 
          marginTop="5%"
          height="17%"
          bg={logo}>
       */}
      {/* Start Search Button */}
      <Form
        barOpened={barOpened}
        top="10%"
        right="13%"
        mobileX="18rem"
        onClick={() => {
          // When form clicked, set state of baropened to true and focus the input
          setBarOpened(true);
          inputFocus.current.focus();
        }}
        // on focus open search bar
        onFocus={() => {
          setBarOpened(true);
          inputFocus.current.focus();
        }}
        // on blur close search bar
        onBlur={() => {
          setBarOpened(false);
        }}
        // On submit, call the onFormSubmit function
        onSubmit={onFormSubmit}
        ref={formRef}
      >
        {/* <Button type="submit" barOpened={barOpened}>
          <FaSearch />
        </Button>
        <Input
          onChange={(event) => setSearchTerm(event.target.value)}
          ref={inputFocus}
          value={searchTerm}
          barOpened={barOpened}
          placeholder="Type Keywords to find answers"
        /> */}
      </Form>
      {/* End Search Button */}
      {/* </FAQBarWithSearch> */}
      <BackCardRe
        width="100%"
        height="fit-content"
        marginTop="30%"
        marginBottom="2%"
        padding="0"
        pT="12%"
      >
        <h2
          style={{
            textAlign: "center",
            fontWeight: 400,
            letterSpacing: 1 + "px",
          }}
        >
          Help Center
        </h2>
        <FAQAccordion searchTerm={searchTerm} />
      </BackCardRe>
      <Footer />
    </FAQLayout>
  );
}
