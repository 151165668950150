import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";
import {FaCheckSquare} from "react-icons/fa"
function MDBArcodion() {
  return (
    <MDBAccordion>
      <MDBAccordionItem
        collapseId="panelsStayOpen-collapse1"
        headerTitle="General"
      >
        <strong>
          This is for the General admission and has the following requirements:
        </strong>
        <ul><FaCheckSquare />Photocopy of Birth certificate</ul>
        <ul><FaCheckSquare />Photocopy of Advanced Level certificate</ul>
        <ul><FaCheckSquare />Photocopy of National Identity card</ul>
        <ul><FaCheckSquare />Two passport size photographs</ul>
        <ul><FaCheckSquare />Registration fee of 15,000frs</ul>
      </MDBAccordionItem>
      <MDBAccordionItem
        collapseId="panelsStayOpen-collapse2"
        headerTitle="Faculty of health"
      >
        <strong>Under the Faculty of Health</strong> 
        <p>In addition with the requirements for general</p>
        You need a pass in <u>at least</u> two Advanced Level subjects Arts/Science (excluding Religion)
      </MDBAccordionItem>
      <MDBAccordionItem
        collapseId="panelsStayOpen-collapse3"
        headerTitle="Midwifery"
      >
        <strong>Under the Faculty of Health</strong> 
        <p>In addition with the requirements for general</p>
        You need a pass in <u>at least</u> two Advanced Level subjects (including Biology)
      </MDBAccordionItem>
      <MDBAccordionItem
        collapseId="panelsStayOpen-collapse4"
        headerTitle="Lab Sciences"
      >
        <strong>Under Lab Sciences</strong> 
        <p>In addition with the requirements for general</p>
        You need a pass in <u>at least</u> two Advanced Level subjects (excluding Religion)
      </MDBAccordionItem>
      <MDBAccordionItem
        collapseId="panelsStayOpen-collapse5"
        headerTitle="Faculty of Social Science"
      >
        <strong>Under the Faculty of Social Science</strong> 
        <p>In addition with the requirements for general</p>
        You need a pass in <u>at least</u> two Advanced Level subjects (excluding Religion)
      </MDBAccordionItem>
      <MDBAccordionItem
        collapseId="panelsStayOpen-collapse6"
        headerTitle="BSc(Degree)"
      >
        <strong>Under the Faculty of Social Science</strong> 
        <p>In addition with the requirements for general</p>
       
        You need a pass in HND <u>at least</u> a Lower credit
      </MDBAccordionItem>
    </MDBAccordion>
  );
}

export default MDBArcodion;
