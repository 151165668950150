import styled from 'styled-components';

export const MainOnCampusLayout = styled.div`
    font-family: Montserrat;
    color: 'black';
    height: 180vh;
    background-image: url(${props => props.bg});
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-color: #F5F5F5;
    `;
export const MainOnCampusHeaderLayout = styled.div`
    height: 65vh;
    height:${props => props.height};
    margin-left: 0;
    background-image: url(${props => props.bg});
    background-position: center;
    background-size:cover;

`;