import { MDBBtn } from "mdb-react-ui-kit";
import { useState, createContext } from "react";

import "animate.css";
import { MdSchool, MdArrowDropDown } from "react-icons/md";
import * as S from "./MainAdmission.styles";
import * as E from "./Elements/Elements.style";
import HeadTextElement from "./Elements/HeadTextElement";
import Modal from "./Elements/Modal";
import MDBArcodion from "./MDBArcodion";
import * as Res from "./AdmissionsRes";
import { Gradient } from "../../AppLayout.style";
import Footer from "../LandingPageComponents/Footer/Footer";

function MainAdmissionWrapper(props) {
  
  return <MainAdmission/>;
}
export default MainAdmissionWrapper;
function MainAdmission(props) {

  const RenderModal = (admission) => {
    const [show, setShow] = useState(false);
    return (
      <S.BackCardGuIn
        bR="16px"
        bg="#f8f9fa"
        bS="0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%)"
        style={{ textAlign: "center" }}
        height="32rem"
        width="calc(100% - 190px)"
        padding="0"
        margin="2%"
      >
        <S.BackCardGuInCir
          border="none"
          // bR="20px"
          // bBL="0"
          // bBR="0"
          bPos="center"
          bg={admission.programImg}
          bSize="cover"
          position="relative"
          top="30px"
          width="9rem"
          height="9rem"
        />
        <p
          style={{
            textAlign: "center",
            fontSize: "20px",
            lineHeight: "28px",
            fontWeight: 600,
            marginTop: "35px",
          }}
        >
          {admission.programType}
        </p>
        <p
          style={{
            fontWeight: 500,
            textAlign: "center",
            minHeight: "40px",
          }}
        >
          Accelerate your growth in the medical domain with the fully managed
          RHIBMS school which stands at {admission.programFee}
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <S.ApplyForThis
            onClick={() => {
              setProgramInstance(admission);
              setShow(true);
            }}
          >
            View All <S.Edu>{admission.programType}</S.Edu>
          </S.ApplyForThis>
          <Modal
            onClose={() => setShow(false)}
            show={show}
            img={admission.programImg}
            tuition={admission.programFee}
            title={admission.programType}
            subPrograms={admission.subPrograms}
          />
          <ul
            style={{
              padding: "0",
              paddingLeft: "30px",
              fontSize: "15px",
            }}
          >
            <li
              style={{
                padding: "2%",
                paddingTop: "7%",
                width: "20rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
              role="list item"
            >
              <MdSchool
                style={{
                  padding: "1%",
                }}
                size={30}
              />
              <>{admission.programType.slice(0, 3)} in Nursing, 3 years</>
            </li>
            <li
              style={{
                padding: "2%",
                width: "20rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
              role="list item"
            >
              <MdSchool
                style={{
                  padding: "1%",
                }}
                size={30}
              />{" "}
              <>{admission.programType.slice(0, 3)} in Midwifery, 3 years</>
            </li>
          </ul>
        </div>
      </S.BackCardGuIn>
    );
  };
  const imagePath = "https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTl8fGVkdWNhdGlvbiUyMGJsYWNrfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=400&q=60";
  const aboutedu = "assets/img/aboutedu.svg";
  const [expandable, setExpandable] = useState(false);
  const handleExpand = (e) => {
    if (expandable) {
      setExpandable(false);
    } else {
      setExpandable(true);
    }
  };
  

  const [programInstance, setProgramInstance] = useState(null);
 
  return (
    <S.MainOnAdmissionsLayout bg={aboutedu}>
      <S.MainOnAdmissionsHeaderLayout bg={imagePath}>
        <Gradient>
          <E.HeadTextElement>
            <HeadTextElement spanMargin="0 auto" title="Admissions" />
          </E.HeadTextElement>
        </Gradient>
      </S.MainOnAdmissionsHeaderLayout>

      <S.BackCardRe marginTop="-10%" padding="80px 90px" textAlign="center" height="fit-content">
        <S.cardHeader>Admission Requirements</S.cardHeader>

        <p style={{ textAlign: "center" }}>
          Since we started taking the HND examinations 10years ago, we have
          unbeatable records in the Southwest region with the outstanding
          results we have produced with distinction and upper credits for 10
          years conservatively.
        </p>
        <MDBArcodion />
        <MDBBtn
          style={{
            marginTop: "4%",
            width: "calc(100%)",
            fontWeight: "bold",
            height: 80 + "%",
          }}
          color="success"
        >
          Apply Now!
        </MDBBtn>
      </S.BackCardRe>
      <S.BackCardGu  padding="2%"
        style={{
          paddingBottom: "50px",
          overflow:"hidden",
        }}
      >
        <S.cardHeader>Admission Guidelines</S.cardHeader>

        <p style={{ textAlign: "center" }}>
          Many believe that most professional schools are quite expensive. The
          Redemption Higher Institute of Biomedical and Management Science has
          made its tuition fee affordable with a registration fee of just
          15,000FCFA
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontWeight: 600,
          }}
        >
          {Res.AdmissionsRes.slice(0, 3).map(RenderModal)}
        </div>
        <S.ConditionalAll display={expandable ? "flex" : "none"}>
          <hr style={{ textAlign: "center" }} />
          <S.ConditionalBlock>
            {/* HND BIOMEDICAL PROGRAMS LIST */}
            <ul
              style={{
                padding: "0",
                paddingLeft: "30px",
                fontSize: "15px",
              }}
            >
              <h4>Biomedical Programs</h4>
              {Res.HNDBiomedical.map((HND, index) => (
                <li
                  style={{
                    padding: "2%",
                    paddingTop: "7%",
                    width: "20rem",
                    display: "flex",
                    alignItems: "center",
                    textTransform: "capitalize",
                  }}
                  role="list item"
                >
                  <MdSchool
                    style={{
                      padding: "1%",
                    }}
                    size={30}
                  />{" "}
                  <>{HND}</>
                </li>
              ))}
            </ul>
            {/* HND HOTEL MANAGEMENT AND TOURISM LIST */}
            <ul
              style={{
                padding: "0",
                paddingLeft: "30px",
                fontSize: "15px",
                textTransform: "capitalize",
              }}
            >
              <h4>Programs for Home Economics, Tourism and Hotel Management</h4>
              {Res.HNDHomeEcons.map((HND, index) => (
                <li
                  style={{
                    padding: "2%",
                    paddingTop: "7%",
                    width: "20rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                  role="list item"
                >
                  <MdSchool
                    style={{
                      padding: "1%",
                    }}
                    size={30}
                  />{" "}
                  <>{HND}</>
                </li>
              ))}
            </ul>
            {/*  SCHOOL OF ARTS, CULTURE AND EDUCATION */}
            <ul
              style={{
                padding: "0",
                paddingLeft: "30px",
                fontSize: "15px",
                textTransform: "capitalize",
              }}
            >
              <h4>Programs For School Of Arts, Culture And Education</h4>
              {Res.HNDArts.map((HND, index) => (
                <li
                  style={{
                    padding: "2%",
                    paddingTop: "7%",
                    width: "20rem",
                    display: "flex",
                    alignItems: "center",
                    textTransform: "capitalize",
                  }}
                  role="list item"
                >
                  <MdSchool
                    style={{
                      padding: "1%",
                    }}
                    size={30}
                  />{" "}
                  <>{HND}</>
                </li>
              ))}
            </ul>
          </S.ConditionalBlock>
          <hr style={{ textAlign: "center" }} />

          <S.ConditionalBlock>
            {/* Masters Programs */}
            <ul
              style={{
                padding: "0",
                paddingLeft: "30px",
                fontSize: "15px",
                textTransform: "capitalize",
              }}
            >
              <h4>Masters Programs</h4>
              {Res.Masters.map((HND, index) => (
                <li
                  style={{
                    padding: "2%",
                    paddingTop: "7%",
                    width: "20rem",
                    display: "flex",
                    alignItems: "center",
                    textTransform: "uppercase",
                  }}
                  role="list item"
                >
                  <MdSchool
                    style={{
                      padding: "1%",
                    }}
                    size={30}
                  />{" "}
                  <>{HND}</>
                </li>
              ))}
            </ul>
            {/* Bachelors PROGRAMS LIST */}
            <ul
              style={{
                padding: "0",
                paddingLeft: "30px",
                fontSize: "15px",
              }}
            >
              <h4>Bachelors Programs</h4>
              {Res.Bachelors.map((HND, index) => (
                <li
                  style={{
                    padding: "2%",
                    paddingTop: "7%",
                    width: "20rem",
                    display: "flex",
                    alignItems: "center",
                    textTransform: "capitalize",
                  }}
                  role="list item"
                >
                  <MdSchool
                    style={{
                      padding: "1%",
                    }}
                    size={30}
                  />{" "}
                  <>{HND}</>
                </li>
              ))}
            </ul>

            {/*  Public Health Programs */}
            <ul
              style={{
                padding: "0",
                paddingLeft: "30px",
                fontSize: "15px",
                textTransform: "capitalize",
              }}
            >
              <h4>Public Health Programs</h4>
              {Res.PublicHealth.map((HND, index) => (
                <li
                  style={{
                    padding: "2%",
                    paddingTop: "7%",
                    width: "20rem",
                    display: "flex",
                    alignItems: "center",
                    textTransform: "capitalize",
                  }}
                  role="list item"
                >
                  <MdSchool
                    style={{
                      padding: "1%",
                    }}
                    size={30}
                  />{" "}
                  <>{HND}</>
                </li>
              ))}
            </ul>
          </S.ConditionalBlock>
        </S.ConditionalAll>
        <S.Expandable>
          <S.ExpandableBtn onClick={handleExpand}>
            <S.Span>
              {expandable ? "View Less" : "View All Admission Programs"}
            </S.Span>{" "}
            <MdArrowDropDown size={30} />
          </S.ExpandableBtn>
        </S.Expandable>
      </S.BackCardGu>
      <Footer />
    </S.MainOnAdmissionsLayout>
  );
}

function MainAdmissionMobile(props) {
  const imagePath = "assets/img/approach-rhibms.PNG";
  const aboutedu = "assets/img/aboutedu.svg";
  return (
    <S.MainOnAdmissionsLayout bg={aboutedu}>
      <S.MainOnAdmissionsHeaderLayout height="20vh" bg={imagePath}>
        <Gradient>
         
          <HeadTextElement fS="1rem" paddingTop="19%" title="Admissions" />
        </Gradient>
      </S.MainOnAdmissionsHeaderLayout>
      {/* ADMISSION REQUIREMENTS */}
      <S.BackCardRe
        pT="3%"
        padding="1%"
        width="calc(100% - 20px)"
        height="fit-content"
      >
        <h2
          style={{
            textAlign: "center",
            fontWeight: 500,
          }}
        >
          Admission Requirements
        </h2>
        <p style={{ textAlign: "center" }}>
          Students can apply online via the Online Application Portal or can
          apply on Campus. You could also mail us at info@rhibms.com
        </p>
        <MDBArcodion />
        <MDBBtn
          style={{
            margin: "4%",
            width: "calc(100% - 20px)",
            fontWeight: "bold",
            height: 80 + "%",
          }}
          color="success"
        >
          Apply Now!
        </MDBBtn>
      </S.BackCardRe>

      {/* ADMISSION GUIDELINES */}
      <S.BackCardGu
        pT="3%"
        padding="1%"
        width="calc(100% - 20px)"
        height="fit-content"
      >
        <h2
          style={{
            textAlign: "center",
            fontWeight: 500,
          }}
        >
          Admission Guidelines
        </h2>
        <p style={{ textAlign: "center" }}>
          Many believe that most professional schools are quite expensive. The
          Redemption Higher Institute of Biomedical and Management Science has
          made its tuition fee affordable with a registration fee of just
          15,000FCFA
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontWeight: 600,
          }}
        >
          <hr />
          {Res.AdmissionsRes.map((admission, index) => (
            <S.BackCardGuIn
              style={{ textAlign: "center" }}
              height="35vh"
              width="calc(100% - 20px)"
              padding="0"
            >
              {/* Picture in Card */}
              <S.BackCardGuInCir
                border="none"
                bR="20px"
                bBL="0"
                bBR="0"
                bPos="top"
                bg={admission.programImg}
                position="inherit"
                top="0"
                width="100%"
                height="50%"
                left="2.4pc"
              />
              <p
                style={{
                  textAlign: "center",
                  fontSize: "15px",
                  lineHeight: "28px",
                  fontWeight: 500,
                }}
              >
                {admission.programType}
              </p>
              <p
                style={{
                  fontSize: "14px",
                  lineHeight: "28px",
                }}
              >
                Fee stands at {admission.programFee}
              </p>

              <p
                style={{
                  textAlign: "center",
                  marginTop: 0,
                  color: "green",
                  fontWeight: 600,
                }}
              >
                See More
              </p>
              <hr
                style={{
                  textAlign: "center",
                  margin: "0 auto",
                  marginTop: "-47.8px",
                  width: "calc(100% - 20px)",

                  color: "gainsboro",
                  fontWeight: 600,
                }}
              />
            </S.BackCardGuIn>
          ))}
        </div>
      </S.BackCardGu>
      <Footer />
    </S.MainOnAdmissionsLayout>
  );
}
