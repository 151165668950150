import * as E from "./Elements.styles";
import { Link } from "react-router-dom";
import AllEvents_DATA from "./AllEvents_DATA.json";
import { Gradient } from "../../../AppLayout.style";
import { useState, createContext } from "react";
import Modal from "./Modal";
export function EventsHeading(props) {
  return (
    <E.EventsHeadingLayout>
      <E.Heading>Events</E.Heading>
      <E.SubHeading>
        Events and important dates from across campus.
      </E.SubHeading>
    </E.EventsHeadingLayout>
  );
}
const EachEvent = (event) => {
  const [show, setShow] = useState(false);
  return (
    <E.EventCardVoidPic
      onClick={() => {
        setShow(true);
      }}
      key={event.id}
      height="20vh"
      width="80%"
    >
      {/* <E.ImageForCardLayout bg={props.imgInside} /> */}
      <E.Time> {event.time}</E.Time>
      {/* <E.Duration>
{event.duration}
</E.Duration> */}
      <E.End>{event.end}</E.End>
      <E.Title> {event.title}</E.Title>
      <E.Location>{event.location}</E.Location>
      <Modal
        onClose={() => setShow(false)}
        show={show}
        time={event.time}
        end={event.end}
        title={event.title}
        location={event.location}
      />
    </E.EventCardVoidPic>
  );
}
export function EventCardVoidPic(props) {
  const filteredStr = AllEvents_DATA.filter((val) =>
    val.title.toLowerCase().includes(props.searchTerm.toLowerCase())
  );
  let filteredStrLength = filteredStr.length;
  
  return (
    <>
      {filteredStrLength > "" ? (
        filteredStr.map(EachEvent)
      ) : (
        <E.NoEvent src="assets/img/NoEvent.png" alt="No Event Found" />
      )}
    </>
  );
}

export function OtherCard(props) {
  return (
    <E.OtherLayout height={props.height} width={props.width}>
      <E.OtherLayoutPic bg={props.imgInside} />
      <E.Category>University Affairs</E.Category>
      <E.Headline fS2={props.fS}>
        Doing HIV Screening in Buea, and sensitizing the public
      </E.Headline>
    </E.OtherLayout>
  );
}

export function ShortEventsCard(props) {
  return (
    <article className="card large featured" id="top-story-3">
      <div className="card-photo ">
        <Link
          to="https://news.stanford.edu/2022/04/06/veronica-roberts-direct-cantor-arts-center-stanford/"
          tabindex="-1"
          aria-hidden="true"
          role="presentation"
          data-ga-action="position-3"
        >
          <img
            src="https://news.stanford.edu/wp-content/uploads/2022/04/1500x1000_Cantor-announcement-22.04.04-VR-by-Manny-Alcala-345x230.jpg"
            className="hidden-xs wp-post-image"
            alt=""
            srcset="https://news.stanford.edu/wp-content/uploads/2022/04/1500x1000_Cantor-announcement-22.04.04-VR-by-Manny-Alcala-345x230.jpg 345w, https://news.stanford.edu/wp-content/uploads/2022/04/1500x1000_Cantor-announcement-22.04.04-VR-by-Manny-Alcala-555x370.jpg 555w, https://news.stanford.edu/wp-content/uploads/2022/04/1500x1000_Cantor-announcement-22.04.04-VR-by-Manny-Alcala-795x530.jpg 795w, https://news.stanford.edu/wp-content/uploads/2022/04/1500x1000_Cantor-announcement-22.04.04-VR-by-Manny-Alcala-960x640.jpg 960w, https://news.stanford.edu/wp-content/uploads/2022/04/1500x1000_Cantor-announcement-22.04.04-VR-by-Manny-Alcala-705x470.jpg 705w, https://news.stanford.edu/wp-content/uploads/2022/04/1500x1000_Cantor-announcement-22.04.04-VR-by-Manny-Alcala-375x250.jpg 375w, https://news.stanford.edu/wp-content/uploads/2022/04/1500x1000_Cantor-announcement-22.04.04-VR-by-Manny-Alcala.jpg 1500w, https://news.stanford.edu/wp-content/uploads/2022/04/1500x1000_Cantor-announcement-22.04.04-VR-by-Manny-Alcala-345x230@2x.jpg 690w, https://news.stanford.edu/wp-content/uploads/2022/04/1500x1000_Cantor-announcement-22.04.04-VR-by-Manny-Alcala-555x370@2x.jpg 1110w, https://news.stanford.edu/wp-content/uploads/2022/04/1500x1000_Cantor-announcement-22.04.04-VR-by-Manny-Alcala-705x470@2x.jpg 1410w, https://news.stanford.edu/wp-content/uploads/2022/04/1500x1000_Cantor-announcement-22.04.04-VR-by-Manny-Alcala-375x250@2x.jpg 750w"
            sizes="(max-width: 345px) 100vw, 345px"
          />
        </Link>
      </div>{" "}
      <div
        className="card-content"
        style={{ padding: "4%", marginTop: "8%", height: "20.344%" }}
      >
        <div className="meta">
          <Link
            style={{
              fontSize: "0.825em",
              color: "green",
              padding: "4%",
              textTransform: "uppercase",
            }}
            to="https://news.stanford.edu/section/arts-creativity/"
          >
            Arts &amp; Creativity
          </Link>
        </div>
        <h3>
          <Link
            style={{ fontSize: "1.1875em;" }}
            to="https://news.stanford.edu/2022/04/06/veronica-roberts-direct-cantor-arts-center-stanford/"
            data-ga-action="position-3"
          >
            Veronica Roberts to direct the Cantor Arts Center at Stanford
          </Link>
        </h3>
        <p className="teaser">
          The curator of modern and contemporary art at the Blanton Museum of
          Art at The University of Texas at Austin will bring her vision for the
          Cantor to campus on July 5.
        </p>
      </div>{" "}
    </article>
  );
}
export function FeaturedEvents(props) {
  const { FeaturedEvents } = props;

  return (
    <E.FeaturedEventsLayout
      padding={props.padding}
      height={props.height}
      width={props.width}
      paddingTop={props.paddingTop}
      marginTop={props.marginTop}
      bgColor={props.bgColor}
    >
      <E.FeaturedEventsPic width={props.width} bg={FeaturedEvents[0].image}>
        <Gradient
          bR="0 0 6px 6px"
          bg="180deg,
          #e8efe819 0%,
          #c9d2c91a 25%,
    #63736319 50%,
    #0c110c1d 75%,
    #071607d1 100%"
          height="100%"
        >
          <E.FeaturedEventsCategory
            CategoryPaddingTop={props.CategoryPaddingTop}
          >
            {FeaturedEvents[0].category}
          </E.FeaturedEventsCategory>
          <E.FeaturedEventsHeadline
            to="/TheEvent"
            fS1={props.fS1}
            fS2={props.fS2}
          >
            <Link
              style={{
                fontSize: "inherit",
                color: "inherit",
                textDecoration: "inherit",
              }}
              to="/TheEvent"
            >
              <u style={{ textAlign: "center" }}>
                {FeaturedEvents[0].headline}
              </u>
            </Link>
          </E.FeaturedEventsHeadline>
        </Gradient>
      </E.FeaturedEventsPic>
    </E.FeaturedEventsLayout>
  );
}

export function AllEvents(props) {
  return (
    <E.AllEventsLayout
      padding={props.AllEventsLayoutPadding}
      flexD={props.flexD}
    >
      {AllEvents_DATA.slice(0, 4).map((val, key) => (
        <E.EachEventContainer
          width={props.NewsContainerWidth}
          margin={props.NewsContainerMargin}
        >
          <E.EachEvent bg={val.image} />
          <E.Category>{val.category}</E.Category>
          <Link to="/">
            <E.Headline fS2={props.fS}>{val.headline}</E.Headline>
          </Link>
        </E.EachEventContainer>
      ))}
    </E.AllEventsLayout>
  );
}

export function RecentHighlights(props) {
  return (
    <E.AllHighlights padding={props.RecentHighlightsContainerPadding}>
      <E.RecentHighlightsText>Recent Highlights</E.RecentHighlightsText>
      <E.RecentHighlightsLayout flexD={props.flexD}>
        {AllEvents_DATA.slice(5, 9).map((val, key) => (
          <E.RecentHighlightsContainer
            mobileML={props.mobileRecentHighlightsContainerML}
            key={key.id}
            width={props.RecentHighlightsContainerWidth}
            margin={props.RecentHighlightsContaineMargin}
          >
            <E.EachHighlight bg={val.image} />
            <E.Category>{val.category}</E.Category>
            <Link to="/TheEvent">
              <E.Headline fS2={props.fS}>{val.headline}</E.Headline>
            </Link>
          </E.RecentHighlightsContainer>
        ))}
      </E.RecentHighlightsLayout>
    </E.AllHighlights>
  );
}
