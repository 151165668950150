import { useMediaQuery } from "react-responsive";
import Media from "react-media"
import AppLaptopL from "./Parts/LargeLaptopVersion/AppLaptopL";
import AppLaptopS from "./Parts/SmallLaptopVersion/AppLaptopS";
import AppMonitorL from "./Parts/LargeMonitorVersion/AppMonitorL";
import AppTablet from "./Parts/TabletVersion/AppTablet";
import AppMobile from "./Parts/MobileVersion/AppMobile";
function App() {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isSmallLaptop = useMediaQuery({ query: "(min-width: 1040px)" },{ query: "(max-width: 1224px)" });
  const isLargeLaptop = useMediaQuery({ query: "(min-width: 1440px)" });
  const isLargeMonitor = useMediaQuery({ query: "(min-width: 1920px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 769px)" }&&{
    query: "(max-width: 1040px)",
  });

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  return (
    // What if Its nested media
    // What if I say minwidth is tablet size, else display mobile?

    <Media query="(min-width:1920px)">
      {(matches) => {
        return matches ? <AppMonitorL /> : <DecideZero />
      }}
    </Media>
  );
}

export default App;

const DecideZero = () =>{
  return (
    <Media query="(min-width:1224px)">
      {(matches) => {
        return matches ? <DecideTwo /> : <DecideOne />
      }}
  </Media>
  )
}
const DecideOne = () =>{
  return (
    <Media query="(min-width:999px)">
    {(matches) => {
      return matches ? <AppTablet /> : <AppMobile />
    }}
  </Media>
  )
}
const DecideTwo = () =>{
  return (
    <Media query="(min-width:1440px)">
    {(matches) => {
      return matches ? <AppLaptopL /> : <AppLaptopS />
    }}
    {/* {isLargeMonitor && <AppMonitorL />} */}
  </Media>
  )
}