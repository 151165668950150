import styled, { createGlobalStyle } from "styled-components";

const Reset = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
  }
`;
const laptopSizesWidth = {
  _11InchMacbookAir: "768px",
  _13InchMacbookAir: "900px",
  _13InchMacbookPro: "800px",
  _21InchiMac: "1080px",
  _27InchiMac: "1440px",
};
const laptopSizesHeight = {
  _11InchMacbookAir: "1366px",
  _13InchMacbookAir: "1440px",
  _13InchMacbookPro: "1280px",
  _21InchiMac: "1920px",
  _27InchiMac: "2560px",
};
export const laptopHeights = {
  _11InchMacbookAir: `(max-height: ${laptopSizesHeight._11InchMacbookAir})`,
  _13InchMacbookAir: `(max-height: ${laptopSizesHeight._13InchMacbookAir})`,
  _13InchMacbookPro: `(max-height: ${laptopSizesHeight._13InchMacbookPro})`,
  _21InchiMac: `(max-height: ${laptopSizesHeight._21InchiMac})`,
  _27InchiMac: `(max-height: ${laptopSizesHeight._27InchiMac})`,
};
const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

export const AppLayout = styled.div`
  background: green;

  position: absolute;
  z-index: -1;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  font-family: Montserrat;
`;

export const Gradient = styled.div`
  border-radius: ${(props) => props.bR};
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  background: linear-gradient(${(props) => props.bg});
  margin: 0 auto;
  margin-top: ${(props) => props.marginTop};
  /* overflow: hidden; */
  width: 100%;
  height: 100vh;
  /* transform: translate(0%, -50%);  */
  /* overflow: hidden; */
  height: ${(props) => props.height};
  width: ${(props) => props.width};
`;

export const EachImg = styled.img`
  position: absolute;
  top: 50;
  height: 100vh;
  width: 100vw;
  font-family: Montserrat;
  object-fit: cover;
`;
export const SlideContainer = styled(AppLayout)`
  /* margin: 0 auto; */

  height: fit-content;
  width: 100vw;
`;

export const MiniatureAbout = styled.div`
  height: fit-content;
  width: 100%;

  /* overflow: hidden; */
  margin: 0 auto;
  @media ${device.laptopL} {
  }
`;

export const OtherSideAbout = styled.div`
  height: fit-content;
  height: ${(props) => props.height};
  overflow: hidden;
  /* border-radius: 10px; */
  padding: 11.5%;
  padding: ${(props) => props.padding};
  width: fit-content;
  background-color: #fafafa;
  @media ${device.laptopL} {
    /* overflow: hidden; */
  }
  &:hover {
  }
`;

export const WhyUsLayout = styled.div`
  height: fit-content;
  width: 100%;
  /* overflow: hidden; */

  @media ${device.laptopL} {
  }
`;
export const WorkFlowLayout = styled.div`
  height: fit-content;
  width: 100%;
  margin-top: 2%;
  /* overflow: hidden; */
  background: whitesmoke;
  padding-bottom: 4%;

  @media ${device.laptopL} {
    /* overflow: hidden; */
  }
`;
