import styled from "styled-components";
import { MDBContainer } from "mdb-react-ui-kit";

import { device } from "../../../AppLayout.style";
export const LandingMnVLayout = styled.div`
  background-color: rgba(2, 99, 66, 0.1);
  height: 100%;
  padding-left: 8%;
  padding-right: 8%;
  padding-bottom: 4%;
  padding-left: ${props => props.mobilePL};
  padding-right: ${props => props.mobilePR};
`;
export const AboutHead = styled.h2`
  text-align: center;
  text-transform: uppercase;
  padding: 0;
  color: #068d14;
  padding-top: 4%;
  font-size: 1.4em;
  font-size: ${props => props.mobileFS};
`;
export const RhibmsOneStatement = styled.h3`
  text-align: center;
  width: 80%;
  width: ${props => props.mobileWidth};
  font-size: 1.9em;
  font-size: ${props => props.mobileFS};
  font-weight: bolder;
  margin: 0 auto;
  text-transform: capitalize;
`;
export const RhibmsExplain = styled.p`
  text-align: center;
  width: 70%;
  width: ${props => props.mobileWidth};
  padding-top: 1%;
  /* font-size:1.9em; */
  font-weight: 500;
  margin: 0 auto;
  text-transform: capitalize;
`;
export const LandingMnVMobileLayout = styled(LandingMnVLayout)`
  margin-top: 0;
  margin-bottom: 4%;
  padding: 1%;
  background: whitesmoke;
`;
export const LandingMnVMobileHead1 = styled.h2`
  color: #265854;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;
  font-size: 0.8125rem;
  font-weight: 700;
  vertical-align: baseline;
`;

export const LandingMnVMobileHead2 = styled.h2`
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-transform: capitalize;
  text-align: center;
  letter-spacing: 2px;
  font-size: 1.8125rem;
  font-weight: 700;
  vertical-align: baseline;
`;
