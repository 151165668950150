import styled from "styled-components";
import { FaQuoteLeft } from "react-icons/fa";
import { Carousel } from "react-responsive-carousel";

export const Testimonials = styled.div`
  background-color: whitesmoke;
  background-color: ${(props) => props.bgColor};
  height: 68vh;
  height: ${(props) => props.height};
  margin-top: 2%;
  padding-top: 2%;
  position: relative;
`;
export const TestimonialsHead = styled.h2`
  padding-top:3%;
  padding-bottom:1%;
  border-radius: 6px;
  text-align: center;
  /* background-color: ghostwhite; */
  text-align: center;
  /* color: #265854; */
`;
export const TestimonialsCarousel = styled(Carousel)`
  height: 40vh;
  margin: ${(props) => props.margin};
  margin-top: ${(props) => props.marginTop};
  position: ${(props) => props.position};
  width: ${(props) => props.width};
  z-index: ${(props) => props.zIndex};
  /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
  
  filter: blur(${(props) => props.blur});
`;
export const TestimonialsCard = styled.div`
  background-color: ${(props) => props.bgColor};
  border-radius: 6px;
  color: ${(props) => props.color};
  height: 40vh;
  margin: ${(props) => props.margin};
  overflow-y: visible;
  margin-right: ${(props) => props.marginRight};
  margin-left: ${(props) => props.marginLeft};
  padding: 2%;
  position: ${(props) => props.position};
  text-align: center;
  top: ${(props) => props.top};
  width: 40vw;
  width: ${(props) => props.width};
  z-index: ${(props) => props.zIndex};
  
`;
export const TestimonialsQuoteLeft = styled(FaQuoteLeft)`
  padding: 2%;
`;
export const TestimonialsContent = styled.p`
  color: ${(props) => props.color};
`;
export const TestimonialsName = styled.h5`
  color: ${(props) => props.color};
  padding-bottom: -5%;
`;

export const TestimonialsNamePost = styled.h6`
  color: ${(props) => props.color};
  padding-bottom: -5%;
`;
export const TestimonialsImage = styled.div`
  position: relative;
  z-index: 10;
  background-image: url(${(props) => props.imgPath});
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  box-shadow: 2px 2px 1px #000;
  -moz-box-shadow: 2px 2px 1px #000;
  -webkit-box-shadow: 2px 2px 1px #000;
  height: 100px;
  height: ${(props) => props.width};
  margin: 0 auto;
  padding-top: 1%;

  margin-right: ${(props) => props.marginRight};
  margin-left: ${(props) => props.marginLeft};
  margin-top: ${(props) => props.marginTop};
  object-fit: cover;
  width: 100px;
  width: ${(props) => props.width};
`;

export const DotsSpanParent = styled.div`
  display: flex;
  position: absolute;
  margin: 0 auto;
  text-align: center;
  top: 90%;
  /* top: 530px; */
`;
export const DotsSpan = styled.span`
  height: 20px;
  width: 20px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  cursor: pointer;
  align-items: center;

  &::before {
    content: "";
    width: 6px;
    height: 6px;
    background-color: brown;
  }
`;
