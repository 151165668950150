import { useState, useEffect, useContext } from "react";
import "animate.css";
import * as E from "./Elements.styles";
import * as S from "./../../AdmissionPageComponents/MainAdmission.styles";

function Modal(props) {
  //   useEffect(() => {
  //     window.addEventListener("keydown", closeOnEscapeKeyDown);
  //     return function cleanup() {
  //       window.removeEventListener("keydown", closeOnEscapeKeyDown);
  //     };
  //   }, []);
  // const [programType, setProgramType] = useState("something");
  if (!props.show) {
    return null;
  }
  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose();
    }
  };

  return (
    <E.Modal
      // onLoad={() => setProgramType(`${props.title}`)}
      onClick={() => {
        // alert(admission.programType)
        props.onClose();
      }}
    >
      <E.ModalContent
        className="animate__animated animate__bounceInUp"
        onClick={(e) => e.stopPropagation()}
      >
        <E.ModalSideBar>
          <E.ModalContact>Contact Information</E.ModalContact>
          <hr />
          <span>admin@rhibms.com</span>
        </E.ModalSideBar>
        <E.ModalBody>
          <E.ModalTitle>{props.title}</E.ModalTitle>
          <E.TimeModal>Time:</E.TimeModal>
          <E.ModalTime>{props.time}</E.ModalTime>
          <br />
          <E.TimeModal>Cost:</E.TimeModal>
          <E.Cost>Free</E.Cost>
          <br />
          <E.TimeModal>Location:</E.TimeModal>
          <E.ModalLocation>{props.location}</E.ModalLocation>
          <br />
          <p>
            A large-scale installation of portraits by photographer Mary Beth
            Meehan on the Watson Institute (111 Thayer Street) and in Stephen
            Robert ’62 Hall (Tarred Malingo Street). Installation on view October 13,
            2021 through May 31, 2023.
          </p>
          <p>
            Even Though RHIBMS is is relatively young, A large-scale installation of portraits by photographer Mary Beth
            Meehan on the Watson Institute (111 Thayer Street) and in Stephen
            Robert ’62 Hall (Malingo Street). Installation on view October 13,
            2021 through May 31, 2023.
            Even THough RHIBMS is is relatively young, A large-scale installation of portraits by photographer Mary Beth
            Meehan on the Watson Institute (111 Thayer Street) and in Stephen
            Robert ’62 Hall (Malingo Street). Installation on view October 13,
            2021 through May 31, 2023.
            Even THough RHIBMS is is relatively young, A large-scale installation of portraits by photographer Mary Beth
            Meehan on the Watson Institute (111 Thayer Street) and in Stephen
            Robert ’62 Hall (Malingo Street). Installation on view October 13,
            2021 through May 31, 2023.
          </p>
        </E.ModalBody>
        <E.ModalFooter>
          <E.ModalBtn title="Close" onClick={props.onClose} />
        </E.ModalFooter>
      </E.ModalContent>
    </E.Modal>
  );
}

export default Modal;
