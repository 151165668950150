import { FooterOverlay } from "../FooterArea/FooterArea.styled";
import FooterWrapper from "../FooterArea/FooterArea.jsx";

function Footer(props) {
  const imagePath = "assets/img/requirementsPics/_DSC0043.JPG";

  return (
    // {/* Footer Down Here */}

    <div className="FooterWrapper">
      <FooterWrapper />
    </div>
  );
}

export default Footer;
