import styled from "styled-components";
import { device } from "../../../AppLayout.style";

export const NavigationBarLayout = styled.div`
  font-family: Montserrat;
  position: -webkit-fixed;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  z-index: 2;
  /* overflow:scroll; */
  /* padding-top: ${(props) => (props.isMobile ? "4%" : "")}; */
  /* padding-left:0; */
  height: 85px;
  height: ${(props) => (props.isMobile ? "auto" : "")};
  background: ${(props) => (props.scrolled ? "white" : "#304454")};
  box-shadow: ${(props) =>
    props.scrolled
      ? "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px"
      : "0 1px 2px -1px #304454"};
  width: 100%;
`;
export const ActiveNavigationBarLayout = styled(NavigationBarLayout)``;


export const Input = styled.input`
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  line-height: 1;
  background-color: transparent;
  width: 100%;
  margin-left: ${(props) => (props.barOpened ? "1rem" : "0rem")};
  border: none;
  color: white;
  transition: margin 300ms cubic-bezier(0.645, 0.045, 0.355, 1);

  &:focus,
  &:active {
    outline: none;
  }
  &::placeholder {
    color: white;
  }
`;

export const Button = styled.button`
  line-height: 1;
  pointer-events: ${(props) => (props.barOpened ? "auto" : "none")};
  cursor: ${(props) => (props.barOpened ? "pointer" : "none")};
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
`;
