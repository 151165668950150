import { Fade } from "react-slideshow-image";
import Hero from "../Hero/Hero";
import { SlideContainer, EachImg } from "../../../AppLayout.style";
import { BannerLayout, SharpOverlay } from "./Banner.style";

function Banner(props) {
  return (
    <BannerLayout>
      <SharpOverlay />

      <Hero />

      <SlideContainer
        style={{
          textAlign: "left",
        }}
      >
        <Fade
          autoplay
          duration={5000}
          transitionDuration={500}
          arrows={false}
          pauseOnHover={false}
          canSwipe={false}
          Easing={"ease"}
        >
          {props.imagePaths.map((image, index) => (
            <EachImg src={image} alt={`RHIBMS BEST SCHOOL ${index}`} />
          ))}
        </Fade>
      </SlideContainer>
    </BannerLayout>
  );
}

export default Banner;
