import styled from "styled-components";
import { MDBBtn } from "mdb-react-ui-kit";
import { FaSearch } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";
import { MdLocationPin, MdOutlineCall, MdMail } from "react-icons/md";
import { Link } from "react-router-dom";
export const EventsHeadingLayout = styled.div`
  margin: 0 auto;
  margin-top: 5.5%;
  background: transparent;
  height: fit-content;
  width: fit-content;
  vertical-align: middle;
  text-align: center;
`;
export const Heading = styled.h1`
  font-family: "Esteban", serif;
  padding-top: 4%;
  font-size: 2.9em;
  font-weight: 600;
  vertical-align: middle;
  color: white;
`;
export const SubHeading = styled.h5`
  font-size: 1.4em;
  color: white;
  color: gainsboro;
`;

export const Form = styled.form`
  position: sticky;
  top: 13.5%;
  z-index: 1;
  border-radius: 6px;
  margin: 0 auto;
  margin-top: -6%;
  /* margin-bottom: 8.5%; */
  vertical-align: middle;
  /* padding-top:25%; */
  display: flex;
  /* align-items: center;
  justify-content: center; */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  /* background: rgba(255,255,255, 0.9); */
  color: black;
  /* Change width of the form depending if the bar is opened or not */
  width: 39rem;
  /* If bar opened, normal cursor on the whole form. If closed, show pointer on the whole form so user knows he can click to open it */
  width: ${(props) => props.mobileX};
  cursor: ${(props) => (props.barOpened ? "auto" : "pointer")};
  /* padding: 1rem; */
  height: 3.3rem;
  transition: width 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
`;

export const Input = styled.input`
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  line-height: 1;
  /* background-color: transparent; */
  width: 100%;
  padding-left: 7%;
  /* padding-left: 1%; */
  border: none;
  border-radius: 0 6px 6px 0;

  color: black;
  outline: none;

  transition: margin 300ms cubic-bezier(0.645, 0.045, 0.355, 1);

  &:focus,
  &:active {
    outline: none;
  }
  &::placeholder {
    color: grey;
  }
`;

export const StyledFaSearch = styled(FaSearch)`
  color: #304454;
  font-weight: 200;
  /* padding:2%; */
  /* margin-left: 95%; */
  font-size: 1.3rem;
`;
export const Button = styled.button`
  line-height: 1;
  pointer-events: ${(props) => (props.barOpened ? "auto" : "none")};
  cursor: ${(props) => (props.barOpened ? "pointer" : "none")};
  background-color: white;
  border-radius: 6px 0 0 6px;
  width: 8%;
  outline: none;
  color: black;
`;
export const EventCardVoidPic = styled.div`
  /* background: #b5d4d9; */
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 6px;
  padding-top: 2%;
  text-align: center;
  margin: 0 auto;
  margin-left: 10%;
  margin-top: 3%;
  margin-bottom: 3%;
  &:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
      rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  }
`;
export const NoEvent = styled.img`
  /* text-align: center; */
  margin-left: 22.5%;

  height: 80vh;
  width: 100vh;
`;
export const Time = styled.span`
  margin-right: 1%;
`;
export const Duration = styled.span`
  margin-right: 1%;
`;
export const End = styled.span``;
export const Location = styled.span``;
export const Title = styled.h4``;
export const OtherLayout = styled.div`
  /* background: #b5d4d9; */
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 6px;
  margin: 4%;
  margin-top: -1%;
`;

export const ImageForCardLayout = styled.div`
  background: red;
  border-radius: 6px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
  height: 35%;
  background: url(${(props) => props.bg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 7%;
`;

export const OtherLayoutPic = styled(ImageForCardLayout)`
  height: 45%;
`;
export const Category = styled.div`
  text-align: center;
  /* text-align: left; */
  /* padding: 0; */
  padding-top: 4%;
  text-transform: uppercase;
  color: #13e7a2;
  font-size: 0.7125em;
  /* line-height: 1.53846154em; */
  font-weight: 600;
`;

export const Headline = styled.h2`
  font-size: 1.025em;
  line-height: 1.14285714em;
  font-size: ${(props) => props.fS2};
  text-transform: capitalize;
  text-align: center;
  font-weight: 600;
  padding-left: 0;
  &:hover {
    color: darkgreen;
  }
`;
export const ContactP = styled.p`
  margin: 0 auto;
  width: 30%;
  width: ${(props) => props.width};
`;

export const FeaturedEventsLayout = styled.div`
  background: whitesmoke;
  background: ${(props) => props.bgColor};
  margin-top: -20%;
  margin-top: ${(props) => props.marginTop};
  padding: 10%;
  padding-top: 20%;
  padding: ${(props) => props.padding};
  padding-top: ${(props) => props.paddingTop};
  height: 45%;
  height: ${(props) => props.height};
  width: 100%;
`;
export const FeaturedEventsPic = styled.div`
  background: url(${(props) => props.bg});
  /* border: 1px solid white; */
  height: 60vh;
  border-radius: 6px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  width: 80%;
  width: ${(props) => props.width};
  margin: 0 auto;
  margin-top: -5.5%;
  background-size: cover;
  background-repeat: none;
`;
export const FeaturedEventsCategory = styled.p`
  padding-left: 2%;
  padding-top: 40%;
  /* width: 80%; */
  text-align: center;
  color: white;
  padding-top: ${(props) => props.CategoryPaddingTop};
  font-size: ${(props) => props.fS1};

  width: ${(props) => props.width};
`;
export const FeaturedEventsHeadline = styled.h3`
  /* margin: 0 auto; */
  color: white;
  text-align: center;

  /* width: ${(props) => props.width}; */
  font-size: ${(props) => props.fS2};

  /* font-size:1.7em; */
  &:hover {
    color: #25dea5;

    text-underline-offset: 10%;
  }
`;

export const AllEventsLayout = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexD};
  height: max-content;
  margin: 0 auto;
  width: 100%;
  padding-left: 0;
  padding: ${(props) => props.padding};

  background: rgb(125, 145, 130, 0.2);
`;
export const EachEvent = styled.div`
  background: gainsboro;
  background: url(${(props) => props.bg});
  background-size: cover;
  background-position: center;
  height: 28vh;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%;
  border-radius: 6px 6px 0 0;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  &:hover {
    outline: 0.5px solid white;
    -moz-outline-radius: 6px;
    outline-offset: -12px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    /* border: 1px solid white; */
    background-size: 100%;
  }
`;
export const EachEventContainer = styled.div`
  height: 41vh;
  width: 20%;
  /* padding-left:2%; */
  border-radius: 6px;
  background: whitesmoke;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  width: ${(props) => props.width};
  margin: 2%;
  margin: ${(props) => props.margin};
  /* padding: 2%; */
`;
export const RecentHighlightsLayout = styled(AllEventsLayout)`
  background-color: transparent;
`;
export const RecentHighlightsContainer = styled(EachEventContainer)`
  margin: ${(props) => props.mobileML};
`;
export const EachHighlight = styled(EachEvent)``;
export const AllHighlights = styled.div`
  margin-top: 4%;
`;

export const RecentHighlightsText = styled.h2`
  text-align: center;
  font-weight: 700;
`;

// STYLES FOR THE MODAL
export const Modal = styled.div`
  position: fixed;
  z-index: 9;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgb(0, 8, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: not-allowed;
  }
`;
export const ModalContent = styled.div`
  position: fixed;
  z-index: 5;
  overflow: scroll;
  margin-top: 27%;
  bottom: 0;
  height: 92%;
  width: 80%;
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-radius: 15px;
  &:hover {
    cursor: default;
  }
`;
export const ModalSideBar = styled.div`
  width: 30%;
  height: 100%;
  background-color: rgba(2, 99, 2, 0.1);
`;
export const ModalContact = styled(ContactP)`
  text-align: center;
  width: fit-content;
  padding-top: 2%;
  margin-top: 4%;
  &:hover {
    cursor: text;
  }
`;
export const ModalTitle = styled.h4`
  padding-top: 5%;
  text-transform: capitalize;
  margin-left: 0;
  text-align: left;
  &:hover {
    cursor: text;
  }
`;
export const ModalBody = styled.div`
  width: 70%;
  text-align: left;
  margin-left: 3%;
  &:hover {
    cursor: text;
  }
`;
export const TimeModal = styled.label`
  text-align: left;
  margin-left: 0;
  color: seagreen;
  font-weight: 600;
`;
export const ModalTime = styled.span`
  text-align: left;
  margin-left: 2%;
  color: black;
`;
export const ModalLocation = styled.span`
  text-align: left;
  margin-left: 2%;
  color: black;
`;
export const Cost = styled.span`
  text-align: left;
  margin-left: 2%;
  color: black;
`;
export const ModalFooter = styled.div`
  margin-left: 1%;
`;
export const ModalBtn = styled(AiFillCloseCircle)`
  /* position: absolute; */
  z-index: 11;
  margin-top: -3.4%;
  right: 0;
  font-size: 2rem;
  color: red;
  &:hover {
    font-size: 2.1rem;
    cursor: pointer;
  }
  &:active {
    font-size: 2.2rem;
  }
`;
