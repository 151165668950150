import styled, { keyframes } from "styled-components";
import { MdKeyboardArrowUp } from "react-icons/md";

export const Heading = styled.h1`
  text-align: center;
  color: green;
`;

export const Content = styled.div`
  overflow-y: scroll;
  height: 2500px;
`;

export const Button = styled.div`
  flex-direction: column;
  position: fixed;
  width: 40px;
  right: 1.5%;

  border-radius: 50%;
  /* margin: 4%; */
  bottom: 40px;
  height: 40px;
  font-size: 2rem;
  z-index: 2;
  cursor: pointer;
    transition: background-color 2s;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: ghostwhite;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
  }
`;
export const Span = styled.span`
  text-transform: uppercase;
  font-size: 0.3em;
`;
export const GlowAnimation = keyframes`
 0% {color:#384854; height: 38px;  }
 25% {color:#02B2CB; height: 40px; }
 /* 50% {color:#ABAC66; height: 48px;   } */
 75% {color: #A36B6C; height: 50px; }
 /* 85% {color: gainsboro; height: 60px; } */
 100% {color: #71747C;  height: 40px;}
`;
export const IconUp = styled(MdKeyboardArrowUp)`
margin-left:10%;
margin-bottom:39%;
color: black;

  &:hover {
    
    color: #30a45f;
    font-size: 2.1rem; 
    /* color:red; */
    /* -webkit-animation: ${GlowAnimation} 1s ease-in-out infinite alternate;
    -moz-animation: ${GlowAnimation} 1s ease-in-out infinite alternate;
    animation: ${GlowAnimation} 1s ease-in-out infinite alternate;
  } */
`;
