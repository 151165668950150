/**
 * @Ojage, that is me, I don take all code related to Events put am for this
 * single file in-order to manage my data fine fine!
 */

import SchoolEventCards from "./SchoolEventCards";
import * as S from "./Events.style";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

const YaEventsContainerLayoutMobile = styled(S.YaEventsContainerLayout)`
  height: fit-content;
  padding: 4%;
`;
const YaEventsSubtitleLayoutMobile = styled(S.YaEventsSubtitleLayout)`
  letter-spacing: 5px;
`;
const EventsMobileHead1 = styled.h2`
  color: #265854;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;
  font-size: 0.8125rem;
  font-weight: 700;
  vertical-align: baseline;
`;
const EventsMobileHead2 = styled.h3`
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-transform: capitalize;
  text-align: center;
  letter-spacing: 2px;
  font-size: 1.8125rem;
  font-weight: 800;
  vertical-align: baseline;
`;

function EventsWrapper() {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const isTablet = useMediaQuery({
    query: "(max-width: 1224px)",
  });

  const isMobile = useMediaQuery({
    query: "(max-width: 786px)",
  });

  const isPortrait = useMediaQuery({
    query: "(orientation: portrait)",
  });

  const isRetina = useMediaQuery({
    query: "(max-resolution: 300dpi)",
  });

  return (
    <div>{isMobile ? <EventsMobile isMobile={isMobile} /> : <Events />}</div>
  );
}
export default EventsWrapper;
function Events() {
  return (
    <S.YaEventsContainerLayout>
      <S.YaEventsHeadingLayout>RHIBMS Events</S.YaEventsHeadingLayout>
      <S.YaEventsSubtitleLayout>
        What’s happening on campus
      </S.YaEventsSubtitleLayout>
      <SchoolEventCards />

      <S.YaMDBBtnLayout style={{ marginBottom: "2%" }} width="30%">
        <S.YaMDBBtnLinkLayout to="/">More RHIBMS Events</S.YaMDBBtnLinkLayout>
      </S.YaMDBBtnLayout>
    </S.YaEventsContainerLayout>
  );
}
function EventsMobile({ isMobile }) {
  return (
    <YaEventsContainerLayoutMobile>
      <S.YaEventsHeadingLayout>RHIBMS Events</S.YaEventsHeadingLayout>
      <YaEventsSubtitleLayoutMobile>
        What’s happening on campus
      </YaEventsSubtitleLayoutMobile>
      <SchoolEventCards isMobile={isMobile} />
      <S.YaMDBBtnLayout mobileMT="4%">
        <S.YaMDBBtnLinkLayout  to="/">More RHIBMS Events</S.YaMDBBtnLinkLayout>
      </S.YaMDBBtnLayout>
    </YaEventsContainerLayoutMobile>
  );
}
