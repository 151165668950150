import { Link } from "react-router-dom";
// INternal Imports
import { LogoLayout, RHIBMS, University } from "./Logo.style";
import logo from "./../../../../../assets/img/logo.png";
function Logo({ scrolled }) {
  return (
    <Link to='/'>
      <LogoLayout>
        <img
          src={logo}
          alt="Logo"
          height="300px"
          width="300px"
          style={{
            
            borderRadius: 50 + "%",
            boxShadow: "inset 0px 4px 4px #009948",
          }}
        />
        <RHIBMS scrolled={scrolled}>
          RHIBMS <University scrolled={scrolled}>University</University>
        </RHIBMS>
      </LogoLayout>
    </Link>
  );
}

export default Logo;
