import { MDBRow, MDBCol, MDBContainer } from "mdb-react-ui-kit";
import { useState, useRef } from "react";

import AllEvents_DATA from "./Elements/AllEvents_DATA.json";

import SearchForm from "./Elements/SearchForm";

import Footer from "../LandingPageComponents/Footer/Footer";

import { MainEventsLayout, MainEventsHeaderLayout } from "./MainEvents.style";
import * as Sub from "./Elements/Elements";
import { Gradient } from "../../AppLayout.style";
import { Link } from "react-router-dom";
function MainEventsWrapper() {
  const FeaturedEvents = AllEvents_DATA.slice(0, 1);

  const CardOneImg =
    "https://images.unsplash.com/photo-1601979112151-26f9fa4520b5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8Y2FtZXJvb258ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60";
  const aboutedu = "assets/img/aboutedu.svg";

  return <MainEvents AllEvents_DATA={AllEvents_DATA} />;
}
export default MainEventsWrapper;

function MainEvents(props) {
  const [searchTerm, setSearchTerm] = useState("");
  const HandleCallBack = (childData) => {
    setSearchTerm(childData);
  };
  return (
    <MainEventsLayout>
      <MainEventsHeaderLayout height="10%">
        <Sub.EventsHeading />
      </MainEventsHeaderLayout>
      <SearchForm ParentCallBack={HandleCallBack} />

      <Sub.EventCardVoidPic searchTerm={searchTerm} />
      {/* <Sub.AllEvents />
      <Sub.RecentHighlights /> */}
      <Footer />
    </MainEventsLayout>
  );
}
