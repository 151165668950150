import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
  }
  render() {
    if (this.state.hasError) {
      return (
        <>
          <h4 style={{ fontFamily: "Montserrat", textAlign: "center" }}>
            Something went Horrendously wrong.
            <br />
            <code>
              Developer Be Calm and <b>Check your Links</b>
            </code>
          </h4>
          <h5 style={{ fontFamily: "Montserrat", textAlign: "center" }}>
            Link broken
            <br />
            This will be fixed in less than <code>15</code> seconds
          </h5>
          <img
            style={{
              marginLeft: "26%",
              height: "380px",
              maxWidth: "980px",
              textAlign: "center",
            }}
            src="https://media.istockphoto.com/photos/breaking-chains-picture-id1223144712?b=1&k=20&m=1223144712&s=170667a&w=0&h=5QS97TvL76N-KYZOnVVn8s7Sh6qwFyPeAUwJbaQpVN0="
            alt="Mild Error"
          />
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

/**
 * In the code above, getDerivedStateFromError function renders the fallback UI interface
 * when the render method has an error.
 * componentDidCatch logs the error information to an error tracking service.
 */
