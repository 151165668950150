import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import MDBCardInHero from "../Hero/MDBCardInHero";
import {
  FeaturedLayout,
  FeaturedMobileLayout,
  FeaturedMobileHead1,
  FeaturedMobileHead2,
} from "./Featured.style";
function FeaturedWrapper() {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const isTablet = useMediaQuery({
    query: "(max-width: 1224px)",
  });

  const isMobile = useMediaQuery({
    query: "(max-width: 786px)",
  });

  const isPortrait = useMediaQuery({
    query: "(orientation: portrait)",
  });

  const isRetina = useMediaQuery({
    query: "(max-resolution: 300dpi)",
  });
  console.log(isMobile);
  return <div>{isMobile ? <FeaturedMobile /> : <Featured />}</div>;
}

export default FeaturedWrapper;

function Featured() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const url = " 159.223.239.215/api/v1/featured";
    fetch();
  });
  return (
    <FeaturedLayout>
      <div
        style={{
          textAlign: "center",
          padding: "3.4%",
          paddingBottom: 0,
          paddingTop: "2%",
        }}
      >
        <h2>ADMISSIONS</h2>
        <p>
          Begin application to higher education studies in Cameroon. Study HND,
          bachelor's and master's courses and programmes taught in English.
        </p>

        <div
          style={{
            margin: "0 auto",
            // background: "lavender",
            display: "flex",
            // flexDirection: "row",
          }}
        >
          <div style={{display: "flex",  margin: "0 auto",}}>
          <div style={{ paddingRight: "2%" }}>
            <MDBCardInHero
              display="block"
              height="16rem"
              margin="2%"
              maxWidth="26rem"
              CardHead="HND Programs"
              Heading="A superb high professional university program"
              TextBody="HND in Nursing, HND in Midwifery, HND in Lab Science "
              ButtonText="See More"
              DaysAgo={5}
            />
          </div>
          

          <div style={{ paddingRight: "2%" }}>
            <MDBCardInHero
              display="block"
              height="16rem"
              maxWidth="26rem"
              margin="2%"
              CardHead="Bachelor Programs"
              Heading="A superb high professional university program"
              TextBody="BSc in Nursing, BSc in Midwifery, BSc in Medical Lab Science"
              ButtonText="See More"
              DaysAgo={8}
            />
          </div>
          <div style={{ paddingRight: "2%" }}>
            <MDBCardInHero
              display="block"
              height="16rem"
              maxWidth="26rem"
              margin="2%"
              CardHead="Master Programs"
              Heading="A superb high professional university program"
              TextBody="MSc in Public Health, MSc in Clinical Psychology, MSc in Medico surgical Nursing"
              ButtonText="See More"
              DaysAgo={3}
            />
          </div>
          </div>
        </div>
      </div>
    </FeaturedLayout>
  );
}
const FeaturedMobile = () => {
  return (
    <FeaturedMobileLayout>
      <FeaturedMobileHead1>Featured</FeaturedMobileHead1>
      <FeaturedMobileHead2>Must reads from RHIBMS</FeaturedMobileHead2>
      <div style={{ padding: "2%" }}>
        <MDBCardInHero
          display="block"
          height="20rem"
          MarginLeft="2%"
          maxWidth="26rem"
          CardHead="HND Programs"
          Heading="A superb high professional university program"
          TextBody="HND in Nursing, HND in Midwifery, HND in Lab Science "
          ButtonText="See More"
          DaysAgo={5}
        />
        <MDBCardInHero
          display="block"
          height="20rem"
          maxWidth="26rem"
          MarginLeft="2%"
          CardHead="Bachelor Programs"
          Heading="A superb high professional university program"
          TextBody="BSc in Nursing, BSc in Midwifery, BSc in Medical Lab Science"
          ButtonText="See More"
          DaysAgo={8}
        />
        <MDBCardInHero
          display="block"
          height="20rem"
          maxWidth="26rem"
          MarginLeft="2%"
          CardHead="Master Programs"
          Heading="A superb high professional university program"
          TextBody="MSc in Public Health, MSc in Clinical Psychology, MSc in Medico surgical Nursing"
          ButtonText="See More"
        />
      </div>
    </FeaturedMobileLayout>
  );
};
