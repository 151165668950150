import * as Styled from "./TheNews.style";
import { useMediaQuery } from "react-responsive";
import "bootstrap";
import { Link } from "react-router-dom";
import Footer from "../LandingPageComponents/Footer/Footer";
import Share from "./Share";
import AllNews_DATA from "./Elements/AllNews_DATA.json";
import MiscNews from "./MiscNews";
import { FaArrowLeft } from "react-icons/fa";
function TheNewsWrapper() {
  const FeaturedNews = AllNews_DATA.slice(0, 1);
  const RelatedNews = AllNews_DATA.slice(2, 3);

  const NewsNav = [
    {
      title: "Category",
      subTitle: FeaturedNews[0].category,
      path: "/",
    },
    {
      title: "Date Published",
      subTitle: FeaturedNews[0].pubishedDate,
      path: "/MainNews",
    },
    {
      title: "Media Contact",
      subTitle: "info@rhibms.com",
      path: "/",
    },
  ];
  const CardOneImg =
    "https://images.unsplash.com/photo-1601979112151-26f9fa4520b5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8Y2FtZXJvb258ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60";
  const aboutedu = "assets/img/aboutedu.svg";
  const isMobile = useMediaQuery({
    query: "(max-width: 786px)",
  });
  return (
    <div>
      {isMobile ? (
        <TheNewsMobile NewsNav={NewsNav} FeaturedNews={FeaturedNews} />
      ) : (
        <TheNews
          NewsNav={NewsNav}
          RelatedNews={RelatedNews}
          FeaturedNews={FeaturedNews}
        />
      )}
    </div>
  );
}
export default TheNewsWrapper;
function TheNews(props) {
  const { FeaturedNews } = props;

  return (
    <>
      <Styled.TheNewsLayout>
        <Styled.Sidebar>
          <Styled.NewsNav>
            {props.NewsNav.map((NewsNavItem) => (
              <Styled.NewsNavItem>
                <hr />
                <Styled.NewsNavTitle>{NewsNavItem.title}</Styled.NewsNavTitle>
                <Styled.NewsNavSubTitle>
                  {NewsNavItem.subTitle}
                </Styled.NewsNavSubTitle>
              </Styled.NewsNavItem>
            ))}
            <Share />
            <MiscNews RelatedNews={props.RelatedNews} />
          </Styled.NewsNav>
        </Styled.Sidebar>
        <Styled.MainSide>
          <Styled.MainNewsBtn>
            <Styled.LinkMainNews to="/MainNews">
              <FaArrowLeft />
              All News
            </Styled.LinkMainNews>
          </Styled.MainNewsBtn>
          <Styled.ImageMain bg={FeaturedNews[0].image} />
          <Styled.Headline>{FeaturedNews[0].headline}</Styled.Headline>
          <Styled.RealContent>
            RHIBMS the leading institution in health care training & joining the
            public to fight against the spread of cholera in the south west
            region. In collaboration with the effort of the Ministry of Public
            Health and all the Medical Facilities, RHIBMS have taken the step of
            leadership by incorporating all its final year students to lend a
            hand and increase the task force in different medical facilities
            such as Regional Hospital Buea, Mile 16 Hospital
          </Styled.RealContent>
          <Styled.RealContent>
            RHIBMS the leading institution in health care training & joining the
            public to fight against the spread of cholera in the south west
            region. In collaboration with the effort of the Ministry of Public
            Health and all the Medical Facilities, RHIBMS have taken the step of
            leadership by incorporating all its final year students to lend a
            hand and increase the task force in different medical facilities
            such as Regional Hospital Buea, Mile 16 Hospital. RHIBMS the leading
            institution in health care training join the public to fight against
            the spread of cholera in the south west region. In collaboration
            with the effort of the Ministry of Public Health and all the Medical
            Facilities, RHIBMS have taken the step of leadership by
            incorporating all its final year students to lend a hand and
            increase the task force in different medical facilities such as
            Regional Hospital Buea, Mile 16 Hospital.
          </Styled.RealContent>
          <Styled.RealContent>
            RHIBMS the leading institution in health care training & joining the
            public to fight against the spread of cholera in the south west
            region. In collaboration with the effort of the Ministry of Public
            Health and all the Medical Facilities, RHIBMS have taken the step of
            leadership by incorporating all its final year students to lend a
            hand and increase the task force in different medical facilities
            such as Regional Hospital Buea, Mile 16 Hospital. RHIBMS the leading
            institution in health care training join the public to fight against
            the spread of cholera in the south west region. In collaboration
            with the effort of the Ministry of Public Health and all the Medical
            Facilities, RHIBMS have taken the step of leadership by
            incorporating all its final year students to lend a hand and
            increase the task force in different medical facilities such as
            Regional Hospital Buea, Mile 16 Hospital. RHIBMS the leading
            institution in health care training join the public to fight against
            the spread of cholera in the south west region. In collaboration
            with the effort of the Ministry of Public Health and all the Medical
            Facilities, RHIBMS have taken the step of leadership by
            incorporating all its final year students to lend a hand and
            increase the task force in different medical facilities such as
            Regional Hospital Buea, Mile 16 Hospital. RHIBMS the leading
            institution in health care training join the public to fight against
            the spread of cholera in the south west region. In collaboration
            with the effort of the Ministry of Public Health and all the Medical
            Facilities, RHIBMS have taken the step of leadership by
            incorporating all its final year students to lend a hand and
            increase the task force in different medical facilities such as
            Regional Hospital Buea, Mile 16 Hospital
          </Styled.RealContent>
        </Styled.MainSide>
      </Styled.TheNewsLayout>
      <Footer />
    </>
  );
}

function TheNewsMobile(props) {
  const FeaturedNews = AllNews_DATA.slice(0, 1);
  return (
    <>
      <Styled.TheNewsLayout>
        <Styled.MainSide width="100%">
          <Styled.MainNewsBtn mobileMT="2%">
            <Styled.LinkMainNews to="/MainNews">
              <FaArrowLeft /> All News
            </Styled.LinkMainNews>
          </Styled.MainNewsBtn>
          <Styled.ImageMain height="20vh" bg={FeaturedNews[0].image} />
          <Styled.Headline>{FeaturedNews[0].headline}</Styled.Headline>
          <Styled.RealContent>
            RHIBMS the leading institution in health care training join the
            public to fight against the spread of cholera in the south west
            region. In collaboration with the effort of the Ministry of Public
            Health and all the Medical Facilities, RHIBMS have taken the step of
            leadership by incorporating all its final year students to lend a
            hand and increase the task force in different medical facilities
            such as Regional Hospital Buea, Mile 16 Hospital
          </Styled.RealContent>
          <Styled.RealContent>
            RHIBMS the leading institution in health care training join the
            public to fight against the spread of cholera in the south west
            region. In collaboration with the effort of the Ministry of Public
            Health and all the Medical Facilities, RHIBMS have taken the step of
            leadership by incorporating all its final year students to lend a
            hand and increase the task force in different medical facilities
            such as Regional Hospital Buea, Mile 16 Hospital. RHIBMS the leading
            institution in health care training join the public to fight against
            the spread of cholera in the south west region. In collaboration
            with the effort of the Ministry of Public Health and all the Medical
            Facilities, RHIBMS have taken the step of leadership by
            incorporating all its final year students to lend a hand and
            increase the task force in different medical facilities such as
            Regional Hospital Buea, Mile 16 Hospital.
          </Styled.RealContent>
          <Styled.RealContent>
            RHIBMS the leading institution in health care training join the
            public to fight against the spread of cholera in the south west
            region. In collaboration with the effort of the Ministry of Public
            Health and all the Medical Facilities, RHIBMS have taken the step of
            leadership by incorporating all its final year students to lend a
            hand and increase the task force in different medical facilities
            such as Regional Hospital Buea, Mile 16 Hospital. RHIBMS the leading
            institution in health care training join the public to fight against
            the spread of cholera in the south west region. In collaboration
            with the effort of the Ministry of Public Health and all the Medical
            Facilities, RHIBMS have taken the step of leadership by
            incorporating all its final year students to lend a hand and
            increase the task force in different medical facilities such as
            Regional Hospital Buea, Mile 16 Hospital. RHIBMS the leading
            institution in health care training join the public to fight against
            the spread of cholera in the south west region. In collaboration
            with the effort of the Ministry of Public Health and all the Medical
            Facilities, RHIBMS have taken the step of leadership by
            incorporating all its final year students to lend a hand and
            increase the task force in different medical facilities such as
            Regional Hospital Buea, Mile 16 Hospital. RHIBMS the leading
            institution in health care training join the public to fight against
            the spread of cholera in the south west region. In collaboration
            with the effort of the Ministry of Public Health and all the Medical
            Facilities, RHIBMS have taken the step of leadership by
            incorporating all its final year students to lend a hand and
            increase the task force in different medical facilities such as
            Regional Hospital Buea, Mile 16 Hospital
          </Styled.RealContent>
        </Styled.MainSide>
      </Styled.TheNewsLayout>
      <Footer />
    </>
  );
}
