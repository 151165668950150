export const AdmissionsRes = [
  {
    programType: "HND Biomedical",
    programFee: "310,000FCFA",
    programImg:
      "https://illlustrations.co/static/444a9d4f69327cb6d55d977f8e308e26/ee604/129-wear-mask.png",
    subPrograms: [
      {
        title: "NURSING",
        tuition: {
          local: "310,000FCFA",
          notLocal: "310,000FCFA",
        },
        duration: "3",
      },
      {
        title: "LAB SCIENCE",
        tuition: {
          local: "310,000FCFA",
          notLocal: "310,000FCFA",
        },
        duration: "3",
      },
      {
        title: "MIDWIFERY",
        tuition: {
          local: "310,000FCFA",
          notLocal: "310,000FCFA",
        },
        duration: "3",
      },
      {
        title: "NUTRITION AND DIETETICS",
        tuition: {
          local: "310,000FCFA",
          notLocal: "310,000FCFA",
        },
        duration: "3",
      },
      {
        title: "PHYSIOTHERAPHY",
        tuition: {
          local: "310,000FCFA",
          notLocal: "310,000FCFA",
        },
        duration: "3",
      },
      {
        title: "HEALTH CARE MANAGEMENT",
        tuition: {
          local: "310,000FCFA",
          notLocal: "310,000FCFA",
        },
        duration: "3",
      },
      {
        title: "PHARMACY TECHNOLOGY",
        tuition: {
          local: "310,000FCFA",
          notLocal: "310,000FCFA",
        },
        duration: "3",
      },
      {
        title: "DENTAL THERAPHY",
        tuition: {
          local: "310,000FCFA",
          notLocal: "310,000FCFA",
        },
        duration: "3",
      },
      {
        title: "VOCATIONAL NURSING ASSISTANCE ",
        tuition: {
          local: "300,000FCFA",
          notLocal: "300,000FCFA",
        },
        duration: "1",
      },
    ],
  },
  {
    programType: "BSc Biomedical",
    programFee: "450,000FCFA",
    programImg:
      "https://www.gstatic.com/mobilesdk/180326_mobilesdk/discoverycards/experiments.png",
      subPrograms: [
        {
          title: "NURSING",
          tuition: {
            local: "450,000FCFA",
            notLocal: "450,000FCFA",
          },
          duration: "1",
        },
        {
          title: "MEDICAL LABORATORY SCIENCE",
          tuition: {
            local: "450,000FCFA",
            notLocal: "450,000FCFA",
          },
          duration: "1",
        },
        {
          title: "MIDWIFERY",
          tuition: {
            local: "450,000FCFA",
            notLocal: "450,000FCFA",
          },
          duration: "1",
        },
        {
          title: "NURSING AND MIDWIFERY",
          tuition: {
            local: "450,000FCFA",
            notLocal: "450,000FCFA",
          },
          duration: "1",
        },
      ],
    },
  {
    programType: "MSc Biomedical",
    programFee: "650,000FCFA",
    programImg:
      "https://www.reshot.com/preview-assets/illustrations/DW93L8VTNX/couple-of-doctors-DW93L8VTNX-w600.jpg",
      subPrograms: [
        {
          title: "Postgraduate Diploma In Midwifery",
          tuition: {
            local: "650,000FCFA ",
            notLocal: "850,000FCFA ",
          },
          duration: "1",
        },
        {
          title: "Nursing Education",
          tuition: {
            local: "650,000FCFA ",
            notLocal: "850,000FCFA ",
          },
          duration: "2",
        },
        {
          title: "Public Health",
          tuition: {
            local: "650,000FCFA ",
            notLocal: "850,000FCFA ",
          },
          duration: "2",
        },
        {
          title: "Midwifery Science",
          tuition: {
            local: "650,000FCFA ",
            notLocal: "850,000FCFA ",
          },
          duration: "2",
        },
        {
          title: "Medico surgical Nursing(Nursing Science)",
          tuition: {
            local: "650,000FCFA ",
            notLocal: "850,000FCFA ",
          },
          duration: "2",
        },{
          title: "Clinical Psychology",
          tuition: {
            local: "650,000FCFA ",
            notLocal: "850,000FCFA ",
          },
          duration: "2",
        },{
          title: "Pharmacology",
          tuition: {
            local: "650,000FCFA ",
            notLocal: "850,000FCFA ",
          },
          duration: "2",
        },{
          title: "Pediatric Nursing",
          tuition: {
            local: "650,000FCFA ",
            notLocal: "850,000FCFA ",
          },
          duration: "2",
        },{
          title: "Medical Microbiology And Parasitology",
          tuition: {
            local: "650,000FCFA ",
            notLocal: "850,000FCFA ",
          },
          duration: "2",
        },{
          title: "Chemical Pathology",
          tuition: {
            local: "650,000FCFA ",
            notLocal: "850,000FCFA ",
          },
          duration: "2",
        },{
          title: "Chemical Hematology",
          tuition: {
            local: "650,000FCFA ",
            notLocal: "850,000FCFA ",
          },
          duration: "2",
        },{
          title: "Business Administration(MBA)",
          tuition: {
            local: "650,000FCFA ",
            notLocal: "850,000FCFA ",
          },
          duration: "2",
        },
      ],
    },
  {
    programType: "HND Management Science",
    programFee: "250,000FCFA",
    programImg:
      "https://www.reshot.com/preview-assets/illustrations/N3FQ5WGSH2/school-education-N3FQ5WGSH2-w600.jpg",
      subPrograms: [
        {
          title: "NURSING",
          tuition: {
            local: "310,000FCFA",
            notLocal: "310,000FCFA",
          },
          duration: "3",
        },
        {
          title: "LAB SCIENCE",
          tuition: {
            local: "310,000FCFA",
            notLocal: "310,000FCFA",
          },
          duration: "3",
        },
        {
          title: "MIDWIFERY",
          tuition: {
            local: "310,000FCFA",
            notLocal: "310,000FCFA",
          },
          duration: "3",
        },
        {
          title: "NUTRITION AND DIETETICS",
          tuition: {
            local: "310,000FCFA",
            notLocal: "310,000FCFA",
          },
          duration: "3",
        },
      ],
    },

  {
    programType: "State Registered Nursing",
    programFee: "400,000FCFA",
    programImg:
      "https://www.reshot.com/preview-assets/illustrations/LVZ9KU83WT/classroom-and-teacher-LVZ9KU83WT-w600.jpg",
      subPrograms: [
        {
          title: "NURSING",
          tuition: {
            local: "310,000FCFA",
            notLocal: "310,000FCFA",
          },
          duration: "3",
        },
        {
          title: "LAB SCIENCE",
          tuition: {
            local: "310,000FCFA",
            notLocal: "310,000FCFA",
          },
          duration: "3",
        },
        {
          title: "MIDWIFERY",
          tuition: {
            local: "310,000FCFA",
            notLocal: "310,000FCFA",
          },
          duration: "3",
        },
        {
          title: "NUTRITION AND DIETETICS",
          tuition: {
            local: "310,000FCFA",
            notLocal: "310,000FCFA",
          },
          duration: "3",
        },
      ],
    },
  {
    programType: "Nursing Assistance",
    programFee: "310,000FCFA",
    programImg:
      "https://www.gstatic.com/mobilesdk/180326_mobilesdk/discoverycards/experiments.png",
      subPrograms: [
        {
          title: "NURSING",
          tuition: {
            local: "310,000FCFA",
            notLocal: "310,000FCFA",
          },
          duration: "3",
        },
        {
          title: "LAB SCIENCE",
          tuition: {
            local: "310,000FCFA",
            notLocal: "310,000FCFA",
          },
          duration: "3",
        },
        {
          title: "MIDWIFERY",
          tuition: {
            local: "310,000FCFA",
            notLocal: "310,000FCFA",
          },
          duration: "3",
        },
        {
          title: "NUTRITION AND DIETETICS",
          tuition: {
            local: "310,000FCFA",
            notLocal: "310,000FCFA",
          },
          duration: "3",
        },
      ],
    },
];
export const HNDBiomedical = [
  "NURSING",
  "LAB SCIENCE",
  "MIDWIFERY",
  "NUTRITION AND DIETETICS",
  "PHYSIOTHERAPHY",
  "HEALTH CARE MANAGEMENT",
  "PHARMACY TECHNOLOGY",
  "DENTAL THERAPHY",
  "VOCATIONAL NURSING ASSISTANCE",
];
export const HNDHomeEcons = [
  "BAKERY AND FOOD PROCESSING",
  "FASHION, CLOTHING AND TEXTILE",
  "TOURISM AND HOTEL MANAGEMENT",
  "HOTEL MANAGEMENT AND CATERING",
];
export const HNDArts = [
  "DIDATICS, CURRICULM DEVELOPMENT AND TEACHING",
  "EDUCATIONAL MANAGEMENT AND ADMINISTRATION",
  "SPECIAL EDUCATION",
];
export const Bachelors = [
  "NURSING",
  "MEDICAL LABORATORY SCIENCE",
  "MIDWIFERY",
  "NURSING AND MIDWIFERY",
];

export const Masters = [
  "Postgraduate Diploma In Midwifery",
  "Masters Of Nursing Education",
  "Masters Of Midwifery Science",
  "Masters Of Medico surgical Nursing(Nursing Science)",
  "Masters Of Pharmacology",
  "Masters In Pediatric Nursing",
  "Masters Of Medical Microbiology And Parasitology",
  "Masters In Chemical Pathology",
  "Masters In Chemical Hematology",
  "Masters Of Business Administration",
];

export const PublicHealth = ["STATE REGISTERED NURSING ", "NURSING ASSISTANCE"];
