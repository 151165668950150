import * as S from "./Elements.style";

function HeadTextElement(props) {
  return (
    <S.HeadTextElement paddingTop={props.paddingTop}>
      <S.H1 fS={props.fS}>{props.title}</S.H1>
      <S.H5 fS={props.fS}>
        RHIBMS {">"}{" "}
        <S.Span margin={props.spanMargin}>
          {props.title}
        </S.Span>
      </S.H5>
    </S.HeadTextElement>
  );
}

export default HeadTextElement;
