import styled from 'styled-components';

export const SchoolsLayout = styled.div`
    font-family: Montserrat;
    color: 'black';
    height: fit-content;
    background-image: url(${props => props.bg});
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-color: #F5F5F5;
        `;
export const RhibmsSchoolsInfo = styled.h2`
    font-weight: 800;
    font-size: 2.5em;
    /* background-color: whitesmoke; */
    padding:1%;
    text-align:center;

`;
export const RSIParagraph = styled.div`
    font-size:1em;
    margin: 0 auto;
    width: 90%;
    width: ${props => props.width};
`;
export const CardForSchool= styled.div`
    width: 90%;
    width: ${props => props.width};
    padding:3%;
    padding: ${props => props.padding};
    margin: 0 auto;
    height:fit-content;
    text-align:center;
    display: flex;
    background-color: ${props => props.bgColor};
    /* margin-right:0; */
    margin-top:3%;
    margin-bottom:1%;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    &:hover{
        cursor:pointer;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    }
`;
export const CardForSchoolImg= styled.div`
    width: 400px;
    height:400px;
    height:${props => props.height};
    border-radius: 6px;
    background: url(${props => props.bg});
    margin-left: 5%;
    margin-left: ${props => props.mL};
`;
export const RhibmsSchoolsInner= styled.div`
    width:80%;
    width: ${props => props.width};
   
    text-align:left;
`;
export const SchoolName= styled.h3`
    font-weight: 900;
    font-family: 'Esteban', serif;
    font-size: 4.2em;
    font-size: ${props => props.fS};
    text-align:left;
    width:100%;
`;
export const ShortDesc= styled.p`
    font-size: 2.2em;
    font-size: ${props => props.fS};
    line-height:2.3pc;
    text-align:left;
    padding:2%;
`;
export const SchoolsHeaderLayout = styled.div`
    height: 35vh;
    height: ${props => props.height};
    margin-left: 0;
    background-color: ${props => props.bgC};
    background-image: url(${props => props.bg});
    background-size: cover;
    background-position: top;
    `;

export const SlideContainerForSchools = styled.div`
    position: relative;
    top: 1pc;
    z-index: 1;
    margin-left: 60px;
    height: 40vh;
    width: 70vw;
    `;

export const EachProgImg = styled.img`
    margin: 0 auto;
    position:absolute;
    top:0;
    height: 60vh;
    width: 70vw;
    font-family: Montserrat;
    object-fit: cover;
    border-radius: 16px;
    `;

export const LiLayout = styled.li`
    list-style-type: square;
    `;