// Other Imports
import { useState,useEffect } from "react";
import { MDBCard, MDBCardImage } from "mdb-react-ui-kit";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import "animate.css";
// Locally created Imports
import * as S from "./Testimonials.style";
import { device } from "../../../AppLayout.style";
import * as TestimonialsRes from "./TestimonialsRes";

function TestimonialsWrapper(props) {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isEkScreen = useMediaQuery({
    query: "(min-width:908px)",
    query: "(max-width:1430px)",
  });
  const isTablet = useMediaQuery({
    query: "(max-width: 1224px)",
  });

  const isMobile = useMediaQuery({
    query: "(max-width: 786px)",
  });

  const isPortrait = useMediaQuery({
    query: "(orientation: portrait)",
  });

  const isRetina = useMediaQuery({
    query: "(max-resolution: 300dpi)",
  });
  
  return (
    <div>
      {isMobile ? (
        <TestimonialsMobile  />
      ) : (
        <Testimonials isEkScreen={isEkScreen} />
      )}
    </div>
  );
}

export default TestimonialsWrapper;
function Testimonials() {
  return (
    <S.Testimonials>
      <S.TestimonialsHead>TESTIMONIALS</S.TestimonialsHead>
      <>
      {/* CenterTestimonialCard */}
        <S.TestimonialsCarousel
          showArrows={false}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          autoPlay={true}
          interval={6100}
          position="relative"
          zIndex="9"
        >
          {TestimonialsRes.TestimonialsRes.map((TestimonialMain, index) => (
            <>
              <S.TestimonialsCard
                color="white"
                bgColor="#075B55"
                margin="0 auto"
              >
                <S.TestimonialsQuoteLeft color="white" size={80} />
                <S.TestimonialsContent>
                  {TestimonialMain.text}
                </S.TestimonialsContent>
                <S.TestimonialsName color="white">
                  {TestimonialMain.name}
                </S.TestimonialsName>
                <S.TestimonialsNamePost color="white">
                  {TestimonialMain.post}
                </S.TestimonialsNamePost>
              </S.TestimonialsCard>
              <S.TestimonialsImage
                imgPath={TestimonialMain.img}
                marginTop="-5%"
              />
            </>
          ))}
        </S.TestimonialsCarousel>
      {/* LeftTestimonialCard */}
        
        <S.TestimonialsCarousel
          showArrows={false}
          infiniteLoop={false}
          showIndicators={false}
          showThumbs={false}
          showStatus={false}
          autoPlay={true}
          interval={10000}
          position="relative"
          zIndex="1"
          marginTop="-20%"
          margin="0"
          blur="18px"
        >
          {TestimonialsRes.TestimonialsRes.map((TestimonialLeft, index) => (
            <>
              <S.TestimonialsCard key={index} color="black" bgColor="#fff">
                <S.TestimonialsQuoteLeft color="black" size={80} />
                <S.TestimonialsContent>
                  {TestimonialLeft.text}
                </S.TestimonialsContent>
                <S.TestimonialsName color="black">
                  {TestimonialLeft.name}
                </S.TestimonialsName>
                <S.TestimonialsNamePost color="black">
                  {TestimonialLeft.post}
                </S.TestimonialsNamePost>
              </S.TestimonialsCard>
              <S.TestimonialsImage
                imgPath={TestimonialLeft.img}
                marginLeft="15%"
                zIndex="10"
                marginTop="-5%"
              />
            </>
          ))}
        </S.TestimonialsCarousel>

        <S.TestimonialsCarousel
          showArrows={false}
          infiniteLoop={false}
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          autoPlay={true}
          interval={11000}
          position="relative"
          zIndex="1"
          blur="18px"
          marginTop="-20%"
        >
          {TestimonialsRes.TestimonialsRes.map((TestimonialRight, index) => (
            <>
              <S.TestimonialsCard color="black" bgColor="#fff" marginLeft="60%">
                <S.TestimonialsQuoteLeft color="black" size={80} />
                <S.TestimonialsContent>
                  {TestimonialRight.text}
                </S.TestimonialsContent>
                <S.TestimonialsName color="black">
                  {TestimonialRight.name}
                </S.TestimonialsName>
                <S.TestimonialsNamePost color="black">
                  {TestimonialRight.post}
                </S.TestimonialsNamePost>
              </S.TestimonialsCard>
              <S.TestimonialsImage
                imgPath={TestimonialRight.img}
                zIndex="9"
                marginTop="-5%"
                marginRight="15%"
              />
            </>
          ))}
        </S.TestimonialsCarousel>
      </>
    </S.Testimonials>
  );
}

function TestimonialsMobile() {
  return (
                
    <S.Testimonials bgColor="white" height="fit-content" >
      <S.TestimonialsHead>TESTIMONIALS</S.TestimonialsHead>
      <>
        <S.TestimonialsCarousel
          showArrows={false}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          autoPlay={true}
          interval={6100}
          position="relative"
          zIndex="9"
          width="100%"
        >
          {TestimonialsRes.TestimonialsRes.map((TestimonialMain, index) => (
            <>
              <S.TestimonialsCard
                color="white"
                bgColor="#075B55"
                width="100%"
          >
                <S.TestimonialsQuoteLeft color="white" size={45} />
                <S.TestimonialsContent>
                  {TestimonialMain.text}
                </S.TestimonialsContent>
                <S.TestimonialsName color="white">
                  {TestimonialMain.name}
                </S.TestimonialsName>
                <S.TestimonialsNamePost color="white">
                  {TestimonialMain.post}
                </S.TestimonialsNamePost>
              </S.TestimonialsCard>
              <S.TestimonialsImage
                imgPath={TestimonialMain.img}
                marginTop="-5%"
                height="40px"
                width="40px"
              />
            </>
          ))}
        </S.TestimonialsCarousel>
        
     
      </>
    </S.Testimonials>
  );
}
