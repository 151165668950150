import styled from "styled-components";
import { MDBBtn } from "mdb-react-ui-kit";
export const HeadTextElement = styled.div`
  padding: 4%;
  padding: ${(props) => props.padding};
  padding-top: ${(props) => props.paddingTop};
  font-size: ${(props) => props.fS};

  margin-top: ${(props) => props.marginTop};
`;
export const H1 = styled.h1`
  text-align: center;
  color: whitesmoke;
  font-size: ${(props) => props.fS};

  margin: ${(props) => props.margin};
`;
export const H5 = styled.h5`
  font-size: ${(props) => props.fS};
  color:white;

  text-align: center;
`;
export const Span = styled.span`
  text-align: center;
  padding: 1%;
  border-radius:10px;
  background-color: #304454;
  color: #62C165;
  text-transform: capitalize;

  font-size: ${(props) => props.fS};
  margin: ${(props) => props.margin};
  &:hover{
  color: #fafafa;

  }
`;
export const ContentActual = styled.div`
  background-color: #D5E3E4;
  height: max-content;
  padding-bottom:1%;
  width: 100%;
  `;
// STYLES FOR THE MODAL
export const Modal = styled.div`
  position: fixed;
  z-index: 9;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgb(0, 8, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ModalContent = styled.div`
  position: fixed;
  z-index: 9;
  overflow: scroll;
  margin-top: 2%;
  height: 85vh;
  width: 100%;
  background-color: white;
  border-radius: 2%;
`;
export const ModalHeader = styled.div`
  /* padding: 4%; */
`;
export const ModalTitle = styled.h4`
  padding: 0;
`;
export const ModalBody = styled.div`
  display: flex;
  padding-left: 2%;
`;
export const ModalFooter = styled.div`
  margin-left: 35%;
`;
export const ModalBtn = styled(MDBBtn)`
  margin: 3%;
  background-color: #228b22;
  &:hover {
    background-color: #008000;
  }
  &:active {
    background-color: #32cd32;
  }
`;

// PROGRAM TABLE STYLES

export const ProgramTable = styled.div`
  max-width: 100%;
  margin-left: 0;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
`;
export const TableHeading = styled.h2`
  /* background: white;
  position: sticky;
  top: 2.5%; */
  font-size: 26px;
  margin: 0 auto;
  text-align: center;
`;
export const Small = styled.small`
  font-size: 0.5em;
`;
export const ResponsiveTable = styled.ul`
  font-size: 16px;
  margin: 0 auto;
  text-align: center;
`;
export const TableLi = styled.li`
  border-radius: 3px;
  padding: 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`;

export const TableHeader = styled(TableLi)`
  position: sticky;
  top: 0;
  background-color: #265854;
  color: white;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  width: 40pc;
  padding: 1%;
`;
export const TableRow = styled(TableLi)`
  font-size: 0.7em;
  background-color: #ffffff;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
`;

export const Col = styled.div`
  display: flex;
  align-items: center;
  /* flex-basis: 100%; */
  text-align: left;
  &:before {
    color: #6c7a89;
    padding-right: 1%;
    content: attr(data-label);
    /* flex-basis: auto; */
    text-align: left;
  }
`;
export const Col1 = styled(Col)`
  text-align: left;
  flex-basis: 10%;
`;
export const Col2 = styled(Col)`
  width: 60%;
  padding: 1%;
`;
export const TuitionMerge = styled(Col2)`
  display: flex;
  flex-direction: column;
`;
export const TuitionMergeExpand = styled(Col2)`
  display: flex;
  width: 60%;
  padding: 1%;
`;
export const Col3 = styled(Col)`
  flex-basis: 25%;
`;
export const Col4 = styled(Col)`
  width: 60%;
  padding: 1%;
`;
