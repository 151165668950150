import "./styles.css";

import React, { useEffect, useRef, useState } from "react";
import { MenuItem } from "./MenuItem";
import * as S from "./Menu.style";
import Toggle from "./Toggle";
function Menu(props) {
  let color = "white";
  let Bcolor = "transparent";
  if (props.scrolled) {
    color = "black";
    // Bcolor = "white";
  }
  const [active, setActive] = useState(false);
  const handleClick = () => {
    if (active) {
      setActive(false);
      // alert(active)
    } else {
      setActive(true);
      // alert(active)
    }
  };
  useEffect(() => {
    handleClick();
  }, []);

  let [isOverButton, setIsOverButton] = useState(false);
  let [isTouchInput, setIsTouchInput] = useState();
  let [hasClicked, setHasClicked] = useState();
  let button = useRef(null);

  useEffect(() => {
    setIsTouchInput(false);
    setHasClicked(false);
  }, [hasClicked]);

  function HandleMouseEnter(e) {
    setIsOverButton(true);
  }
  function CrossClass() {
    return `dropdown-content ${isOverButton ? "onIt" : ""} `;
  }
  const [navbarOpen, setNavbarOpen] = useState(false);

  const handleToggle = () => {
    setNavbarOpen(!navbarOpen);
  };
  useEffect(() => {
    setNavbarOpen(false);
  })
  return (
    <>
      <S.MenuLayout isEkScreen={props.isEkScreen}>
        <S.NavLinkLayout
          className={window.location.pathname == '/'  ? 'menu__item menu__active' : 'menu__item'}
          style={{
            marginTop: "-20px",
            paddingRight: "-20px",
            fontWeight: 500,
            background: `${Bcolor}`,
            color: `${color}`,
          }}
          to="/"
          scrolled={props.scrolled}
          width="190px"
          // onClick={handleClick}
        >
          Home
        </S.NavLinkLayout>
        <div className="right-menuT">
          <button
            style={{
              marginTop: "-22px",

              color: `${color}`,
            }}
            className= {
              
              window.location.pathname == "/MnV" ? ' menu-button menu__item menu__active' : 'menu-button menu__item'}

          >
            about rhibms
          </button>
          <div className={`my-dropdown-menu ${navbarOpen ? "testDis" : ""}`}>
            <S.NavLinkLayout to="/MnV" onClick={handleToggle}>Mission & Vision</S.NavLinkLayout>
            <S.NavLinkLayout to="/Gallery" onClick={handleToggle}>Gallery</S.NavLinkLayout>
            <S.NavLinkLayout to="/FAQ" onClick={handleToggle}>FAQ</S.NavLinkLayout>
          </div>
        </div>
        <div className="right-menuT">
          <button
            className={window.location.pathname == "/Admissions"  ? 'menu-button menu__item menu__active' : 'menu-button menu__item'}
            style={{
              marginTop: "-20px",

              paddingRight: "-20px",
              color: `${color}`,
            }}
          >
            Admissions
          </button>
          <div className={`my-dropdown-menu ${navbarOpen ? "testDis" : ""}`}>
            <S.NavLinkLayout to="Admissions" onClick={handleToggle}>Admission requirements</S.NavLinkLayout>
            <S.NavLinkLayout
              to={{
                pathname: "/Admissions",
                // state: {
                //   fromAdmissions: true,
                // },
              }}
              onClick={handleToggle}
            >
              Admission Guidelines
            </S.NavLinkLayout>
          </div>
        </div>
        <div className="right-menuT">
          <button
            style={{
              marginTop: "-20px",

              color: `${color}`,
            }}
            className={window.location.pathname == "/OnCampus"  ? 'menu-button menu__item menu__active' : 'menu-button menu__item'}
          >
            Oncampus
          </button>
          <div className={`my-dropdown-menu ${navbarOpen ? "testDis" : ""}`}>
            <S.NavLinkLayout to="OnCampus" onClick={handleToggle}>Clubs & Associations</S.NavLinkLayout>
          </div>
        </div>
        <div className="right-menuT">
          <button
            style={{
              marginTop: "-20px",

              color: `${color}`,
            }}
            className={window.location.pathname == "/Programs"  ? 'menu-button menu__item menu__active' : 'menu-button menu__item'}

          >
            academics
          </button>
          <div className={`my-dropdown-menu ${navbarOpen ? "testDis" : ""}`}>
            <S.NavLinkLayout to="Programs" onClick={handleToggle}>schools</S.NavLinkLayout>
            <S.NavLinkLayout to="Office-of-Academic-Affairs" onClick={handleToggle}>
              office of academic affairs
            </S.NavLinkLayout>
            <S.NavLinkLayout to="Office-of-Student-Affairs" onClick={handleToggle}>
              office of student affairs
            </S.NavLinkLayout>
          </div>
        </div>
        
        <S.NavLinkLayout
          className={window.location.pathname == "/MainNews"  ? 'menu__item menu__active' : 'menu__item'}
          style={{
            marginTop: "-20px",
            fontWeight: 500,
            
            color: `${color}`,
          }}
          to="/MainNews"
          scrolled={props.scrolled}
          width="190px"
          // active={window.location.pathname == "/MainNews" ? "red" : ""}
        >
          NEWS
        </S.NavLinkLayout>
        <S.NavLinkLayout
          className={window.location.pathname == "/MainEvents"  ? 'menu__item menu__active' : 'menu__item'}
          style={{
            marginTop: "-20px",
            fontWeight: 500,
            
            color: `${color}`,
          }}
          to="/MainEvents"
          scrolled={props.scrolled}
          width="190px"
          // active={window.location.pathname == "/MainNews" ? "red" : ""}
        >
          Events
        </S.NavLinkLayout>
        <S.NavLinkLayout
          className={window.location.pathname == "/Contact"  ? 'menu__item menu__active' : 'menu__item'}
          style={{
            marginTop: "-20px",
            fontWeight: 500,
           
            color: `${color}`,
          }}
          to="/Contact"
          scrolled={props.scrolled}
          width="190px"
          // active={window.location.pathname === "/" ? "red" : ""}
        >
          CONTACT
        </S.NavLinkLayout>
      </S.MenuLayout>
{/* NOt TO BE DISPLAYED FOR TABLET */}
      {/* <S.MenuForOther>
        <Toggle scrolled={props.scrolled} />
      </S.MenuForOther> */}
    </>
  );
}

export default Menu;
