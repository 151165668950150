import styled from "styled-components";
import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { device } from "../../../AppLayout.style";

export const MenuLayout = styled.div`

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 10%;

    position: fixed;
    width: 788px;
    height: 18px;
    right: 17%;
    top: 22px;
  
`;

export const LinkLayout = styled(Link)`
  padding-right: 20px;
  padding-right: ${(props) => props.pR};
  width: fit-content;
  width: ${(props) => props.width};
  color:inherit;
  color: ${(props) => props.color};
  text-decoration: inherit;
  transition: 0.1s all ease-in;
  user-select: none;
  font-family: montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 300;
  font-weight: ${(props) => props.fW};
background-color: ${props => props.active};
  /* font-size: 13px; */
    font-size: .9rem;
    
  text-transform: uppercase;

  &:hover {
    font-weight: 500;
    color: inherit;
  }
`;
export const NavLinkLayout = styled(LinkLayout)`
    font-size: 1rem;
    

    &:hover {
    font-weight: 700;
    
  }
`
export const CloseToggle = styled(FaTimes)`
  color: #fff;
  top: 5%;
  right: 4%;
  background: #222;
  padding: 0.75rem;
  display: flex;
  place-items: center;
  font-size: 2rem;
  cursor: pointer;
`;

export const MenuForOther = styled.div``;

