import React, { Component } from "react";
import { Map, GoogleApiWrapper } from "google-maps-react";

const mapStyles = {
  width: "64vw",
  height: "60%",
  marginTop: "2%",
  marginRight: "2%",
  borderRadius: "6px",
  border: "1px ridge green",
  textAlign: "center",
  paddingTop: "14%",
  boxShadow:
    "rgba(0, 0, 0, 0.05) 0px 0px 0px 2px, rgb(209, 213, 219) 0px 0px 0px 1px inset",
};

export class MapContainer extends Component {
  render() {
    return (
      <Map
        google={this.props.google}
        zoom={14}
        style={mapStyles}
        initialCenter={{
          lat: 4.155966,
          lng: 9.263224,
        }}
      />
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyANk0BJi79zKF9IeUadNmzzVd4wvuetbNc",
})(MapContainer);
