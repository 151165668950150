import styled from "styled-components";

export const MainContactLayout = styled.div`
  font-family: Montserrat;
  color: "black";
  height: max-content;
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-color: white;
`;

export const MainContactHeaderLayout = styled.div`
  height: 45vh;
  margin-left: 0;
  background-image: url(${(props) => props.bg});
`;
export const EnterMessageAndMapLayout = styled.div`
display: flex;
  `;