import { DateCardLayout, DayLayout, MonthLayout } from "./DateCard.style";

function DateCard(props) {
    return ( <DateCardLayout>
        <MonthLayout>
            {props.month}
        </MonthLayout>
        <DayLayout>
            {props.date}
        </DayLayout>
    </DateCardLayout> );
}

export default DateCard;