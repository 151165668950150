import styled from "styled-components";
import {
  FaMailchimp,
  FaFacebook,
  FaTwitter,
  FaWhatsapp,
  FaLinkedin,
} from "react-icons/fa";
export const ShareLayout = styled.div``;
export const ShareText = styled.h5`
  text-transform: uppercase;
`;
export const IconRow = styled.div`
  display: flex;
  height: 10vh;
  width: 100%;
`;
export const FacebookIcon = styled(FaFacebook)`
  padding: 1%;
  padding-top: 0;
  height: 30%;
  width: 30%;
  &:hover {
    cursor: pointer;
    color: #267854;
  }
`;
export const TwitterIcon = styled(FaTwitter)`
  padding: 1%;
  padding-top: 0;
  height: 30%;
  width: 30%;
  &:hover {
    cursor: pointer;
    color: #267854;
  }
`;
export const WhatsAppIcon = styled(FaWhatsapp)`
  padding: 1%;
  padding-top: 0;
  height: 30%;
  width: 30%;
  &:hover {
    cursor: pointer;
    color: #267854;
  }
`;
export const LinkedInIcon = styled(FaLinkedin)`
  padding: 1%;
  padding-top: 0;
  height: 30%;
  width: 30%;
  &:hover {
    cursor: pointer;
    color: #267854;
  }
`;
export const MailchimpIcon = styled(FaMailchimp)`
  padding: 1%;
  padding-top: 0;
  height: 30%;
  width: 30%;
  &:hover {
    cursor: pointer;
    color: #267854;
  }
`;

function Share() {
  return (
    <ShareLayout>
      <ShareText>Share</ShareText>
      <IconRow>
        <FacebookIcon />
        <TwitterIcon />
        <WhatsAppIcon />
        <LinkedInIcon />
        <MailchimpIcon />
      </IconRow>
    </ShareLayout>
  );
}

export default Share;
