import styled from "styled-components";
import { Link } from "react-router-dom";
export const FooterAreaLayout = styled.div`
  padding: 1%;
  color: white;
  width: 100%;
  background: #304454;
`;

export const FooterOverlay = styled.div`
  height: fit-content;
  width: 100%;
  opacity: 0.8;
  background: #304454;
`;
export const HelpfulLinksLeftLayout = styled.div``;

export const HelpfulLinksMidLayout = styled.div``;

export const HelpfulLinksRightLayout = styled.div``;

export const HelpfulLinksText = styled.h4`
  color: white;
  font-size: 0.875rem;
  line-height: 1.21428571;
  font-family: Mallory-Medium, "Gill Sans", "Gill Sans MT", Calibri, Arial,
    sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.75px;
  word-spacing: 1.25px;
  text-transform: uppercase;
  &:after {
    display: block;
    width: 40px;
    height: 1px;
    background-color: whitesmoke;
    content: "";
    margin-top: 16px;
  }
`;
export const HelpfulLink = styled.span`
  position: absolute;
  width: 33.33333333%;
  height: 60vh;
  padding-top: 20px;
  /* float: left; */
`;

export const ImgLogoFooter = styled.div`
  border: 0.5px outset #fff;
  position: relative;
  height: 40vh;
  width: 20vw;
  top: 20px;
  right: 190px;
  border-radius: 10px;
  &:hover {
    cursor: pointer;
    box-shadow: 2px 2px 2px 2px #fff;
    transition: ease-in-out 9ms;
  }
`;

export const SectionInFooter = styled.div`
  text-align: left;
  background: transparent;
  
  margin: 2%;
  height: fit-content;
  width: 30vw;
  top: 20px;
  right: 190px;
  border-radius: 10px;
  &:hover {
    background:rgba(151,171,167,.05);

    transition: ease-in-out 10ms;
  }
`;
