import styled from "styled-components";

export const FeaturedLayout = styled.div`
  margin-top: 1%;
  padding: 2%;
  display: ${(props) => props.display};
`;
export const FeaturedMobileLayout = styled.div`
  margin-top: 2%;
  padding: 2%;
  display: ${(props) => props.display};
  height: fit-content;
`;
export const FeaturedMobileHead1 = styled.h2`
  color: #265854;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;
  font-size: 0.8125rem;
  font-weight: 700;
  vertical-align: baseline;
`;

export const FeaturedMobileHead2 = styled.h3`
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-transform: capitalize;
  text-align: center;
  letter-spacing: 2px;
  font-size: 1.8125rem;
  font-weight: 800;
  vertical-align: baseline;
`;
