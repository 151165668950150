/**
 * The RHIBMS hero
 * @params - none
 */
import { useEffect, useState } from "react";
import { HeroLayout, BigTextLayout, MajorText, MinorText, BtnGroup } from "./Hero.style";
import { MDBBtn } from "mdb-react-ui-kit";
import Typewriter from "typewriter-effect";
import "animate.css";
import MDBCardInHero from "./MDBCardInHero";

import { LinkLayout } from "../NavigationBar/Menu.style";

function Hero() {

  const [loading, setLoading] = useState(true);

  return (
    <HeroLayout>
      
        <BigTextLayout className="animate__animated animate__bounce">
          <MajorText>
            University <br />and Training center
          </MajorText>
          <MinorText>
            training of skilled personnel in various disciplines more
            particularly Nurses and <br />
            Laboratory Studies.
          </MinorText>
          <BtnGroup>
              <LinkLayout pR="0"  to="/">
                <MDBBtn
                  style={{
                    width: "15rem",
                    fontSize: 1 + "rem",
                    fontWeight: 500,
                    lineHeight: 1.1,
                    padding: "15px",
                    backgroundColor: "#098639",
                    // border: "1px solid #188c5a",
                    color: "#fafafa",
                  }}
                >
                    Apply Now
                </MDBBtn>
              </LinkLayout>
              <LinkLayout pR="0"  to="/join-classroom">
                <MDBBtn
                  style={{
                    marginLeft: "2%",
                    width: "19rem",
                    fontSize: 1 + "rem",
                    fontWeight: 500,
                    lineHeight: 1.1,
                    padding: "15px",
                    backgroundColor: "rgba(2220,220,220, 0.4)",
                    border: "1px solid gainsboro",
                    color: "#000",
                    "box-shadow": "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  }}
                >
                    Join the Classroom
                </MDBBtn>
              </LinkLayout>
          </BtnGroup>
        </BigTextLayout>
   

      <div
        style={{
          backgroundPosition: "center -100px",
          filter: "blur(7px)",
          position: "absolute",
          marginLeft: 38 + "%",
          top: 60 + "%",
          maxWidth: "22rem",
        }}
        className="blur"
      ></div>
    </HeroLayout>
  );
}

export default Hero;
