import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { useMediaQuery } from "react-responsive";
import * as E from "./Elements/Elements.style";
import HeadTextElement from "./Elements/HeadTextElement";
import { MnVLayout, MnVHeaderLayout } from "./MainAbout.styles";
import { BackCardGuIn } from "../AdmissionPageComponents/MainAdmission.styles";
import { Gradient } from "../../AppLayout.style";
import Footer from "../LandingPageComponents/Footer/Footer";
function MnVWrapper(props) {


  return <div><MnV /></div>;
}
export default MnVWrapper;

function MnV() {
  const imagePath = "assets/img/requirementsPics/tinified/_DSC0007.JPG";
  const aboutedu = "assets/img/aboutedu.svg";
  const imageAbout = "assets/img/requirementsPics/_DSC0012.JPG";

  const EachNewsForDisPage = () => {
    return (
      <div
        style={{
          marginLeft: -1.3,
          width: 340.8 + "px",
          height: "9vh",
          backgroundColor: "whitesmoke",
          fontWeight: 500,
          padding: "10px",
          borderRadius: "16px",
        }}
        className="EachNews"
      >
        Identifying drug-resistant tuberculosis strains in Moldova
        <p
          style={{
            fontSize: "9pt",
            fontWeight: 300,
          }}
        >
          July 27, 2022
        </p>
      </div>
    );
  };
  return (
    <MnVLayout bg={aboutedu}>
      <MnVHeaderLayout bg={imagePath}>
        <Gradient>
          <E.HeadTextElement>
            <HeadTextElement spanMargin="0 auto" title="Mission and Vision" />
          </E.HeadTextElement>
        </Gradient>
      </MnVHeaderLayout>

      <MDBRow
        style={{
          textAlign: "center",
          padding: "4%",
          height: "50%",
        }}
      >
        <MDBCol
          style={{
            backgroundColor: "white",
            textAlign: "center",
            height: "auto",
            borderRadius: "6px",
            padding: "5%",
          }}
          md="8"
          className="col-example"
        >
          <h2
            style={{
              textAlign: "center",
              fontWeight: 500,
              letterSpacing: 3 + "px",
            }}
          >
            Our Vision
          </h2>
          <p style={{ textAlign: "center" }}>
            To become a world-leading training and research university whose
            products, research findings and public engagements shall
            significantly improve the quality of life in Cameroon and
            Sub-Saharan Africa.
          </p>
          <BackCardGuIn
            height="60vh"
            width="100%"
            bg={imageAbout}
            hoverBg={imageAbout}
            // blurOnHover="0.2px"
          />

          <h2
            style={{
              textAlign: "center",
              fontWeight: 500,
              letterSpacing: 3 + "px",
              marginTop: "2%",
            }}
          >
            Our Mission
          </h2>
          <p style={{ textAlign: "center" }}>
            To undertake excellent research, teaching and public engagement in
            the service of local, regional, national and global communities.
          </p>
        </MDBCol>
        <MDBCol md="4" className="col-example">
          <BackCardGuIn padding="2%" marginTop="7px" height="100vh" width="30vw">
            <h2
              style={{
         
                textAlign: "center",
              }}
            >
              Recent News
            </h2>
            <p style={{  textAlign: "center" }}>
              RHIBMS, established in 2010 as a training center for Nursing
              Assistants under the ministry of Public Health.
            </p>
            <hr
              style={{
                textAlign: "center",
                color: "grey",
                fontWeight: 600,
              }}
            />
            <div
              style={{
                display: "block",
                textAlign: "center",
                marginLeft: 38.8,
              }}
              className="AllNewsHere"
            >
              <EachNewsForDisPage /> <br />
              <EachNewsForDisPage /> <br />
              <EachNewsForDisPage /> <br />
              <EachNewsForDisPage /> <br />
              <EachNewsForDisPage /> <br />
            </div>
          </BackCardGuIn>
        </MDBCol>
      </MDBRow>

      <Footer top="1400px" />
    </MnVLayout>
  );
}

function MnVMobile() {
  const imagePath = "assets/img/approach-rhibms.PNG";
  const aboutedu = "assets/img/aboutedu.svg";
  const imageAbout = "assets/img/requirementsPics/_DSC0081.jpg";

  const EachNewsForDisPage = () => {
    return (
      <div
        style={{
          marginLeft: -1.3,
          width: 100 + "%",
          height: "auto",
          backgroundColor: "whitesmoke",
          fontWeight: 500,
          padding: "1%",
          borderRadius: "6px",
        }}
        className="EachNews"
      >
        Identifying drug-resistant tuberculosis strains in Moldova
        <p
          style={{
            fontSize: "9pt",
            fontWeight: 300,
          }}
        >
          July 27, 2022
        </p>
      </div>
    );
  };
  return (
    <MnVLayout bg={aboutedu}>
      <MnVHeaderLayout height="20vh" bg={imagePath}>
        <Gradient>
          <E.HeadTextElement paddingTop="12%" >
            <HeadTextElement fS = "1.4em" spanMargin="0 auto" title="Mission and Vision" />
          </E.HeadTextElement>
        </Gradient>
      </MnVHeaderLayout>

      <MDBRow
        style={{
          textAlign: "center",
          padding: "2%",
          height: "auto",
        }}
      >
        <MDBCol
          style={{
            // margin:"2%",
            backgroundColor: "white",
            textAlign: "center",
            height: "fit-content",
            borderRadius: "6px",
          }}
          md="8"
          className="col-example"
        >
          <h2
            style={{
              textAlign: "center",
              fontWeight: 500,
              letterSpacing: 1 + "px",
            }}
          >
            Our vision
          </h2>
          <p style={{ textAlign: "center" }}>
            To become a world-leading training and research university whose
            products, research findings and public engagements shall
            significantly improve the quality of life in Cameroon and
            Sub-Saharan Africa.
          </p>
          <BackCardGuIn
            
            height="40%"
            bg={imageAbout}
          />

          <h2
            style={{
              marginTop:"1%",
              textAlign: "center",
              fontWeight: 500,
              letterSpacing: 1 + "px",
            }}
          >
            Our Mission
          </h2>
          <p>
            To undertake excellent research, teaching and public engagement in
            the service of local, regional, national and global communities.
          </p>
        </MDBCol>
        <MDBCol md="4" className="col-example">
          <BackCardGuIn padding="2%" marginTop="2%" height="auto" width="auto">
            <h2
              style={{
                
                textAlign: "center",
              }}
            >
              Recent News
            </h2>
            <p style={{  textAlign: "center" }}>
              RHIBMS, established in 2010 as a training center for Nursing
              Assistants under the ministry of Public Health.
            </p>
            <hr
              style={{
                textAlign: "center",
               
                color: "grey",
                fontWeight: 600,
              }}
            />
            <div
              style={{
                display: "block",
                textAlign: "center",
              }}
              className="AllNewsHere"
            >
              <EachNewsForDisPage /> <br />
              <EachNewsForDisPage /> <br />
              <EachNewsForDisPage /> <br />
              <EachNewsForDisPage /> <br />
              <EachNewsForDisPage /> <br />
            </div>
          </BackCardGuIn>
        </MDBCol>
      </MDBRow>

      <Footer top="1400px" />
    </MnVLayout>
  );
}
