// Remote Imports
import { MDBRow, MDBCol, MDBCard, MDBCardImage } from "mdb-react-ui-kit";
import {
  FaHeart,
  FaFacebook,
  FaTwitter,
  FaWhatsapp,
  FaCaretRight,
} from "react-icons/fa";
import { MdLocationPin, MdOutlineCall, MdMail } from "react-icons/md";
// Local Imports
import {
  FooterAreaLayout,
  ImgLogoFooter,
  SectionInFooter,
} from "./FooterArea.styled";
import HelpfulLinksLeft from "./HelpfulLinksLeft";
import HelpfulLinksMid from "./HelpfulLinksMid";
import HelpfulLinksRight from "./HelpfulLinksRight";
import logo from "./../../../../../assets/img/logo.png";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

const FooterMobileHead1 = styled.h2`
  color: #265854;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;
  font-size: 0.8125rem;
  font-weight: 700;
  vertical-align: baseline;
`;
const FooterMobileHead2 = styled.h3`
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-transform: capitalize;
  text-align: center;
  letter-spacing: 2px;
  font-size: 1.8125rem;
  font-weight: 800;
  vertical-align: baseline;
`;

function FooterWrapper() {
  
  return (
    <div>
     <FooterArea />
    </div>
  );
}
export default FooterWrapper;
function FooterArea({ isDesktop }) {
  return (
    <FooterAreaLayout>
      <div
        style={{
          justifyContent: "center",
          display: "flex",
          textAlign: "center",
        }}
      >
        <SectionInFooter>
          <div
            style={{
              marginLeft: "5%",
              color: "white",
              display: "flex",
            }}
          >
            <MDBCard
              style={{
                borderRadius: "50%",

                height: "4rem",
                width: "4rem",
                boxShadow: "inset 5px 7px 7px 5px #009948",
              }}
            >
              <MDBCardImage
                style={{
                  borderRadius: "50%",
                  height: "4rem",
                  width: "4rem",
                  objectFit: "cover",
                }}
                position="top"
                alt="..."
                src={logo}
              />
            </MDBCard>
            <h4
              style={{
                marginLeft: "5%",
                marginTop: "7%",
                textTransform: "uppercase",
                fontWeight: 700,
                color: "white",
              }}
            >
              RHIBMS
            </h4>
          </div>
          <p style={{ marginTop: "3%", marginLeft: "5%" }}>
            The Redemption Higher Institute of Biomedical and Management Science
            (RHIMBS) established in 2010 as a training center for Nursing
            Assistants under the ministry of Public Health. Its mission was to
            train nurses in the professional field.
          </p>
        </SectionInFooter>
        <SectionInFooter>
          <h4
            style={{
              marginLeft: "5%",
              marginTop: "7%",
              textTransform: "uppercase",
              fontWeight: 700,
              color: "white",
            }}
          >
            Contact Info{" "}
          </h4>
          <p style={{ marginLeft: "5%", marginTop: "3%" }}>
            <span style={{ display: "flex", paddingTop: "1%" }}>
              <MdLocationPin
                style={{ marginTop: "1.1%" }}
                size={40}
                color="#06C969"
              />{" "}
              <span style={{ marginLeft: "1%" }}>
                Tarred Malingo - Buea, Molyko, - Buea, Molyko,
              </span>{" "}
            </span>{" "}
            <span style={{ display: "flex", paddingTop: "1%" }}>
              <MdOutlineCall
                style={{
                  marginTop: "6.9%",
                  verticalAlign: "middle",
                  lineHeight: "50px",
                }}
                size={35}
                color="#06C969"
              />{" "}
              <span style={{ marginLeft: "1%" }}>
                +237 681 019 578 <br />
                +237 233 324 850 <br /> +237 677 172 022
              </span>{" "}
            </span>{" "}
            <span style={{ display: "flex", paddingTop: "1%" }}>
              <MdMail
                style={{ marginTop: "-1.1%" }}
                size={30}
                color="#06C969"
              />{" "}
              <span style={{ verticalAlign: "middle", marginLeft: "1%" }}>
                info@rhibms.com
              </span>{" "}
            </span>{" "}
          </p>
        </SectionInFooter>
        <SectionInFooter>
          <h4
            style={{
              marginLeft: "5%",
              marginTop: "7%",
              textTransform: "uppercase",
              fontWeight: 700,
              color: "white",
            }}
          >
            Our Offices
          </h4>
          <p style={{ marginLeft: "5%", marginTop: "3%" }}>
            <span style={{ display: "flex", marginLeft: "" }}>
              <FaCaretRight
                style={{ marginTop: "-1.1%" }}
                size={30}
                color="#06C969"
              />{" "}
              <span style={{ marginLeft: "1%" }}>Office of the VC</span>{" "}
            </span>{" "}
            <span style={{ display: "flex", marginLeft: "" }}>
              <FaCaretRight
                style={{ marginTop: "-1.1%" }}
                size={30}
                color="#06C969"
              />{" "}
              <span style={{ marginLeft: "1%" }}>Office of the Registrar</span>{" "}
            </span>{" "}
            <span style={{ display: "flex", marginLeft: "" }}>
              <FaCaretRight
                style={{ marginTop: "-1.1%" }}
                size={30}
                color="#06C969"
              />{" "}
              <span style={{ verticalAlign: "middle", marginLeft: "1%" }}>
                Office of Student Affairs
              </span>{" "}
            </span>{" "}
            <span style={{ display: "flex", marginLeft: "" }}>
              <FaCaretRight
                style={{ marginTop: "-1.1%" }}
                size={30}
                color="#06C969"
              />{" "}
              <span style={{ verticalAlign: "middle", marginLeft: "1%" }}>
                Office of Academic Affairs
              </span>{" "}
            </span>{" "}
          </p>
        </SectionInFooter>
        <SectionInFooter>
          <h4
            style={{
              marginLeft: "5%",
              marginTop: "7%",
              textTransform: "uppercase",
              fontWeight: 700,
              color: "white",
            }}
          >
            Opening Hours
          </h4>
          <span style={{ display: "flex", marginLeft: "5%" }}>
            <span style={{ verticalAlign: "middle", marginLeft: "1%" }}>
              Mon - Sat
            </span>{" "}
            <span style={{ textAlign: "right", marginLeft: "20%" }}>
              09 am - 05 pm
            </span>{" "}
          </span>
          <hr />
          <span style={{ marginLeft: "5%" }}>
            Sunday <strong style={{ marginLeft: "25%" }}>closed</strong>
          </span>
          <hr />
        </SectionInFooter>
      </div>
      

      <hr style={{ padding:"0"}}/>
      <p style={{ paddingBottom:"-2px",textAlign: "center" }}>
        Copyright © {new Date().getFullYear()} RHIBMS University º All Rights
        Reserved
        <FaFacebook
          style={{
            marginLeft: 4 + "pc",
          }}
        />{" "}
        <FaTwitter
          style={{
            marginLeft: 15 + "px",
          }}
        />{" "}
        <FaWhatsapp
          style={{
            marginLeft: 15 + "px",
          }}
        />{" "}
      </p>
    </FooterAreaLayout>
  );
}

function FooterAreaMobile() {
  return (
    <FooterAreaLayout>
        <div
        style={{
          justifyContent: "center",
          padding:"1%",
          textAlign: "center",
        }}
      >
        <SectionInFooter style={{
              width: "100%",
              
            }}>
          <div
            style={{
              marginLeft: "5%",
              color: "white",
              display: "flex",
            }}
          >
            <MDBCard
              style={{
                borderRadius: "50%",

                height: "4rem",
                width: "4rem",
                boxShadow: "inset 5px 7px 7px 5px #009948",
              }}
            >
              <MDBCardImage
                style={{
                  borderRadius: "50%",
                  height: "4rem",
                  width: "4rem",
                  objectFit: "cover",
                }}
                position="top"
                alt="..."
                src={logo}
              />
            </MDBCard>
            <h4
              style={{
                marginLeft: "5%",
                marginTop: "7%",
                textTransform: "uppercase",
                fontWeight: 700,
                color: "white",
              }}
            >
              RHIBMS
            </h4>
          </div>
          <p style={{ marginTop: "3%", marginLeft: "5%" }}>
            The Redemption Higher Institute of Biomedical and Management Science
            (RHIMBS) established in 2010 as a training center for Nursing
            Assistants under the ministry of Public Health. Its mission was to
            train nurses in the professional field.
          </p>
        </SectionInFooter>
        <SectionInFooter style={{
              width: "100%",
              
            }}>
          <h4
            style={{
              marginLeft: "5%",
              marginTop: "7%",
              textTransform: "uppercase",
              fontWeight: 700,
              fontSize:"1rem",
              color: "white",
            }}
          >
            Contact Info{" "}
          </h4>
          <p style={{ marginLeft: "5%", marginTop: "3%" }}>
            <span style={{ display: "flex", paddingTop: "1%" }}>
              <MdLocationPin
                style={{ marginTop: "1.1%" }}
                size={40}
                color="#06C969"
              />{" "}
              <span style={{ marginLeft: "1%" }}>
                Tarred Malingo - Buea, Molyko, - Buea, Molyko,
              </span>{" "}
            </span>{" "}
            <span style={{ display: "flex", paddingTop: "1%" }}>
              <MdOutlineCall
                style={{
                  marginTop: "6.9%",
                  verticalAlign: "middle",
                  lineHeight: "50px",
                }}
                size={35}
                color="#06C969"
              />{" "}
              <span style={{ marginLeft: "1%" }}>
                +237 681 019 578 <br />
                +237 233 324 850 <br /> +237 677 172 022
              </span>{" "}
            </span>{" "}
            <span style={{ display: "flex", paddingTop: "1%" }}>
              <MdMail
                style={{ marginTop: "-1.1%" }}
                size={30}
                color="#06C969"
              />{" "}
              <span style={{ verticalAlign: "middle", marginLeft: "1%" }}>
                info@rhibms.com
              </span>{" "}
            </span>{" "}
          </p>
        </SectionInFooter>
        <SectionInFooter style={{
              width: "100%",
              
            }}>
          <h4
            style={{
              marginLeft: "5%",
              marginTop: "7%",
              textTransform: "uppercase",
              fontSize:"1rem",
              fontWeight: 700,
              color: "white",
            }}
          >
            Our Offices
          </h4>
          <p style={{ marginLeft: "5%", marginTop: "3%" }}>
            <span style={{ display: "flex", marginLeft: "" }}>
              <FaCaretRight
                style={{ marginTop: "-1.1%" }}
                size={30}
                color="#06C969"
              />{" "}
              <span style={{ marginLeft: "1%" }}>Office of the VC</span>{" "}
            </span>{" "}
            <span style={{ display: "flex", marginLeft: "" }}>
              <FaCaretRight
                style={{ marginTop: "-1.1%" }}
                size={30}
                color="#06C969"
              />{" "}
              <span style={{ marginLeft: "1%" }}>Office of the Registrar</span>{" "}
            </span>{" "}
            <span style={{ display: "flex", marginLeft: "" }}>
              <FaCaretRight
                style={{ marginTop: "-1.1%" }}
                size={30}
                color="#06C969"
              />{" "}
              <span style={{ verticalAlign: "middle", marginLeft: "1%" }}>
                Office of Student Affairs
              </span>{" "}
            </span>{" "}
            <span style={{ display: "flex", marginLeft: "" }}>
              <FaCaretRight
                style={{ marginTop: "-1.1%" }}
                size={30}
                color="#06C969"
              />{" "}
              <span style={{ verticalAlign: "middle", marginLeft: "1%" }}>
                Office of Academic Affairs
              </span>{" "}
            </span>{" "}
          </p>
        </SectionInFooter>
        <SectionInFooter style={{
              fontSize:"1rem",

              width: "100%",
              
            }}>
          <h4
            style={{
              marginLeft: "5%",
              marginTop: "7%",
              textTransform: "uppercase",
              fontWeight: 700,
              fontSize:"1rem",
              color: "white",
            }}
          >
            Opening Hours
          </h4>
          <span style={{ display: "flex", marginLeft: "5%" }}>
            <span style={{ verticalAlign: "middle", marginLeft: "1%" }}>
              Mon - Sat
            </span>{" "}
            <span style={{ textAlign: "right", marginLeft: "20%" }}>
              09 am - 05 pm
            </span>{" "}
          </span>
          <hr />
          <span style={{ marginLeft: "5%" }}>
            Sunday <strong style={{ marginLeft: "25%" }}>closed</strong>
          </span>
          <hr />
        </SectionInFooter>
      </div>
      

      {/* <hr style={{ padding:"0"}}/> */}
      <p style={{ paddingBottom:"-2px",textAlign: "center" }}>
        Copyright © {new Date().getFullYear()} RHIBMS University º<br/> All Rights
        Reserved
        <FaFacebook
          style={{
            marginLeft: 1 + "pc",
          }}
        />{" "}
        <FaTwitter
          style={{
            marginLeft: 12 + "px",
          }}
        />{" "}
        <FaWhatsapp
          style={{
            marginLeft: 15 + "px",
          }}
        />{" "}
      </p>
    </FooterAreaLayout>
  );
}
