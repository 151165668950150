import { FaArrowRight } from "react-icons/fa";

import { MDBCard, MDBCardImage } from "mdb-react-ui-kit";

import { useMediaQuery } from "react-responsive";
import "animate.css";
import styled from "styled-components";
import { Link } from "react-router-dom";

const WorkFlowMobileHead1 = styled.h2`
  color: #265854;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;
  font-size: 0.8125rem;
  font-weight: 700;
  vertical-align: baseline;
`;
const ApplyLink = styled.h4`
  &:hover {
    cursor: pointer;
  }
`;
const WorkFlowMobileHead2 = styled.h3`
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-transform: capitalize;
  text-align: center;
  letter-spacing: 2px;
  font-size: 1.8125rem;
  font-weight: 800;
  vertical-align: baseline;
`;

function WorkFlowWrapper(props) {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isEkScreen = useMediaQuery({
    query: "(min-width:908px)",
    query: "(max-width:1430px)",
  });
  const isTablet = useMediaQuery({
    query: "(max-width: 1224px)",
  });

  const isMobile = useMediaQuery({
    query: "(max-width: 786px)",
  });

  const isPortrait = useMediaQuery({
    query: "(orientation: portrait)",
  });

  const isRetina = useMediaQuery({
    query: "(max-resolution: 300dpi)",
  });
  const Images = [
    "https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTl8fGVkdWNhdGlvbiUyMGJsYWNrfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
    "https://images.unsplash.com/photo-1514416432279-50fac261c7dd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8ZWR1Y2F0aW9uJTIwYmxhY2slMjBkb2N0b3J8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
    "https://media.istockphoto.com/photos/african-scientist-medical-worker-or-tech-in-modern-laboratory-picture-id635767756?b=1&k=20&m=635767756&s=170667a&w=0&h=TMzDoirjhgXD6FHW9sDPdniFL0_DE8gIiFPhXPuunVk=",
    "https://images.unsplash.com/photo-1639741660848-a07ebe5e2ce0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8Z3JhZHVhdGV8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
  ];
  return (
    <div>
      {isMobile ? (
        <WorkFlowMobile Images={Images} />
      ) : (
        <WorkFlow isEkScreen={isEkScreen} Images={Images} />
      )}
    </div>
  );
}

export default WorkFlowWrapper;
function WorkFlow(props) {
  return (
    <>
      <div
        style={{
          padding: "1.2%",
          width: "100%",
          marginLeft: "0",
          textAlign: "center",
        }}
      >
        <h2
          style={{
            fontWeight: 600,
            textTransform: "uppercase",
            // color: "#265854",
          }}
        >
          Study flow
        </h2>
        <p
          style={{
            margin: "auto",
            width: "49rem",
            lineHeight: 30 + "px",
          }}
          className="bodyAbout"
        >
          Haven't yet submitted an application and need to connect? You can
          visit our Admissions page for opportunities to learn more before
          submitting your RHIBMS Apply application. You can also use the
          following information is a self-guide which will prepare you well for
          application to graduate education at RHIBMS! For admission purposes to
          RHIBMS, please apply to the appropriate category based on your dream
        </p>
      </div>

      <div
        style={{
          padding: "3%",
          marginLeft: "-1%",
          paddingBottom: "0",
          textAlign: "center",
          justifyContent: "center",
          display: "flex",
        }}
        className="circles"
      >
        <MDBCard
          style={{
            borderRadius: "50%",
            margin: "auto",
            marginRight: "1%",
            height: "18rem",
            width: "18rem",
          }}
        >
          <MDBCardImage
            style={{
              borderRadius: "50%",
              height: "18rem",
              width: "18rem",
              objectFit: "cover",
            }}
            position="top"
            alt="..."
            src={props.Images[0]}
          />
        </MDBCard>
        <FaArrowRight style={{ marginTop: "9%" }} size={"48px"} />

        <MDBCard
          style={{
            borderRadius: "50%",
            margin: "auto",
            marginRight: "1%",
            height: "18rem",
            width: "18rem",
          }}
        >
          <MDBCardImage
            style={{
              borderRadius: "50%",
              height: "18rem",
              width: "18rem",
              objectFit: "cover",
            }}
            position="top"
            alt="..."
            src={props.Images[1]}
          />
        </MDBCard>
        <FaArrowRight style={{ marginTop: "9%" }} size={"48px"} />

        <MDBCard
          style={{
            borderRadius: "50%",
            margin: "auto",
            marginRight: "1%",
            height: "18rem",
            width: "18rem",
          }}
        >
          <MDBCardImage
            style={{
              borderRadius: "50%",
              height: "18rem",
              width: "18rem",
              objectFit: "cover",
            }}
            position="top"
            alt="..."
            src={props.Images[2]}
          />
        </MDBCard>
        <FaArrowRight style={{ marginTop: "9%" }} size={"48px"} />
        <MDBCard
          style={{
            borderRadius: "50%",
            margin: "auto",
            height: "18rem",
            width: "18rem",
          }}
        >
          <MDBCardImage
            style={{
              borderRadius: "50%",
              height: "18rem",
              width: "18rem",
              objectFit: "cover",
            }}
            position="top"
            alt="..."
            src={props.Images[3]}
          />
        </MDBCard>
      </div>
      <div
        style={{
          marginLeft: "-1%",
          display: "flex",
        }}
      >
        <h4 style={{ marginTop: "4%", marginLeft: "11.3%" }}>
          Visit
          <p
            style={{
              fontSize: `${props.isEkScreen ? "0.8rem" : "auto"}`,
              textAlign: "center",
              marginTop: "7%",
              marginLeft: "-34.5%",
              width: "20rem",
            }}
          >
            Visiting our campus is the best way to experience RHIBMS. Schedule
            an individual tour or join an event, or visit the site online as you
            are doing now.
          </p>
        </h4>
        <h4
          style={{
            marginLeft: `${props.isEkScreen ? "1.3%" : "1.6%"}`,
            color: "#265854",
            marginTop: "4%",
          }}
        >
          <u>
            <Link to="/">Apply</Link>
          </u>

          <p
            style={{
              textAlign: "center",
              fontSize: `${props.isEkScreen ? "0.8rem" : "auto"}`,
              marginTop: "7%",
              marginLeft: "-34.5%",
              width: "20rem",
            }}
          >
            We know you’re busy so we’ve made our application process clear and
            easy to understand.Apply in just 5 mins.
          </p>
        </h4>
        <h4
          style={{
            textAlign: "center",
            marginTop: "4%",
            marginLeft: `${props.isEkScreen ? "-5%" : "-6%"}`,
          }}
        >
          Enroll
          <p
            style={{
              fontSize: `${props.isEkScreen ? "0.8rem" : "auto"}`,
              marginTop: "7%",
              width: "20rem",
            }}
          >
            Congratulations on being accepted to RHIBMS Buea campus! There are
            couple of steps to take to secure your spot.
          </p>
        </h4>
        <h4 style={{ marginTop: "4%", marginLeft: "10.5%" }}>
          Graduate
          <p
            style={{
              fontSize: `${props.isEkScreen ? "0.8rem" : "auto"}`,
              marginTop: "7%",
              marginLeft: "-25.5%",
              width: "20rem",
            }}
          >
            You've done it, you graduated and for that congratulations; Go for
            your dreams.
          </p>
        </h4>
      </div>
    </>
  );
}

function WorkFlowMobile({ Images }) {
  return (
    <>
      <div
        style={{
          padding: "2%",
          width: "100%",
          marginLeft: "0",
          textAlign: "center",
        }}
      >
        <WorkFlowMobileHead1>you can learn</WorkFlowMobileHead1>
        <WorkFlowMobileHead2>Our course flow</WorkFlowMobileHead2>
      </div>

      <div
        style={{
          textAlign: "center",
        }}
        className="circles"
      >
        <MDBCard
          style={{
            borderRadius: "50%",
            margin: "0 auto",
            height: "9rem",
            width: "9rem",
          }}
        >
          <MDBCardImage
            style={{
              borderRadius: "50%",
              height: "9rem",
              width: "9rem",
              objectFit: "cover",
            }}
            position="top"
            alt="..."
            src={Images[0]}
          />
        </MDBCard>
        <h4>Visit</h4>
        <p>
          Visiting our campus is the best way to experience RHIBMS. Schedule an
          individual tour or join an event, or visit the site online as you are
          doing now.
        </p>
        <MDBCard
          style={{
            borderRadius: "50%",
            margin: "0 auto",
            height: "9rem",
            width: "9rem",
          }}
        >
          <MDBCardImage
            style={{
              borderRadius: "50%",
              height: "9rem",
              width: "9rem",
              objectFit: "cover",
            }}
            position="top"
            alt="..."
            src={Images[1]}
          />
        </MDBCard>
        <Link to="/Admissions">
          <ApplyLink style={{ color: "blue" }}>
            <u>Apply</u>
          </ApplyLink>
        </Link>
        <p>
          We know you’re busy so we’ve made our application process clear and
          easy to understand.Apply in just 5 mins.
        </p>
        <MDBCard
          style={{
            borderRadius: "50%",
            margin: "0 auto",
            height: "9rem",
            width: "9rem",
          }}
        >
          <MDBCardImage
            style={{
              borderRadius: "50%",
              height: "9rem",
              width: "9rem",
              objectFit: "cover",
            }}
            position="top"
            alt="..."
            src={Images[2]}
          />
        </MDBCard>
        <h4>Enroll</h4>
        <p>
          Congratulations on being accepted to RHIBMS Buea campus! There are
          couple of steps to take to secure your spot.
        </p>
        <MDBCard
          style={{
            borderRadius: "50%",
            margin: "0 auto",
            height: "9rem",
            width: "9rem",
          }}
        >
          <MDBCardImage
            style={{
              borderRadius: "50%",
              height: "9rem",
              width: "9rem",
              objectFit: "cover",
            }}
            position="top"
            alt="..."
            src={Images[3]}
          />
        </MDBCard>
        <h4>Graduate</h4>
        <p>
          You've done it, you graduated and for that congratulations; Go for
          your dreams.
        </p>
      </div>
    </>
  );
}
