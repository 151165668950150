import styled from "styled-components";

export const LogoLayout = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 60px;
  height: 60px;
  margin-left: 38%;
  padding-top: 13%;
  &:hover {
    cursor: pointer;
  }
`;

export const RHIBMS = styled.h2`
  color: ${(props) => (props.scrolled ? "black" : "white")};
  font-weight: bolder;
  position: absolute;
  font-size: 1.38rem;
  left: 7%;
  top: 22%;
  text-align: center;
  text-transform: uppercase;
`;

export const University = styled.div`
  position: relative;
  font-size: 0.9rem;
  left: 4%;
  top: 6.5%;
  letter-spacing: 0.1ch;
  font-weight: 300px;
`;
