import styled from "styled-components";
import {
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
  MDBRow,
  MDBCol,
  MDBBtn,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";

export const YaMDBBtnLayout = styled(MDBBtn)`
  margin: 0 auto;
  margin-top: ${(props) => props.mobileMT};
  font-weight: 600;
  background-color: #207030;
  width: 100%;
  width: ${(props) => props.width};
  &:hover {
    background-color: #209030;
  }
`;

export const YaMDBBtnLinkLayout = styled(Link)`
  color: white;
  text-decoration: none;
  &:hover {
    color: whitesmoke;
  }
`;

export const YaEventsHeadingLayout = styled.h2`
  color: white;
  /* margin-top:-2%; */
  font-weight: 900;
  font-size: 2rem;
`;

export const YaEventsSubtitleLayout = styled.p`
  margin-bottom: 4%;
  letter-spacing: 13px;
`;
export const YaEventsContainerLayout = styled.div`
  background: #00bf63;
  height: fit-content;
  padding-top: 4%;
  width: 100%;
  margin: 0 auto;
  margin-top: 2%;
  color: white;
  text-align: center;
`;

export const OjageRow = styled(MDBRow)`
  margin-top: 2%;
  margin: 0 auto;
`;
export const OjageCol = styled(MDBCol)`
  margin: 0 auto;
  width: 25%;
  border-radius: 10px;
  box-shadow: #00bf63, -25px -25px 10px #2d302f;
  height: 67.6vh;
  margin-top: ${(props) => props.mobileMT};
`;

export const OjageCard = styled(MDBCard)`
  height: ${(props) => props.height};
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  &:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
      rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }
`;
export const OjageCardImage = styled(MDBCardImage)`
  height: inherit;
  height: ${(props) => props.height};
`;
export const OjageCardBody = styled(MDBCardBody)`
  margin: 0;
  color: ${(props) => props.color};
`;
export const OjageCardTitle = styled(MDBCardTitle)`
  color:#207030;
  color: ${(props) => props.color};
  &:hover{
    text-decoration:underline;
  }
`;
export const OjageCardText = styled(MDBCardText)`
  color: ${(props) => props.color};
  &:hover{
    color:#207030;
    text-decoration:underline;
  }
`;
export const OjageCardVenue = styled.span`
  position: relative;
  text-transform: uppercase;
  /* overflow: auto; */
  width: 20%;
  margin: 0 auto;
  top: -67px;
  top: ${(props) => props.top};
  right: -31.5%;
  right: ${(props) => props.right};
  background-color: #304454;
  color: white;
`;
/* OjageCardImage.defaultProps = {
  src: ${props => props.src},
}; */
