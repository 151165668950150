import styled, { keyframes } from "styled-components";
import { AiOutlineDown } from "react-icons/ai";

export const BannerLayout = styled.div`
  margin: 0 auto;
  height: 100vh;
`;

export const SharpOverlay = styled.div`
  /* margin: 0 auto; */
  position: absolute;
  /* top: -30px; */
  /* left: -190px; */

  width: 100%;
  top: ${props => props.MobileTop};
  left: ${props => props.MobileLeft};

  width: ${props => props.MobileWidth};
  z-index: 1;
  /* transform: rotate(170deg); */
  background: rgba(11, 59, 12, 0.55);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1.8px);
  -webkit-backdrop-filter: blur(1.8px);
  height: 100%;
  height: ${props => props.MobileHeight};
`;
const fadeSlideUp = keyframes`
  0% {
    opacity: 0.2;
    transform: translateY(4rem);
  }
  100% {
    opacity: 1;
    transform: none;
  }
`;
const pulse = keyframes`
    0% {
      opacity: 1;
      transform: none;
    }
    25% {
      opacity: .8;
      transform: scale(1);
    }
    50% {
      opacity: .5;
      transform: scale(.5);
    }
    75% {
      opacity: .8;
      transform: scale(.8);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  `;

export const AnimatedScrollCue = styled(AiOutlineDown)`
  color: white;
  margin-top: 45%;

  animation: ${fadeSlideUp} 1s 1s ease-out forwards,
    pulse 2s 3s ease-out infinite;
  opacity: 0;
`;
