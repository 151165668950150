import styled from "styled-components";
import { MDBBtn } from "mdb-react-ui-kit";
export const OAALayout = styled.div`
  font-family: Montserrat;
  color: "black";
  height: fit-content;
  background: white;
`;
export const OAAHeaderLayout = styled.div`
  height: 65vh;
  height: ${props => props.height};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-position: ${props => props.bP};
  margin-left: 0;
  margin-top:5%;
  background-image: url(${(props) => props.bg});
`;

export const OAAPop = styled.div`
  height: 30%;
  width: 50%;
  width: ${props => props.width};
  background: white;
  margin: 0 auto;
  margin-top: -12%;
  margin-top: ${props => props.mT};
  margin-bottom: 2%;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 6px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(0.2px);
  -webkit-backdrop-filter: blur(0.2px);
  border: 1px solid rgba(255, 255, 255, 0.06);
  border-bottom: 7px solid #265854;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
      rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
      rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  }
`;

export const OAAPopLead1 = styled.div`
  text-align: center;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.5em;
  font-size: ${props => props.fS};
  
  padding: 1%;
  padding-top: 8%;
  padding-top: ${props => props.pT};
`;
export const OAAPopLead2 = styled(OAAPopLead1)`
  font-size: 2.7em;
  font-size: ${props => props.fS};
  font-weight: 300;
  word-spacing: 8px;
  padding-top: 1%;
  &:after {
    content: ".";
    display: block;
    height: 1px;
    width: 200px;
    margin: 0px auto;
    text-indent: -9999px;
    border-top: 1px solid #265854;
  }
`;

export const OAAPopPara = styled.p`
  text-align: center;
  font-size: 1em;
  word-spacing: 4px;
  padding: 5%;
  padding: ${props => props.padding};
  padding-top:5%;
`;

export const OAAPopBtn = styled(MDBBtn)`
  margin: 3%;
  margin-left: 33%;
  margin-left: ${props => props.mL};
  text-transform: capitalize;
  text-transform: ${props => props.mTTrans};
  height: 4rem;
  height: ${props => props.mH};
  width: 15rem;
  font-weight: ${props => props.mFW};
  width: ${props => props.mW};
  color: black;
  border: 1px solid #265854;
  background: transparent;
  font-size: 1em;
  box-shadow:none;
  &:hover {
    background: #265854;
    background: ${props => props.mB};
    box-shadow:none;
  }
  &:focus {
    background: #265854;
  }
  &:active {
    background: #265854;
  }
`;
// Staff Duties Styling
export const StaffDuties = styled.div`
  background-color: whitesmoke;
  height: 45vh;
  height: ${props => props.height};
  width: 100%;
  margin-bottom: 12%;
`;
export const StaffDutiesLeadText = styled.div`
  text-align: center;
  padding: 5%;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 2.5em;
  font-size: ${props => props.fS};
`;
export const Bolded = styled.span`
  font-weight: 700;
`;
export const ListOfRoles = styled.div`
  display: flex;
  display:${props => props.display};
  align-content: space-around;
`;
export const ActualRole = styled.div`
  margin: 0 auto;
  height: 20vh;
  width: 14%;
  width: ${props => props.width};
  margin-bottom: ${props => props.mB};
  padding-top: ${props => props.pT};
  background: white;
  border-radius: 6px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(0.2px);
  -webkit-backdrop-filter: blur(0.2px);
  border: 1px solid rgba(255, 255, 255, 0.06);
  border-bottom: 3px solid #265854;

  &:hover {
    transition: height 0.8s;
    transition: color 0.1s;
    height: 27vh;
    border-radius: 3px;
    background: #265854;
    color: white;
    cursor: pointer;
  }
`;
export const ActualRoleImage = styled.div`
  text-align: center;
  background-color: gainsboro;
  background: url(${(props) => props.bg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 50%;
  width: 100%;
`;

export const RoleText = styled.h6`
  color: inherit;
  text-align: center;
  padding: 14%;
`;
