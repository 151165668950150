import styled from 'styled-components';

export const Beta = styled.div`
text-align: center;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
margin: 0 auto;
width: 80%;
height: 80%;
background-color: gold;
`;
export const BetaHead = styled.div`
text-transform: uppercase;
font-weight: 700;
color: brown;
`