import styled from "styled-components";
import { Link } from "react-router-dom";
import { MDBBtn } from "mdb-react-ui-kit";

export const TheNewsLayout = styled.div`
  display: flex;
  height: 80%;
  margin-top: 8%;
  font-family: montserrat;
`;
export const Sidebar = styled.div`
  position: sticky;
  top: 8%;
  padding: 1%;
  padding-top: 4%;
  background-color: transparent;
  width: 20%;
  height: 80vh;
  border-right: 1px green solid;
`;
export const MainSide = styled.div`
  /* margin-left: 5%; */
  width: 80%;
  width:${props => props.width};
  padding: 3%;
  padding-top: 4%;
`;
export const LinkMainNews = styled(Link)`
color:inherit;
&:hover{
  color:inherit;
}
`;
export const MainNewsBtn = styled(MDBBtn)`
margin-top:-4%;
margin-top:${props => props.mobileMT};
margin-bottom:1%;
background-color:#265854;
&:hover{
  background-color:#267854;
}
`;
// Inside Side Bar

export const NewsNav = styled.ul`
  text-align: left;
`;
export const NewsNavItem = styled.li`
  color: black;
  font-weight: 200;
  margin: 5% auto;
`;
export const NewsNavTitle = styled.div`
  text-transform: uppercase;
  color: inherit;
  &:hover {
    color: green;
  }
`;
export const NewsNavSubTitle = styled.p`
  text-transform: lowercase;
  color: inherit;
  font-weight: 500;
`;

// Inside MainSide

export const ImageMain = styled.div`
  background: url(${(props) => props.bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 60vh;
  height: ${(props) => props.height};
  margin: 0 auto;
`;
export const Headline = styled.h3`
  text-transform: capitalize;
  padding-top: 2%;
`;
export const RealContent = styled.p`
  color: inherit;
  width:inherit &:hover {
    color: green;
  }
`;
