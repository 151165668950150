import styled from "styled-components";
import { BsZoomIn } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import { device } from "../../AppLayout.style";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
export const MainAboutLayout = styled.div`
  background-color: aliceblue;
`;

export const MnVLayout = styled.div`
  font-family: Montserrat;
  color: "black";
  height: fit-content;
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-color: #f5f5f5;
`;

export const MnVHeaderLayout = styled.div`
  height: 45vh;
  height: ${(props) => props.height};
  margin-left: 0;
  background-image: url(${(props) => props.bg});
  background-position: top;
  background-size: cover;
`;

export const GalleryLayout = styled(MnVLayout)``;

export const GalleryHeaderLayout = styled(MnVHeaderLayout)``;

export const FAQLayout = styled(MnVLayout)`
  height: auto;
  background: white;
`;

export const QuestionSVG = styled.div`
position: absolute;
right: 0;
background: url("assets/img/Question.svg");
background-repeat: no-repeat;
height:40vh;
width:40%;

`;
export const HowCan = styled.h2`
  position: relative;
  margin-top: 18%;
  text-align: center;
  color: #304454;
  font-weight: 500;
`;

export const Form = styled.form`
  /* position: relative; */
  /* z-index: 1;
  top: 20%;
  top: ${(props) => props.top}; */
  /* right: 37%;
  right: ${(props) => props.right}; */
  /* border: 1px solid; */
  border-radius: 6px;
  margin: 0 auto;
  vertical-align: middle;
  /* padding-top:25%; */
  display: flex;
  /* align-items: center;
  justify-content: center; */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  /* background: rgba(255,255,255, 0.9); */
  color: black;
  /* Change width of the form depending if the bar is opened or not */
  width: 39rem;
  /* If bar opened, normal cursor on the whole form. If closed, show pointer on the whole form so user knows he can click to open it */
  width: ${(props) => props.mobileX};
  cursor: ${(props) => (props.barOpened ? "auto" : "pointer")};
  /* padding: 1rem; */
  height: 3.3rem;
  transition: width 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
`;

export const Input = styled.input`
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  line-height: 1;
  /* background-color: transparent; */
  width: 100%;
  margin-left: 7%;
  /* padding-left: 1%; */
  border: none;
  color: black;

  transition: margin 300ms cubic-bezier(0.645, 0.045, 0.355, 1);

  &:focus,
  &:active {
    outline: none;
  }
  &::placeholder {
    color: grey;
  }
`;

export const StyledFaSearch = styled(FaSearch)`
  color: #304454;
  font-weight: 200;
  /* padding:2%; */
  margin-left: 95%;
  font-size: 1.3rem;
`;
export const Button = styled.button`
  line-height: 1;
  pointer-events: ${(props) => (props.barOpened ? "auto" : "none")};
  cursor: ${(props) => (props.barOpened ? "pointer" : "none")};
  background-color: transparent;
  border: none;
  outline: none;
  color: black;
`;
export const DeGallery = styled.div`
  @media ${device.mobileS} {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 1;
    background: gainsboro;
  }
  @media ${device.mobileL} {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    column-width: 100%;
    background: gainsboro;
  }
  @media ${device.laptop} {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    background: gainsboro;
    animation: bounce;
    animation-duration: 200ms;
  }

  -webkit-column-width: 13%;
  -moz-column-width: 13%;
  column-width: 13%;
  margin-left: 1%;
  margin-right: 1%;
  border-radius: 6px;
  padding: 2%;
  background: rgba(2, 99, 66, 0.9);
`;

export const BrowseHelp = styled.div`
  width: 70%;
  border-radius: 6px;
  margin: 0 auto;
  margin-top: 1%;
  margin-bottom: 3%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgb(209, 213, 219) 0px 0px 0px 1px inset;
`;
export const Styledh2 = styled.div`
  padding-top: 10%;
  margin-left: 15%;
  font-weight: 500;
`;
export const NothingFoundCard = styled.div`
  padding: 5%;
  margin: 0 auto;
  border-radius: 7px;
  text-align: center;
  height: 40vh;
  height: ${(props) => props.height};
  width: 40vw;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
  background-image: url(${(props) => props.bg});
  background-color: whitesmoke;
  margin-top: ${(props) => props.marginTop};
  background-repeat: space;
  color: black;
  background-size: 70px auto;
  text-transform: capitalize;
`;
export const NoMatch = styled.div`
  background-image: url("assets/img/Empty.svg");
  height: 40vh;
  width: 50%;
  margin: 0 auto;
  background-size: cover;
  background-position: center;
`;
export const DeGalleryPics = styled.div`
  -webkit-transition: all 350s ease;
  -moz-transition: all 350s ease;
  transition: all 350s ease;
  cursor: pointer;
  margin-bottom: 7px;
`;

export const DePic = styled.img`
  border-radius: 6px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);

  width: 100%;
  &:hover {
    filter: brightness(105%);
    /* transform: scale(1.1); */
    padding: 1%;
  }
`;
export const ZoomIcon = styled(BsZoomIn)`
  margin-left: 50%;
  margin-top: -75%;

  text-align: center;
  color: gainsboro;
  font-size: 2.4rem;
  /* &:hover {
    transform: scale(1.5);
  } */
`;
export const StyledTabs = styled(MDBTabs)`
  overflow: scroll;
  color: white;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
`;

export const ImgDivForNoData = styled.div`
  margin-left: 22.5%;
  height: 100%;
  width: 100%;
  background-image: url(${(props) => props.bg});
  background-size: contain;
  margin-top: ${(props) => props.marginTop};
  background-repeat: no-repeat;
`;
export const FAQBarWithSearch = styled.div`
  height: 10%;
  height: ${(props) => props.height};
  width: 100%;
  /* background-image: url(${(props) => props.bg}); */
  background-color: whitesmoke;
  filter: blur(0.4px);
  margin-top: ${(props) => props.marginTop};
  background-repeat: space;
  color: black;
  background-size: 70px auto;
`;
