import * as S from './JoinClassroom.style';
import Educator from '../../../../assets/img/joinAs/educator.svg';

const JoinClassroom = () => {
    return (
        <S.JoinClassroom>
            <S.DesignCard>
                <img src={Educator} alt="educator" />
                <h1>Join Classroom</h1>
                <p>
                   I am joining the classrooms as a
                </p>
                 {/* Create a set of buttons */}
                <S.BtnGroup>
                        <S.Btn href="https://accounts.google.com/v3/signin/identifier?dsh=S-1827991485%3A1663679999831118&continue=https%3A%2F%2Fclassroom.google.com%2Fu%2F0%2Fh&followup=https%3A%2F%2Fclassroom.google.com%2Fu%2F0%2Fh&passive=1209600&service=classroom&flowName=GlifWebSignIn&flowEntry=ServiceLogin&ifkv=AQDHYWpiXHj1eaysneJTWHSIn-QDyqFy3-yKX_ddO2eU9RxQNV7Sz28BWeBjOK3OcbtihwHB4RV1Mg">
                            Student
                        </S.Btn>
                    <S.Btn href="https://accounts.google.com/v3/signin/identifier?dsh=S-1827991485%3A1663679999831118&continue=https%3A%2F%2Fclassroom.google.com%2Fu%2F0%2Fh&followup=https%3A%2F%2Fclassroom.google.com%2Fu%2F0%2Fh&passive=1209600&service=classroom&flowName=GlifWebSignIn&flowEntry=ServiceLogin&ifkv=AQDHYWpiXHj1eaysneJTWHSIn-QDyqFy3-yKX_ddO2eU9RxQNV7Sz28BWeBjOK3OcbtihwHB4RV1Mg">
                            Teacher
                    </S.Btn>
                 </S.BtnGroup>
            </S.DesignCard>
            <hr />
            {/* Join the Classroom as a: */}
           
        </S.JoinClassroom>
    )
}

export default JoinClassroom;