import styled from "styled-components";
import { Link } from "react-router-dom";
const img =
  "	https://www.gstatic.com/mobilesdk/180326_mobilesdk/discoverycards/experiments.png";

export const UL = styled.ul`
  padding: 0;
  padding-left: 30px;
  font-size: 15px;
  text-transform: capitalize;
`;
export const MainOnAdmissionsLayout = styled.div`
  font-family: Montserrat;
  color: "black";
  height: fit-content;
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-color: #f5f5f5;
`;

export const MainOnAdmissionsHeaderLayout = styled.div`
  height: 65vh;
  height: ${(props) => props.height};
  margin-left: 0;
  background-image: url(${(props) => props.bg});
  background-position:top;
  background-size: cover;
`;

export const BackCardRe = styled.div`
  height: ${(props) => props.height};
  margin: 0 auto;
  background: white;
  border-radius: 16px;
  width: 90vw;
  width: ${(props) => props.width};
  padding: 80px;
  padding-bottom: 20px;
  padding: ${(props) => props.padding};
  padding-top: ${(props) => props.pT};
  margin-top: 50px;
  margin-top: ${(props) => props.marginTop};
  margin: ${(props) => props.margin};
  margin-bottom: ${(props) => props.marginBottom};
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
  -webkit-box-shadow: -2px 2px -1px 3px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: -2px 2px 4px -1px rgba(0, 0, 0, 0.22);
`;
export const cardHeader = styled.div`
  margin-bottom: 16px;
  text-align: center;
  font-size: 44px;
  font-weight: 500;
`;
export const BackCardGu = styled(BackCardRe)`
  margin-top: 5%;
  padding: ${(props) => props.padding};
  margin-bottom: 5%;
`;

export const BackCardGuIn = styled(BackCardRe)`
  border: ${(props) => props.border};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-image: url(${(props) => props.bg});
  margin-top: ${(props) => props.marginTop};
  margin-left: ${(props) => props.marginLeft};
  margin: ${(props) => props.margin};
  font: 500 20px/26px;
  padding-top: 135px;
  padding-left: 10px;
  padding: ${(props) => props.padding};
  line-height: 30px;
  /* background: #f8f9fa; */
  background: ${(props) => props.bgcolor};
  background-position: center;
  background-size: cover;

  border-radius: ${(props) => props.bR};
  background: ${(props) => props.bg};
  box-shadow: ${(props) => props.bS};
  /* padding: 40px; */
  transition: all 850ms ease;
  &:hover {
    cursor: pointer;
    background: ${(props) => props.hoverBg};
    /* background:${(props) => props.bgcolor_hover}; */
    /* /* background-size: cover; */
    background-position: contain; 
    /* background-image: url(${(props) => props.bg}); */
    /* webkit-filter: blur(  */
    /* ${(props) => props.blurOnHover} */
    /* ); */
    /* Chrome, Safari, Opera */
    /* filter: blur(${(props) => props.blurOnHover}); */
  }
`;
export const ApplyForThis = styled.button`
  font: 500 20px/26px;
  line-height: 30px;
  color: #03c350;
  background-color: transparent;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow:ellipsis;
  display: inline;
  width: 34%; /* this could be defined on any parent */
`;
export const ConditionalBlock = styled.div`
  display: flex;
`;
export const Span = styled.span`
  content: "flex";
`;
export const ConditionalAll = styled.div`
  display: flex;
  display: ${(props) => props.display};
  flex-direction: column;
`;
export const Expandable = styled.div`
  text-align: center;
  margin: 0 auto;
  margin-top: 80px;
  display: block;
  min-height: 24px;
  &::before {
    box-sizing: inherit;
  }
  &::after {
    box-sizing: inherit;
  }
`;
export const ExpandableBtn = styled.button`
  position: relative;
  color: #03c350;
  font-size: 20px;
  background: transparent;
  padding: 10px 5px 10px 20px;
  line-height: 20px;
  /* display:flex; */
  &:hover {
    background-color: rgb(3, 195, 80, 0.1);
    border-radius: 8px;
    border: none;
    width: auto;
    font-weight: 500;
  }
`;
export const BackCardGuInCir = styled.div`
  position: relative;
  top: -90px;
  top: ${(props) => props.top};
  position: ${(props) => props.position};
  background: url(${img});
  background: url(${(props) => props.bg});
  background-size: contain;
  background-size: ${(props) => props.bSize};
  background-position: ${(props) => props.bPos};
  background-repeat: no-repeat;
  object-fit: cover;
  left: ${(props) => props.left};
  padding: 0;
  border-radius: 50%;
  border-radius: ${(props) => props.bR};
  border-bottom-left-radius: ${(props) => props.bBL};
  border-bottom-right-radius: ${(props) => props.bBR};
  border: 0.5px solid green;
  border: ${(props) => props.border};
  margin: 0 auto;
  width: 95px;
  height: 95px;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  &:hover {
    background: ${(props) => props.bgcolor_hover};
    background-position: contain;
  }
`;

export const Edu = styled.span`
  
`;
